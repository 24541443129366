<template>
  <div class="content">
    <!-- Product -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Loading/Errors -->
    <div class="spinner auto-center" v-if="loading"></div>
    <div class="red input-error text-center" v-if="error.unknown">An unknown error occured. Please try again.</div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import platform from 'platform';
import ShopProductBox from './ShopProductBox.vue';

export default {
  name: 'shop-steam-payment-view',
  components: {ShopProductBox},
  data() {
    return {
      loading: true,
      error: {
        unknown: false,
      },
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    // Listen for data coming from the Steam app.
    window.steam.ipc.on('steam', (event, data) => {
      if (data.action === 'steamPaymentAuth') {
        this.authPayment(this.$store.state.app.steam.appId, data.orderId);
      }
    });

    // Get the steam id if we don't have it; otherwise, start the purchase.
    if (this.$store.state.app.steam.id === '') {
      window.steam.ipc.on('steam', (event, data) => {
        if (data.action === 'steamMetaData') {
          this.$store.commit('setSteamData', {
            id: data.steamId,
            lang: data.gameLang,
            appId: data.appId,
          });

          this.startPurchase();
        }
      });

      // Send request to Steam to get the metadata for the user.
      window.steam.ipc.sendToHost('steamMetaData');
    } else {
      this.startPurchase();
    }

    // Subscribe to the completion channel.
    this.$store.dispatch('sub', `completeSteam:${this.$store.state.user.uid}`);
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
  }),
  methods: {
    startPurchase() {
      const supportsClient = platform.os.family.includes('Windows')
        && !platform.os.version.startsWith('7');

      this.$store.dispatch('createSteamPayment', {
        appId: this.$store.state.app.steam.appId,
        product: this.product.id,
        steamId: this.$store.state.app.steam.id,
        gameLang: this.$store.state.app.steam.lang,
        gid: this.product.gid,
        gift: this.$store.state.shop.gift,
        usersession: supportsClient ? 'client' : 'web',
        // usersession: 'web',
      }).then((data) => {
        if (data.err) {
          this.error.unknown = true;
        } else if (data.url) {
          window.steam.ipc.sendToHost('steamPaymentUrl', data.url, data.orderid, data.transid);
        }
      });
    },
    authPayment(appId, orderId) {
      this.$store.dispatch('processSteamPayment', {
        appId,
        orderId,
      }).then((data) => {
        if (data.err) {
          this.error.unknown = true;
        } else {
          this.$store.commit('changeView', 'shop-success-view');
        }
      });
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.steam.ipc.removeAllListeners('steam');
    }

    this.$store.dispatch('unsub', `completeSteam:${this.$store.state.user.uid}`);
  },
};
</script>
