<template>
  <transition-group name="slide" :class="['notice-queue', left ? 'notice-queue-left' : '']">
    <notice-popup v-for="notice in notices" :notification="notice" :key="notice._id"></notice-popup>
  </transition-group>
</template>

<script>
import Vuex from 'vuex';
import NoticePopup from './NoticePopup.vue';

export default {
  name: 'notice-popup-queue',
  components: {NoticePopup},
  computed: Vuex.mapState({
    notices: state => state.notices.filter(n => n.type !== 'medal'),
    left: state => state.toolbar.left,
  }),
};
</script>
