<template>
  <div class="content">
    <forum-search></forum-search>

    <!-- Categories -->
    <template v-if="searchQuery === ''">
      <forum-category v-for="(category, index) in categories" :data="category" :index="index" :key="index"></forum-category>
    </template>

    <!-- Search Results -->
    <template v-else>
      <forum-thread-box
        v-for="thread in threads"
        class="inf"
        :id="`id-${thread._id}`"
        :thread="thread"
        :category="thread.category"
        :key="thread._id"
      >
      </forum-thread-box>
    </template>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumSearch from './ForumSearch.vue';
import ForumCategory from './ForumCategory.vue';
import ForumThreadBox from './ForumThreadBox.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'forums-view',
  components: {ForumSearch, ForumCategory, ForumThreadBox},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'FORUMS',
      buttons: [
        {label: 'Social Feed', view: 'social-feed-view'},
        {label: 'Subscriptions', view: 'forum-subscriptions-view'},
      ],
      altIcon: 'search',
      altMethod: {
        mutation: 'toggleForumSearch',
      },
      wide: false,
    });

    this.$store.dispatch('viewForumCategories');

    if (this.auth) {
      this.$store.dispatch('sub', `forumCategoryUnreads:${this.$store.state.user.uid}`);
      this.$store.dispatch('sub', 'updateForumCategoryUnreadCount:all');
    }

    this.$store.commit('setForumFilterTags', []);
    this.setWide(this.searchQuery !== '');
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    infiniteScrollingCfg(state) {
      return {
        actionName: 'searchForums',
        mutationName: 'setForumThreads',
        items: state.forums.threads,
        keys: ['t'],
      };
    },
    searchQuery: state => state.forums.searchQuery,
    categories(state) {
      return state.forums.categories.filter((cat) => {
        const {hidden} = state.gameData[cat.gid];
        return !hidden || (hidden && state.launcher.games.find(g => g.game === cat.gid));
      });
    },
    threads: (state) => {
      return state.forums.threads
        .map((thread) => {
          thread.category = state.forums.categories.find(cat => cat.gid === thread.gid);
          return thread;
        })
        // eliminate duplicates
        .filter((thread, i, arr) => arr.findIndex(t => t._id === thread._id) === i);
    },
  }),
  watch: {
    searchQuery(newQuery) {
      if (newQuery !== '') {
        this.setWide(true);
      } else {
        this.setWide(false);
      }
    },
  },
  methods: {
    setWide(wide) {
      this.$store.commit('setPanelWide', wide);
      this.$store.commit('setPanelData', {
        title: 'FORUMS',
        buttons: [
          {label: 'Social Feed', view: 'social-feed-view'},
          {label: 'Subscriptions', view: 'forum-subscriptions-view'},
        ],
        altIcon: 'search',
        altMethod: {
          mutation: 'toggleForumSearch',
        },
        wide,
      });
    },
  },
  beforeDestroy() {
    if (this.auth) {
      this.$store.dispatch('unsub', `forumCategoryUnreads:${this.$store.state.user.uid}`);
      this.$store.dispatch('unsub', 'updateForumCategoryUnreadCount:all');
    }

    this.$store.commit('setForumThreads', {});
  },
};
</script>
