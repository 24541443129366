<template>
  <div :class="style" @click="onClick" :id="id">
    <div key="value" v-if="!loading">{{ value }}</div>
    <div key="loading" class="spinner" v-else></div>
  </div>
</template>

<script>
export default {
  name: 'button-loader',
  props: ['styles', 'value', 'click', 'loading', 'id', 'disabled'],
  computed: {
    style() {
      return Object.assign({}, {
        button: true,
        flex: true,
        center: true,
        middle: true,
        disabled: this.disabled,
      }, this.styles);
    },
  },
  methods: {
    onClick(event) {
      if (!this.loading) {
        this.click(event);
      }
    },
  },
  watch: {
    loading() {
      this.styles.flex = true;
      this.styles.center = true;
      this.styles.disabled = this.loading || this.disabled;
    },
    disabled() {
      this.styles.disabled = this.loading || this.disabled;
    },
  },
  serverCacheKey(props) {
    return `${props.styles}::${props.value}::${props.click}::${props.loading}::${props.id}`;
  },
};
</script>
