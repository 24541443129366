<template>
  <div class="container flex column center middle">
    <h1>Contact Us</h1>
    <template v-if="!sent">
      <div class="contact-us flex column half auto-center top-margin-30">
        <!-- Email -->
        <input type="text" :class="['light', {error: error.email}]" placeholder="E-mail Address" v-model="email" @keyup.enter="submit" v-focus>
        <div class="red input-error" v-if="error.email">Please enter a valid e-mail address.</div>

        <!-- Subject -->
        <input type="text" :class="['light', 'top-margin-15', {error: error.subject}]" placeholder="Subject" v-model="subject" @keyup.enter="submit">
        <div class="red input-error" v-if="error.subject">Please enter a subject.</div>

        <!-- Message -->
        <textarea :class="['light', 'top-margin-15', {error: error.message}]" placeholder="Message" v-model="message"></textarea>
        <div class="red input-error" v-if="error.message">Please enter a message.</div>

        <!-- Honeypot -->
        <input type="text" class="none" name="website" placeholder="Website">

        <!-- Submit Button -->
        <button class="top-margin-15" @click="submit">Send Message</button>
      </div>
    </template>
    <template v-else>
      <div class="contact-us auto-center top-margin-30">
        <h3>Thanks for reaching out! If your message requires a response, we'll get back to you as quickly as we can!</h3>
        <router-link to="/" class="button auto top-margin-30">Back Home</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import is from 'is_js';

export default {
  name: 'contact',
  data() {
    return {
      email: '',
      subject: '',
      message: '',
      sent: false,
      error: {
        email: false,
        subject: false,
        message: false,
      },
    };
  },
  meta: {
    title: 'Contact Us',
  },
  methods: {
    submit() {
      this.error.email = false;
      this.error.subject = false;
      this.error.message = false;

      // Check if all of the data is valid.
      if (!is.email(this.email)) {
        this.error.email = true;
      }
      if (this.subject.length < 1) {
        this.error.subject = true;
      }
      if (this.message.length < 1) {
        this.error.message = true;
      }

      const isHoneyPot = this.$el.querySelector('.none').value.length > 0;
      if (this.error.email || this.error.subject || this.error.message || isHoneyPot) {
        return;
      }

      // Send the e-mail.
      this.$store.dispatch('contactUs', {
        email: this.email,
        subject: this.subject,
        message: this.message,
      });
      this.sent = true;
    },
  },
  serverCacheKey() {
    return true;
  },
};
</script>
