<template>
  <div class="box dropdown flex column start no-padding">
    <span
      v-for="(item, index) in items"
      :class="['item', {active: index === active}]"
      @mousedown="select(item)"
    >
      {{ item }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'dropdown-list',
  props: ['items'],
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.keydown);
    }
  },
  methods: {
    select(item) {
      this.$emit('select', item);
    },
    keydown(event) {
      if (event.which === 13 || event.which === 9) { // enter
        event.preventDefault();
        this.select(this.items[this.active]);
      } else if (event.which === 38 && this.active > 0) { // up
        event.preventDefault();
        this.active -= 1;
      } else if (event.which === 40 && this.active < this.items.length) { // down
        event.preventDefault();
        this.active += 1;
      }
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.keydown);
    }
  },
};
</script>
