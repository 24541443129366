<template>
  <div class="content">
    <!-- Product -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Load/Errors -->
    <div class="spinner auto-center" v-if="loading"></div>
    <div class="red input-error text-center" v-if="error.unknown">An unknown error occured. Please try again.</div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ShopProductBox from './ShopProductBox.vue';

export default {
  name: 'shop-xsolla-payment-view',
  components: {ShopProductBox},
  data() {
    return {
      loading: true,
      error: {
        unknown: false,
      },
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    this.getToken();
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
  }),
  methods: {
    getToken() {
      this.$store.dispatch('getXsollaToken', {
        product: this.product.id,
        gid: this.product.gid,
        gift: this.$store.state.shop.gift,
      }).then((data) => {
        this.loading = false;

        if (!data.token || data.errors) {
          this.error.unknown = true;
          return;
        }

        // Launch the modal.
        this.$store.commit('setXsollaToken', data.token);
      });
    },
  },
};
</script>
