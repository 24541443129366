<template>
  <div class="box post social">
    <avatar :player="update.player" :history="history"></avatar>
    <div class="text full">
      <username :player="update.player" :history="history"></username>
      <live-time class="time right" :timestamp="update.t"></live-time> 
      <div v-for="(msg, index) in messages">
        <!-- Display an uploaded image -->
        <div v-if="msg.img" class="relative bottom-margin-5">
          <div class="relative" :style="style" v-html="msg.img"></div>
        </div>

        <!-- Display an embedded video -->
        <div v-if="msg.video" v-html="msg.video"></div>

        <!-- Display a text message -->
        <div v-if="msg.msg" class="text" v-html="msg.msg"></div>
      </div>
      <span class="flex full justify top-margin-10">
        <span class="flex">
          <span class="text-counter medium regular right-margin-15">
            <span class="icon comments right-margin-5" @click="toggleCommenting"></span> {{commentCount}}
          </span>
          <span class="text-counter medium regular">
            <span class="relative right-margin-5">
              <span :class="['icon', 'likes', {highlight: likeHighlight}]" @click="like"></span>
              <transition name="pulse" @after-enter="pulse = false">
                <span :class="['icon', 'likes', 'bottom', {highlight: likeHighlight}]" v-if="pulse"></span>
              </transition>
            </span>
            &nbsp;{{likeCount}} 
          </span>
        </span>

        <!-- Delete/Flag Icons -->
        <delete-icon v-if="canDelete" :remove="remove"></delete-icon>
        <flag-icon v-if="!canDelete"></flag-icon>
      </span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import Avatar from './Avatar.vue';
import Username from './Username.vue';
import DeleteIcon from './DeleteIcon.vue';
import FlagIcon from './FlagIcon.vue';
import {linkTargetBlank} from '../utils';

export default {
  name: 'social-feed-update',
  components: {LiveTime, Avatar, Username, DeleteIcon, FlagIcon},
  props: ['update'],
  data() {
    return {
      pulse: false,
      style: '',
    };
  },
  mounted() {
    if (this.$parent.$options._componentTag !== 'social-feed-update-view') {
      this.update.commenting = false;
    } else {
      this.update.commenting = true;
    }

    // Update links.
    linkTargetBlank(this.$el);
  },
  updated() {
    linkTargetBlank(this.$el);
  },
  methods: {
    like() {
      if (this.auth) {
        this.$store.dispatch('toggleLike', {updateId: this.update._id});
        this.pulse = true;
      }
    },
    toggleCommenting() {
      this.update.commenting = !this.update.commenting;
    },
    history() {
      this.$store.commit('addHistory', {
        mutation: 'toggleTabView',
        data: {tab: 'forums', view: 'social-feed-view'},
      });
    },
    remove() {
      this.$store.dispatch('deleteSocialFeedUpdate', {id: this.update._id, uid: this.update.player.uid});
    },
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    likeHighlight(state) {
      return this.update.likes.some(like => like === state.user.uid);
    },
    likeCount() {
      return this.update.likes.length;
    },
    commentCount() {
      return this.update.comments.length;
    },
    messages() {
      const regex = /<span class="video-wrapper"><iframe src="\/\/(.+?)<\/iframe><\/span>|<img src="https:\/\/img.(.+?)" class="image">|<img class="image" src="https:\/\/img.(.+?)">/g;
      const messages = this.update.msg.split(regex).map((part) => {
        if (!part) {
          return null;
        }

        const msg = {};

        // Add the correct media designation.
        if (part.indexOf('www.youtube.com/') === 0 || part.indexOf('player.vimeo.com/') === 0 || part.indexOf('player.twitch.tv/') === 0) {
          msg.video = `<span class="video-wrapper"><iframe src="//${part}</iframe></span>`;
        } else if (part.indexOf('goldfire.me/') === 0) {
          const [url, aspect] = part.split(/\?a=(.*)/ig);
          this.style = `padding-top: ${aspect}%`;
          msg.img = `<a href="https://img.${url.replace('/thumb/', '/post/')}" target="_blank"><img src="https://img.${url}" class="image"></a>`;
        } else {
          msg.msg = part;
        }

        return msg;
      }).filter(part => part);

      return messages;
    },
    canDelete(state) {
      const isModerator = state.user.role;
      const isOwnPost = state.user.uid === this.update.player.uid;

      return isModerator || isOwnPost;
    },
  }),
};
</script>
