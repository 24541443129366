<template>
  <div class="content">
    <!-- Gold Pack -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Handle Errors -->
    <template v-if="error.unknown || error.bought || error.exists">
      <info-box title="Error" type="''">
        <span v-if="error.unknown">An unknown error occured, please try again.</span>
        <span v-if="error.bought">This product can only be purchased once.</span>
        <span v-if="error.exists">The product you are trying to purchase no longer exists.</span>
      </info-box>
      <button @click="back">Back to Store</button>
    </template>

    <!-- Payment Options List -->
    <info-box title="Select Payment Type" type="''" v-else>
      <!-- Credit Card -->
      <button :class="['button', 'payment', 'flex', 'center', 'middle', 'bottom-margin-10', 'credit-card']" @click="select('card')">
        <div class="cc-container">
          <span class="icon amex-card" style="left: 0; z-index: 1"></span>
          <span class="icon discover-card" style="left: 35px; z-index: 2;"></span>
          <span class="icon mastercard-card" style="left: 70px; z-index: 3;"></span>
          <span class="icon visa-card" style="left: 105px; z-index: 4;"></span>
        </div>
      </button>

      <!-- PayPal -->
      <button :class="['button', 'payment', 'flex', 'center', 'middle', {'bottom-margin-10': !product.recurring}, 'paypal']" @click="select('paypal')">
        <span class="icon paypal"></span>
      </button>

      <!-- Bitcoin -->
      <!-- <button class="button payment flex center middle bottom-margin-10 bitcoin" v-if="!product.recurring" @click="select('bitcoin')">
        <span class="icon bitcoin"></span>
      </button> -->

      <!-- Mobile/Other -->
      <button class="button payment flex center middle mobile" v-if="!product.recurring" @click="select('xsolla')">
        Mobile/Other
      </button>
    </info-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import InfoBox from './InfoBox.vue';
import ShopProductBox from './ShopProductBox.vue';

export default {
  name: 'shop-payment-view',
  components: {InfoBox, ShopProductBox},
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    // Load cards if we don't have any.
    if (this.$store.state.shop.manage.cards.length === 0) {
      this.$store.dispatch('getShopCards');
    }

    // If in the Windows App, open the external payment flow.
    const hasExternal = typeof window.external !== 'undefined' && 'notify' in window.external;
    if (!this.product.recurring && hasExternal && this.$store.state.app.type === 'windows') {
      window.external.notify(`gold - ${this.$store.state.user.uid} - ${this.product.id} - ${this.$store.state.shop.gift} - ${this.product.gid}`);
      this.$store.commit('toggleTab', 'shop');
    } else if (!this.product.recurring && window.app && window.app.ipc && this.$store.state.app.type === 'windows') {
      window.app.ipc.sendToHost('winAppBuy', {
        uid: this.$store.state.user.uid,
        pid: this.product.id,
        gift: this.$store.state.shop.gift,
        gid: this.product.gid,
      });
      this.$store.commit('toggleTab', 'shop');
    }

    // If in the Steam App, open the Steam payment flow.
    if (this.$store.state.app.type === 'steam') {
      this.$store.commit('changeView', 'shop-steam-payment-view');
    }

    // If in a mobile app, open the mobile payment flow.
    if (this.$store.state.app.type === 'mobile') {
      this.$store.commit('changeView', 'shop-mobile-payment-view');
    }
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
    error: state => state.shop.error,
  }),
  methods: {
    select(type) {
      this.$store.commit('changeView', `shop-${type}-payment-view`);
    },
    back() {
      this.$store.commit('changeView', 'shop-view');
    },
  },
};
</script>
