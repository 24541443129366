<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import InfoView from './InfoView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'info',
  components: {PanelContent, InfoView, EmptyView},
  computed: Vuex.mapState({
    activeView: state => state.info.activeView,
  }),
};
</script>
