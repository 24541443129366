<template>
  <transition :name="buttonTransition" v-if="buttons.length > 0">
    <div class="buttons">
      <template v-if="buttons.length === 1">
        <button-loader
          :value="buttons[0].label"
          :loading="buttons[0].loading"
          :styles="{}"
          :click="changeView(buttons[0].view)"
          :id="buttons[0].id"
          :disabled="buttons[0].disabled"
        ></button-loader>
      </template>
      <template v-else>
        <button-loader
          class="left-btn"
          :value="buttons[0].label"
          :loading="buttons[0].loading"
          :styles="{}"
          :click="changeView(buttons[0].view)"
          :id="buttons[0].id"
          :disabled="buttons[0].disabled"
        ></button-loader>
        <button-loader
          class="right-btn"
          :value="buttons[1].label"
          :loading="buttons[1].loading"
          :styles="{}"
          :click="changeView(buttons[1].view)"
          :id="buttons[1].id"
          :disabled="buttons[1].disabled"
        ></button-loader>
      </template>
    </div>
  </transition>
  <div class="buttons grow-shrink" v-else-if="hasInput">
    <div class="box input no-border bottom">
      <input :placeholder="placeholder" v-model="inputValue">
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ButtonLoader from './ButtonLoader.vue';

export default {
  name: 'panel-footer',
  components: {ButtonLoader},
  computed: Vuex.mapState({
    buttons: state => state.panel.buttons,
    hasInput: state => state.panel.input.show,
    placeholder: state => state.panel.input.placeholder,
    buttonTransition: state => state.panel.buttonTransition,
  }),
  data() {
    return {
      inputValue: '',
    };
  },
  watch: {
    inputValue(val) {
      this.$store.commit('updateInput', val);
    },
  },
  methods: {
    changeView(view) {
      return () => {
        if (typeof view === 'string') {
          this.$store.commit('changeView', view);
        } else if (typeof view === 'object') {
          if (view.mutation) {
            this.$store.commit(view.mutation, view.data);
          } else {
            this.$store.dispatch(view.action, view.data);
          }
        }
      };
    },
  },
};
</script>
