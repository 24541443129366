<template>
  <div class="container text-left profile">
    <profile-view></profile-view>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ProfileView from '../ProfileView.vue';

export default {
  name: 'profile',
  components: {ProfileView},
  data() {
    return {
      loading: true,
    };
  },
  meta() {
    return {
      title: this.$store.state.launcher.profile.username || 'Profile',
    };
  },
  computed: Vuex.mapState({
    user: state => state.route.params.user,
  }),
  mounted() {
    if (this.$store.state.user.auth) {
      this.$store.dispatch('viewProfile', {user: this.user});
      this.$router.replace('/');
    } else if (!this.$store.state.launcher.profile.uid) {
      this.$store.dispatch('viewProfile', {user: this.user});
    }
  },
};
</script>
