<template>
  <div class="signup">
    <div class="content">
      <h3>Login to connect {{ type }} to your GoldFire account.</h3>

      <!-- Login Form -->
      <div class="top-margin-10 bottom-margin-30">
        <input
          type="text"
          class="underline"
          placeholder="E-mail Address"
          v-model="email"
          @keyup.enter="login"
          v-focus
        >
        <input
          type="password"
          :class="['underline', 'top-margin-15', {error: error}]"
          placeholder="Password"
          v-model="pass"
          @keyup.enter="login"
        >
        <div class="red input-error" v-if="error">Wrong e-mail or password.</div>
      </div>

      <!-- Login Button -->
      <button-loader
        value="Connect Accounts"
        :styles="{'color': true, 'bottom-margin-30': true}"
        :loading="loading"
        :click="login"
      ></button-loader>
      <router-link to="/login/reset" class="link">Forgot your password?</router-link>
    </div>
  </div>
</template>

<script>
import ButtonLoader from '../ButtonLoader.vue';
import {readCookie, setCookie} from '../../utils';

export default {
  name: 'social-connect',
  components: {ButtonLoader},
  data() {
    return {
      type: this.$store.state.route.query.type,
      id: this.$store.state.route.query.id,
      email: '',
      pass: '',
      error: false,
      loading: false,
    };
  },
  methods: {
    login() {
      if (this.email === '' || this.pass === '') {
        this.error = true;
      } else {
        // Update the state of the button.
        this.loading = true;

        // Determine which type to send.
        let type = 'fb';
        if (this.type === 'Twitter') {
          type = 'tw';
        } else if (this.type === 'Google') {
          type = 'gp';
        } else if (this.type === 'Windows') {
          type = 'win';
        } else if (this.type === 'Apple') {
          type = 'apple';
        }

        // Send the login request to the server.
        this.$store.dispatch('login', {
          email: this.email.toLowerCase(),
          pass: this.pass,
          social: {
            type,
            id: this.id,
          },
        }).then((data) => {
          this.loading = false;
          this.error = data.err;
          if (!this.error) {
            const loginRoute = readCookie('loginRoute');
            const loginGame = readCookie('loginGame');
            if (loginRoute) {
              const mobile = `${loginGame}://?sid=${data.cookie}`;
              setCookie('loginRoute', '', 0);
              setCookie('loginGame', '', 0);
              window.location = loginRoute === 'mobile' ? mobile : `${loginRoute}?rel=social&sid=${encodeURIComponent(data.cookie)}`;
            } else {
              this.$router.push('/');
            }
          }
        });
      }
    },
  },
};
</script>
