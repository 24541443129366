<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import ShopView from './ShopView.vue';
import ShopPaymentView from './ShopPaymentView.vue';
import ShopCardPaymentView from './ShopCardPaymentView.vue';
import ShopSteamPaymentView from './ShopSteamPaymentView.vue';
import ShopMobilePaymentView from './ShopMobilePaymentView.vue';
// import ShopBitcoinPaymentView from './ShopBitcoinPaymentView.vue';
import ShopPaypalPaymentView from './ShopPaypalPaymentView.vue';
import ShopXsollaPaymentView from './ShopXsollaPaymentView.vue';
import ShopHistoryView from './ShopHistoryView.vue';
import ShopManagePaymentsView from './ShopManagePaymentsView.vue';
import ShopSuccessView from './ShopSuccessView.vue';
import ShopPendingView from './ShopPendingView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'shop',
  components: {
    PanelContent,
    ShopView,
    ShopPaymentView,
    ShopCardPaymentView,
    ShopSteamPaymentView,
    ShopMobilePaymentView,
    // ShopBitcoinPaymentView,
    ShopPaypalPaymentView,
    ShopXsollaPaymentView,
    ShopHistoryView,
    ShopManagePaymentsView,
    ShopSuccessView,
    ShopPendingView,
    EmptyView,
  },
  computed: Vuex.mapState({
    activeView: state => state.shop.activeView,
  }),
};
</script>
