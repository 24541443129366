<template>
  <div class="signup">
    <div class="content" v-if="!error.keyMatch">
      <h3>Enter a new password.</h3>
      <div class="bottom-margin-30">
        <input
          type="password"
          :class="['underline', 'top-margin-15', {error: error.passLen}]"
          placeholder="Password"
          v-model="pass"
          @keyup.enter="submit"
          v-focus
        >
        <div class="red input-error" v-if="error.passLen">Password should be 6+ characters.</div>
      </div>

      <!-- Reset Button -->
      <button-loader
        value="Reset Password"
        :styles="{'color': true, 'top-margin-15': true}"
        :loading="loading"
        :click="submit"
      ></button-loader>
      <div class="red input-error" v-if="error.unknown">An unknown error occured, please try again.</div>
    </div>

    <!-- Key Match Error -->
    <div class="content" v-else>
      <h3>There was an error with your request.</h3>
      <router-link to="/login/reset" class="button color">Send New Reset Link</router-link>
    </div>
  </div>
</template>

<script>
import ButtonLoader from '../ButtonLoader.vue';

export default {
  name: 'reset-finish',
  components: {ButtonLoader},
  data() {
    return {
      loading: false,
      pass: '',
      error: {
        unknown: false,
        passLen: false,
        keyMatch: false,
      },
    };
  },
  methods: {
    submit() {
      this.error.unknown = false;
      this.error.passLen = false;
      this.error.keyMatch = false;

      // Check if this is a valid password.
      if (!this.pass || this.pass === '' || this.pass.length < 6) {
        this.error.passLen = true;
        return;
      }

      this.loading = true;
      this.$store.dispatch('finishResetPassword', {
        pass: this.pass,
        user: this.$route.params.user,
        key: this.$route.params.key,
      }).then((data) => {
        this.loading = false;

        // Handle errors.
        Object.assign(this.error, data.errors);
        const err = this.error;
        const hasErr = Object.keys(err).some(key => err[key] === true);
        if (!hasErr) {
          this.$router.push('/');
        }
      });
    },
  },
};
</script>