<template>
  <input type="text" :placeholder="placeholder" v-bind:value="value" @keyup="updateValue($event.target.value)" @keyup.enter="$emit('enter')">
</template>

<script>
import Cleave from 'cleave.js';

export default {
  name: 'cleave',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      cleave: null,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.cleave = new Cleave(this.$el, this.options);
  },
  beforeDestroy() {
    this.cleave.destroy();
  },
};
</script>
