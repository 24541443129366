<template>
  <div>
    <div v-for="(msg, index) in messages" :class="['chat-box', 'no-margin', {dark: isDark}]">
      <span class="outer">
        <avatar v-if="!isDark && first && index === 0" :player="player" :history="history" class="small"></avatar>
      </span>
      <div :class="['message', {grow: msg.video}]">
        <!-- Display an uploaded image -->
        <div v-if="msg.img" class="relative bottom-margin-5">
          <div class="relative" :style="style" v-html="msg.img"></div>
        </div>

        <!-- Display an embedded video -->
        <div v-if="msg.video" v-html="msg.video"></div>

        <!-- Display a text message -->
        <div v-if="msg.msg" class="text" v-html="msg.msg"></div>

        <!-- Display a typing indicator -->
        <div v-if="msg.typing" class="text">
          <span class="typing">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>

        <!-- Display the time -->
        <span :class="['flex', 'row', 'middle', {end: isDark}]">
          <live-time class="time" :timestamp="msg.t" v-if="index === messages.length - 1"></live-time>
          <span v-if="first" class="time">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
          <span v-if="first" class="time">{{ player.user }}</span>
        </span>
      </div>
      <span class="outer">
        <avatar v-if="isDark && first && index === 0" :player="player" :history="history" class="small"></avatar>
      </span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import Avatar from './Avatar.vue';
import {linkTargetBlank} from '../utils';

export default {
  name: 'chat-message',
  components: {LiveTime, Avatar},
  props: ['message', 'player', 'first'],
  data() {
    return {
      style: '',
    };
  },
  mounted() {
    linkTargetBlank(this.$el);
  },
  updated() {
    linkTargetBlank(this.$el);
  },
  computed: Vuex.mapState({
    isDark(state) {
      const player = this.player ? this.player.user : null;
      return state.user.user === player;
    },
    messages() {
      if (!this.message.msg) {
        return [this.message];
      }

      // Split out the embedded media so we can display correctly.
      const regex = /<span class="video-wrapper"><iframe src="\/\/(.+?)<\/iframe><\/span>|<img src="https:\/\/img.(.+?)" class="image">|<img class="image" src="https:\/\/img.(.+?)">/g;
      const messages = this.message.msg.split(regex).map((part) => {
        if (!part) {
          return null;
        }

        const msg = {
          t: this.message.t,
          _id: this.message._id,
        };

        // Add the correct media designation.
        if (part.indexOf('www.youtube.com/') === 0 || part.indexOf('player.vimeo.com/') === 0 || part.indexOf('player.twitch.tv/') === 0) {
          msg.video = `<span class="video-wrapper"><iframe src="//${part}</iframe></a>`;
        } else if (part.indexOf('goldfire.me/') === 0) {
          const [url, aspect] = part.split(/\?a=(.*)/ig);
          this.style = `padding-top: ${aspect}%`;
          msg.img = `<a href="https://img.${url.replace('/thumb/', '/post/')}" target="_blank"><img src="https://img.${url}" class="image"></a>`;
        } else {
          msg.msg = part;
        }

        return msg;
      }).filter(part => part);

      return messages;
    },
  }),
  methods: {
    history() {
      this.$store.commit('addHistory', {
        action: 'viewChatThread',
        data: {id: this.$store.state.chat.activeMessage.id},
      });
    },
  },
};
</script>
