<template>
  <div :class="{flex: !auth, 'game-wrap': !auth}">
    <game-display :user="user" :context="context" v-for="(game, index) in games" :data="game" :key="game.game"></game-display>
    <div v-if="games.length === 0 && !isLoading" class="box center">
      This user hasn't played any games yet.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import GameDisplay from './GameDisplay.vue';

export default {
  name: 'games-list',
  props: ['games', 'context', 'user'],
  components: {GameDisplay},
  methods: {
    viewNotifications(game) {
      this.$parent.viewNotifications(game);
    },
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    isLoading: state => state.panel.loading,
  }),
};
</script>
