<template>
  <div>
    <transition name="fade-top">
      <div class="notice-bar flex middle center" @click="onClick" v-if="showNotification">
        {{ notificationText }}
      </div>
    </transition>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'forum-thread-header',
  computed: Vuex.mapState({
    showNotification: state => state.forums.notifications !== 0,
    notificationCount: state => state.forums.notifications,
    end: state => state.scroll.end,
    notificationText() {
      const post = this.notificationCount === 1 ? 'post' : 'posts';
      return `Click to scroll to ${this.notificationCount} new or updated ${post}.`;
    },
  }),
  methods: {
    onClick() {
      this.$store.commit('incForumNotifications', 0);

      if (!this.end) {
        this.$store.commit('reloadForumThread');
      } else {
        this.$store.commit('scrollToBottom');
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('incForumNotifications', 0);
  },
};
</script>
