<template>
  <div class="upload-wrapper flex middle center">
    <div class="spinner" v-if="loading"></div>
    <div :class="['upload-area', 'flex', 'column', 'center', 'middle', {'drop-hover': hover}]" v-else>
      <span class="icon upload no-pointer"></span>
      <span class="subtext top-margin-10 no-pointer">Drag & drop or click to upload.</span>
      <span class="red input-error" v-if="error !== ''">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import {v4 as uuid} from 'uuid';

let Dropzone = {};
if (typeof document !== 'undefined') {
  Dropzone = require('dropzone');
}

export default {
  name: 'dropzone',
  props: ['bucket', 'type'],
  data() {
    return {
      dropzone: {},
      loading: false,
      hover: false,
      error: '',
    };
  },
  mounted() {
    const self = this;
    const bucket = this.bucket || 'img.goldfire.me';
    let sig = '';
    let policy = '';
    let filename = '';

    // Setup Dropzone.js instance.
    this.dropzone = new Dropzone(this.$el.querySelector('.upload-area'), {
      url: `https://${bucket}`,
      maxFilesize: 3,
      clickable: true,
      createImageThumbnails: false,
      acceptedFiles: 'image/png,image/jpg,image/gif,image/jpeg',
      accept(file, done) {
        self.loading = true;
        self.error = '';
        self.$store.dispatch('prepareImageUpload', {type: file.type, bucket}).then((data) => {
          sig = data.sig;
          policy = data.policy;
          done();
        });
      },
      error(file, err) {
        self.loading = false;
        self.error = err;
      },
      sending(file, xhr, formData) {
        // Generate the new filename.
        if (self.type === 'avatar') {
          filename = `${self.$store.state.user.uid}.png`;
        } else {
          const ext = /\.([^.]+)$/.exec(file.name.split('?', 1)[0])[0].toLowerCase();
          filename = file.name
            .replace(`${ext}`, '')
            .replace(/\s+/g, '_')
            .replace(/\W/g, '')
            .toLowerCase()
            .concat(`.${uuid().slice(0, 8)}`)
            .concat(ext);
        }

        // Update the form data to send to S3.
        formData.append('key', `tmp/${filename}`);
        formData.append('AWSAccessKeyId', 'AKIAIZMVCMGQYPSN2EMQ');
        formData.append('acl', 'public-read');
        formData.append('policy', policy);
        formData.append('signature', sig);
        formData.append('Content-Type', file.type);
        formData.append('success_action_status', '201');
      },
      success() {
        // Send the URL to the server to be compressed.
        const tab = self.$store.state.toolbar.activeTab;
        self.$store.dispatch('compressImage', {filename, bucket}).then((data) => {
          // If this is an editor dropzone, insert the image.
          if (self.type === 'editor') {
            const query = `?a=${data.aspect}`;
            self.$parent.$parent.$emit('insert', {
              type: 'image',
              data: {
                img: `https://${bucket}/post/${filename}${query}`,
                thumb: tab !== 'forums' ? `https://${bucket}/thumb/${filename}${query}` : null,
              },
            });
            self.$parent.$parent.closeMedia();
          }

          // Update the user's avatar.
          if (self.type === 'avatar') {
            if (data.avatar) {
              self.$store.commit('setUserData', {avatar: data.avatar});
            }
            self.$store.commit('toggleTab', 'launcher');
          }
        });
      },
      dragover() {
        self.hover = true;
      },
      dragleave() {
        self.hover = false;
      },
    });
  },
  serverCacheKey() {
    return `${props.bucket}::${props.type}`;
  },
};
</script>
