<template>
  <div :class="['page', {full: !auth, 'left-toolbar': auth && left}]">
    <!-- Header -->
    <div class="top">
      <!-- Top Navigation -->
      <div class="topnav">
        <router-link to="/" class="icon click goldfire" style="margin-top: -7px;"></router-link>
        <div class="rightnav" v-if="!auth">
          <router-link to="/login" class="button outline small auto right-margin-15">Login</router-link>
          <router-link to="/signup" class="button small auto">Signup</router-link>
          <router-link to="/login" class="button small auto hidden">Login/Signup</router-link>
        </div>
        <div class="rightnav" v-else>
          <router-link to="/bye" class="button outline small auto logout">Logout</router-link>
        </div>
      </div>

      <!-- Show Games on Homepage -->
      <div v-if="path === '/'" class="games">
        <div class="game casinorpg right-margin-15">
          <div class="online-counter flex middle" v-if="auth">
            <span class="icon online right-margin-5"></span>
            {{ games[1].online }}
          </div>
          <div class="flex column full">
            <div class="name">CasinoRPG</div>
            <div class="details">Unique casino game that merges elements of tycoon, role-playing, city-building and more!</div>
          </div>
          <button class="color auto medium" @click="$router.push('/casinorpg')" v-if="!auth">Learn More</button>
          <button class="color auto medium" @click="$router.push('/casinorpg')" v-else>Play Now</button>
          <button class="color outline medium hidden" @click="$router.push('/casinorpg')">CasinoRPG</button>
        </div>
        <div class="game exocraft right-margin-15 center">
          <div class="online-counter flex middle" v-if="auth">
            <span class="icon online right-margin-5"></span>
            {{ games[4].online }}
          </div>
          <button class="color outline medium" @click="$router.push('/exocraft')">Exocraft</button>
        </div>
        <div class="game bcwars center">
          <div class="online-counter flex middle" v-if="auth">
            <span class="icon online right-margin-5"></span>
            {{ games[2].online }}
          </div>
          <button class="color outline medium" @click="$router.push('/bcwars')">BC Wars</button>
        </div>
      </div>
    </div>

    <!-- Info -->
    <div :class="['page-content', {'no-padding': path === '/' && auth}]">
      <router-view></router-view>
    </div>

    <!-- Footer -->
    <div class="footer">
      <span>&copy; {{ new Date().getFullYear() }} <a href="http://goldfirestudios.com">GoldFire Studios, Inc.</a></span>
      <div>
        <router-link to="/forums">Forums</router-link>&nbsp;&nbsp;/&nbsp;
        <router-link to="/contact">Contact</router-link>&nbsp;&nbsp;/&nbsp;
        <router-link to="/directory">Players</router-link>&nbsp;&nbsp;/&nbsp;
        <router-link to="/terms">Terms</router-link>&nbsp;&nbsp;/&nbsp;
        <router-link to="/privacy">Privacy</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'layout',
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    left: state => state.toolbar.left,
    path: state => state.route.path,
    games: state => state.gameData,
  }),
  mounted() {
    this.updateActive();
  },
  watch: {
    auth() {
      this.updateActive();
    },
  },
  methods: {
    updateActive() {
      if (this.auth) {
        this.$store.dispatch('updateActiveGame', {gid: 0});
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/bye') {
      // Send message to server to log them out.
      this.$store.dispatch('logout');
      next('/');
    } else {
      next();
    }
  },
};
</script>
