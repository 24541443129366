<template>
  <div :class="['editor', {'full': showEmoji || showMedia}, type]">
    <template v-if="view === 'editor'">
      <!-- Editor Toolbar -->
      <span class="filter-toolbar middle">
        <template v-if="type !== 'chat' && type !== 'feed'">
          <span :class="['filter', {active: states.bold}]" @click="sendFilter('bold')">
            <svg viewBox="0 0 14 14"><path d="M10.061 6.573c.607-.72.94-1.631.939-2.573 0-2.206-1.794-4-4-4H2v14h6c2.206 0 4-1.794 4-4a4.003 4.003 0 0 0-1.939-3.427zM6.586 2c.874 0 1.586.897 1.586 2s-.711 2-1.586 2H5V2h1.586zM5 12V8h2.484c.913 0 1.656.897 1.656 2s-.743 2-1.656 2H5z" fill="#787878"/></svg>
          </span>
          <span :class="['filter', {active: states.italic}]" @click="sendFilter('italic')">
            <svg viewBox="0 0 14 14"><path d="M13 0v1h-2L6 13h2v1H1v-1h2L8 1H6V0h7z" fill="#787878"/></svg>
          </span>
          <span :class="['filter', {active: states.underline}]" @click="sendFilter('underline')">
            <svg viewBox="0 0 14 14"><path d="M12 0v6.5C12 8.985 9.761 11 7 11S2 8.985 2 6.5V0h2v6.5c0 .628.285 1.23.802 1.695C5.379 8.714 6.159 9 7 9s1.621-.286 2.198-.805C9.715 7.729 10 7.127 10 6.5V0h2zM2 12h10v2H2v-2z" fill="#787878"/></svg>
          </span>
          <span :class="['filter', {active: states.strike}]" @click="sendFilter('strike')">
            <svg viewBox="0 0 14 14"><path d="M15 8h-3.664c.43.602.664 1.292.664 2 0 1.107-.573 2.172-1.572 2.921C9.501 13.617 8.283 14 7 14s-2.501-.383-3.428-1.079C2.573 12.172 2 11.107 2 10h2c0 1.084 1.374 2 3 2s3-.916 3-2-1.374-2-3-2h-8V7h4.68a3.003 3.003 0 0 1-.108-.079C2.573 6.172 2 5.107 2 4c0-1.107.573-2.172 1.572-2.921C4.499.383 5.717 0 7 0s2.501.383 3.428 1.079C11.427 1.828 12 2.893 12 4h-2c0-1.084-1.374-2-3-2s-3 .916-3 2 1.374 2 3 2c1.234 0 2.407.354 3.32 1H15v1z" fill="#787878"/></svg>
          </span>
          <span :class="['filter', {active: states.heading}]" @click="sendFilter('heading')">
            <svg viewBox="0 0 14 14"><path d="M0 6h6v2H4v6H2V8H0V6zm14-4h-3.934v12H7.933V2H3.999V0h10L14 2z" fill="#787878"/></svg>
          </span>
          <span :class="['filter', {active: states.ordered}]" @click="sendFilter('ordered')">
            <svg viewBox="0 0 16 16"><path fill="#787878" d="M6 13h10v2H6zm0-6h10v2H6zm0-6h10v2H6zM3 0v4H2V1H1V0zM2 8.219V9h2v1H1V7.719l2-.938V6H1V5h3v2.281zM4 11v5H1v-1h2v-1H1v-1h2v-1H1v-1z"/></svg>
          </span>
          <span :class="['filter', {active: states.unordered}]" @click="sendFilter('unordered')">
            <svg viewBox="0 0 16 16"><path fill="#787878" d="M6 1h10v2H6V1zm0 6h10v2H6V7zm0 6h10v2H6v-2zM0 2a2 2 0 1 1 3.999-.001A2 2 0 0 1 0 2zm0 6a2 2 0 1 1 3.999-.001A2 2 0 0 1 0 8zm0 6a2 2 0 1 1 3.999-.001A2 2 0 0 1 0 14z"/></svg>
          </span>
        </template>
        <template v-else>
          <span v-if="!mobile" class="subtext small left-margin-10">Shift+Enter for new line.</span>
        </template>
        <div>
          <span class="filter" @click="toggleMedia">
            <svg viewBox="0 0 16 16"><path fill="#787878" d="M10.404 5.11L9.389 4.096 4.314 9.17a2.152 2.152 0 1 0 3.045 3.044l6.09-6.089a3.588 3.588 0 0 0-5.075-5.074L1.98 7.444l-.014.013c-1.955 1.955-1.955 5.123 0 7.077s5.123 1.954 7.078 0l.013-.014.001.001 4.365-4.364-1.015-1.014-4.365 4.363-.013.013c-1.392 1.392-3.656 1.392-5.048 0a3.572 3.572 0 0 1 .014-5.06l-.001-.001L9.39 2.065c.839-.84 2.205-.84 3.045 0s.839 2.205 0 3.044l-6.09 6.089a.718.718 0 0 1-1.015-1.014l5.075-5.075z"/></svg>
          </span>
          <span class="filter" @click="toggleEmoji">
            <svg viewBox="0 0 16 16"><path fill="#787878" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM8 1.5a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13zm0 7.856c1.812 0 3.535-.481 5-1.327C12.772 10.817 10.607 13 8 13s-4.772-2.186-5-4.973a10.017 10.017 0 0 0 5 1.329zM4 5.5C4 4.672 4.448 4 5 4s1 .672 1 1.5S5.552 7 5 7s-1-.672-1-1.5zm6 0c0-.828.448-1.5 1-1.5s1 .672 1 1.5S11.552 7 11 7s-1-.672-1-1.5z"/></svg>
          </span>
        </div>
      </span>

      <!-- New Thread Title -->
      <div v-if="type === 'new'" class="flex none row">
        <input
          type="text"
          :class="['underline', 'title', {error: titleError}]"
          placeholder="Title"
          @input="updateTitle"
          :value="title"
          tabindex="1"
          v-focus
        >
        <button :class="['small', 'auto', {untoggle: !hasPoll}]" @click="togglePoll">Add Poll</button>
      </div>

      <!-- Squire Editor -->
      <squire :type="type" @path-change="buttonState" />

      <!-- Emoji Display -->
      <emoji-keyboard v-if="showEmoji" @select="insertEmoji" />

      <!-- Media Display -->
      <media-keyboard v-if="showMedia" @insert="insertMedia" />
    </template>

    <!-- Poll Keyboard -->
    <poll-keyboard v-if="view === 'poll'" />

    <!-- Tags Keyboard -->
    <tags-keyboard v-if="view === 'tags'" />
  </div>
</template>

<script>
import Vuex from 'vuex';
import Squire from './Squire.vue';
import EmojiKeyboard from './EmojiKeyboard.vue';
import MediaKeyboard from './MediaKeyboard.vue';
import PollKeyboard from './PollKeyboard.vue';
import TagsKeyboard from './TagsKeyboard.vue';
import {isMobile} from '../utils';

export default {
  name: 'text-editor',
  components: {Squire, EmojiKeyboard, MediaKeyboard, PollKeyboard, TagsKeyboard},
  props: ['content'],
  data() {
    return {
      mobile: isMobile(),
      states: {
        bold: false,
        italic: false,
        underline: false,
        strike: false,
        heading: false,
        ordered: false,
        unordered: false,
      },
    };
  },
  mounted() {
    this.$emit('mounted');
  },
  computed: Vuex.mapState({
    showEmoji: state => state.editor.emoji,
    showMedia: state => state.editor.media,
    type: state => state.editor.type,
    title: state => state.editor.title,
    hasPoll: state => state.editor.hasPoll,
    view: state => state.editor.view,
    show: state => state.editor.show,
    titleError: state => state.editor.titleError,
  }),
  watch: {
    show(val) {
      if (!val) {
        this.$store.commit('toggleEditorEmoji', false);
        this.$store.commit('toggleEditorMedia', false);
      }
    },
  },
  methods: {
    updateTitle(e) {
      this.$store.commit('updateTitle', e.target.value);
    },
    sendFilter(filter) {
      this.$emit(filter);
    },
    buttonState(editor) {
      this.states.bold = editor.hasFormat('B');
      this.states.italic = editor.hasFormat('I');
      this.states.underline = editor.hasFormat('U');
      this.states.strike = editor.hasFormat('S');
      this.states.heading = editor.hasFormat('H2');
      this.states.ordered = editor.hasFormat('OL');
      this.states.unordered = editor.hasFormat('UL');
    },
    toggleEmoji() {
      this.$store.commit('toggleEditorEmoji');
    },
    insertEmoji(src) {
      this.$emit('insertEmoji', src);
    },
    toggleMedia() {
      this.$store.commit('toggleEditorMedia');
    },
    insertMedia({type, data}) {
      if (type === 'link') {
        this.$emit('insertLink', data);
      } else if (type === 'video') {
        this.$emit('insertVideo', data);
      } else if (type === 'image') {
        this.$emit('insertImage', data);
      }
    },
    togglePoll() {
      this.$store.commit('toggleEditorPoll');
    },
  },
  beforeDestroy() {
    this.$store.commit('toggleEditorEmoji', false);
    this.$store.commit('toggleEditorMedia', false);
    this.$store.commit('updateTitle', '');
    this.$store.commit('setEditorPoll');
    this.$store.commit('setEditorView', 'editor');
    this.$store.commit('toggleEditorPoll', false);
  },
};
</script>
