<template>
  <transition name="fade">
    <div v-if="showTerms" class="login-modal-container flex middle center">
      <div class="login-modal light flex column middle center native">
        <terms class="overflow-scroll margin-10"></terms>
        <div class="button auto color bottom-margin-10" @click="close">Accept</div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';
import Terms from './routes/Terms.vue';

export default {
  name: 'accept-terms',
  components: {Terms},
  computed: Vuex.mapState({
    showTerms: state => state.auth.showTerms,
  }),
  methods: {
    close() {
      this.$store.commit('setAuthData', {showTerms: false});
    },
  },
};
</script>
