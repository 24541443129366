<template>
  <div class="info-box fill emojis">
    <span class="box extra-padding">
      <span class="icons left">
        <span
          v-for="(category, i) in categories"
          :class="['icon', category, {active: activeCategory === category}]"
          @click="switchCategory(category)"
          :key="i"
        ></span>
      </span>
      <span class="icon close dark right" @click="closeEmoji"></span>
    </span>
    <div id="emojis" class="info">
      <span class="category">{{ names[activeCategory] }}</span>
      <img v-for="(src, i) in emojiSrc" :src="src" @click="select(src)" :key="`src-${i}`">
      <img v-for="(phantomEmoji, i) in numPhantomEmoji" class="hidden" :key="`phantom-${i}`">
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import Emoji from '../store/emoji';
import {getNumPhantomElements} from '../utils';

export default {
  name: 'emoji-keyboard',
  data() {
    return {
      categories: ['people', 'nature', 'food', 'activity', 'travel', 'objects', 'symbols'],
      activeCategory: 'people',
      names: {
        people: 'SMILEYS & PEOPLE',
        nature: 'ANIMALS & NATURE',
        food: 'FOOD & DRINK',
        activity: 'ACTIVITY',
        travel: 'TRAVEL & PLACES',
        objects: 'OBJECTS',
        symbols: 'SYMBOLS',
      },
      numPhantomEmoji: 0,
    };
  },
  computed: Vuex.mapState({
    emojiSrc() {
      const cat = Emoji[this.activeCategory];
      const keys = Object.keys(cat);
      return keys.map(key => `https://cdn.jsdelivr.net/emojione/assets/svg/${cat[key]}.svg`);
    },
  }),
  methods: {
    closeEmoji() {
      this.$store.commit('toggleEditorEmoji');
      this.activeCategory = 'people';
    },
    select(emoji) {
      this.$emit('select', emoji);
      this.closeEmoji();
    },
    switchCategory(category) {
      this.activeCategory = category;
      this.$el.querySelector('#emojis').scrollTop = 0;
    },
    spaceElements() {
      const container = this.$el.querySelector('#emojis');
      const containerPadding = 30;
      const containerWidth = container.offsetWidth - containerPadding;
      this.numPhantomEmoji = getNumPhantomElements({
        numElements: this.emojiSrc.length,
        elementWidth: 28,
        spaceBetween: 15,
        containerWidth,
      });
    },
  },
  mounted() {
    this.spaceElements();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.spaceElements);
    }

    // Setup the custom scrollbars.
    if (this.$store.state.app.type !== 'mobile') {
      this.$nextTick(() => {
        this.ps = new PerfectScrollbar(this.$el.querySelector('#emojis'), {suppressScrollX: true});
      });
    }
  },
  beforeDestroy() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps.destroy();
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.spaceElements);
    }
  },
};
</script>
