<template>
  <div class="container">
    <!-- Description -->
    <div class="heading">
      <h1 class="bottom-margin-10">Join the social gaming revolution.</h1>
      <h2>Games are better when you play with friends. GoldFire enables communities in and around our games to enjoy unique and exciting gameplay by focusing on true social interactions.</h2>
    </div>

    <!-- Feature List -->
    <div class="features">
      <div v-for="feature in features" class="item">
        <div :class="['icon', 'feature', feature.icon]"></div>
        <div class="title">{{ feature.title }}</div>
        <div class="details">{{ feature.details }}</div>
      </div>
    </div>

    <!-- Start Playing -->
    <router-link to="/signup" class="button outline auto third">Start Playing</router-link>
  </div>
</template>

<script>
export default {
  name: 'index-offline',
  data() {
    return {
      features: [
        {
          icon: 'unique',
          title: 'Unique Games',
          details: 'You won’t find games like these anywhere else. Experience unique genres, mechanics and more.',
        },
        {
          icon: 'instant',
          title: 'Instant Play',
          details: 'Never wait for a download again. All of our games are playable in any browser to get you right to the action.',
        },
        {
          icon: 'screens',
          title: 'Play Everywhere',
          details: 'Get the same great gaming and community experience whether on your phone, desktop or other device.',
        },
        {
          icon: 'community',
          title: 'Real Communities',
          details: 'Our platform supports authentic communities in and around our games. Lifelong friendships start here.',
        },
        {
          icon: 'identity',
          title: 'Single Identity',
          details: 'Your identity and profile follows you from game to game so you can make progress no matter what you play.',
        },
        {
          icon: 'gift',
          title: 'Always Free',
          details: 'All of our games are free to play with no subscriptions or purchases required to get the full experience.',
        },
      ],
    };
  },
  meta: {
    titleTemplate: null,
  },
  serverCacheKey() {
    return true;
  },
};
</script>
