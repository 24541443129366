<template>
  <div class="content">
    <div v-for="medal in medals" :class="['box', 'notice', 'wide', 'middle', 'color', {inactive: medal.p < 100}]">
      <div class="progress" :style="{width: `${medal.p}%`}"></div>
      <img :src="medal.src" class="avatar square">
      <div class="text full">
        <div class="user medal">
          <div>
            <span class="title">{{ medal.title }}</span>
            <div>{{ medal.desc }}</div>
          </div>
        </div>
        <span class="time right" v-if="medal.p < 100">{{ medal.p }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {medalUrl} from '../utils';

export default {
  name: 'medals-view',
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'MEDALS',
      buttons: [{
        label: 'Leaderboards',
        view: 'leaderboards-view',
      }],
    });

    this.$store.dispatch('getGameMedals', this.gid);
    this.$store.dispatch('sub', `medalUpdated:${this.uid}`);
  },
  computed: Vuex.mapState({
    uid: state => state.user.uid,
    gid: state => state.leaderboards.gid,
    medals(state) {
      return state.leaderboards.medals.map((medal) => {
        return {
          title: medal.title,
          desc: medal.desc,
          secret: medal.secret,
          src: medalUrl(medal.gid, medal.img),
          p: Math.floor(medal.p * 100),
        };
      });
    },
  }),
  beforeDestroy() {
    this.$store.dispatch('unsub', `medalUpdated:${this.uid}`);
  },
};
</script>
