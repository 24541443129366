<template>
  <div class="content no-horiz-padding">
    <div
      v-for="player in players"
      :id="`id-${player.uid}`"
      :class="['inf', 'box', 'notice', 'user', 'wide', 'middle', {light: highlight(player)}]">
      <avatar :player="player" :history="history"></avatar>
      <div class="text full">
        <div class="user">
          <username :player="player" :history="history"></username>
        </div>
        <span class="sub-value">{{ player.formattedScore }}</span>
      </div>
      <span class="text-counter large light">{{ player.formattedRank }}</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import Avatar from './Avatar.vue';
import Username from './Username.vue';
import {numberWithCommas, avatarUrl} from '../utils';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'leaderboard-view',
  components: {Avatar, Username},
  mixins: [infiniteScrolling],
  mounted() {
    const buttons = [
      {label: 'All Users', view: {mutation: 'toggleLeaderboardUsers'}},
    ];

    if (this.showMonth) {
      buttons.push({label: 'All-Time', view: {mutation: 'toggleLeaderboardTime'}});
    }

    this.$store.commit('setPanelData', {
      title: this.user ? `${this.title} (${this.user})` : this.title,
      buttons,
      altIcon: 'me',
      altMethod: {method: this.jumpToPlayer},
    });

    if (this.user) {
      this.jumpToPlayer();
    } else {
      this.load();
    }
  },
  watch: {
    allTime() {
      if (this.notLoggedInUser) {
        this.jumpToPlayer();
        this.$store.commit('scrollToTop');
      } else {
        this.load().then(() => {
          this.$store.commit('scrollToTop');
        });
      }
    },
    allUsers() {
      if (this.notLoggedInUser) {
        this.jumpToPlayer();
      } else {
        this.load().then(() => {
          this.$store.commit('scrollToTop');
        });
      }
    },
  },
  methods: {
    jumpToPlayer() {
      this.$store.commit('setLeaderboardPlayers', {players: []});

      // If the player is already on the page, go back to the top.
      if (this.playerOnPage) {
        this.load().then(() => {
          this.$store.commit('scrollToTop');
        });
        return;
      }

      // Otherwise, reload the list with the user's score as the key.
      this.$store.commit('setScrollData', {start: false, end: false});
      this.load(null, {jump: this.uid}).then(() => {
        // Scroll to the player on the page.
        this.$nextTick(() => {
          const player = this.$el.querySelector('.box.user.light');

          if (player) {
            this.$store.commit('setScrollAreaPosition', {
              top: player.offsetTop - 24,
            });
          }
        });
      });
    },
    highlight(player) {
      return player.uid === this.uid;
    },
    history() {
      this.$store.commit('addHistory', {
        mutation: 'viewLeaderboard',
        data: this.$store.state.leaderboards.activeCategory,
      });
    },
  },
  computed: Vuex.mapState({
    playerOnPage(state) {
      const top = state.panel.scrollTop;
      const player = this.$el.querySelector('.box.user.light');
      if (!player) {
        return false;
      }

      // 84 is default min row hieght
      // get this from inifinte scroll config
      const heightDiff = player.offsetTop - top;
      return heightDiff > 0 && heightDiff < (this.limit * 84) / 1.25;
    },
    lid: state => state.leaderboards.activeCategory._id,
    showMonth: state => state.leaderboards.activeCategory.monthly && state.leaderboards.activeCategory.all,
    title: state => state.leaderboards.activeCategory.name,
    user: state => state.leaderboards.user,
    uid: state => state.leaderboards.uid,
    infiniteScrollingCfg(state) {
      return {
        minRowHeight: 60,
        actionName: 'loadLeaderboard',
        actionParams: {lid: this.lid, uid: this.uid},
        items: state.leaderboards.players,
        mutationName: 'setLeaderboardPlayers',
        keys: ['score'],
      };
    },
    players(state) {
      return state.leaderboards.players
        .map((player) => {
          const formattedScore = numberWithCommas(Math.floor(player.score));
          const formattedRank = `#${numberWithCommas(player.rank)}`;
          const avatarurl = avatarUrl(player.avatar);

          return Object.assign({formattedScore, formattedRank, avatarurl}, player);
        });
    },
    allTime(state) {
      return state.leaderboards.allTime;
    },
    allUsers(state) {
      return state.leaderboards.allUsers;
    },
  }),
  beforeDestroy() {
    this.$store.state.leaderboards.allTime = true;
    this.$store.state.leaderboards.allUsers = true;
    this.$store.commit('setLeaderboardPlayers', {items: []});
  },
};
</script>
