<template>
  <div id="media" class="info media-type">
    <dropzone bucket="img.goldfire.me" type="editor"></dropzone>
  </div>
</template>

<script>
import Dropzone from './Dropzone.vue';

export default {
  name: 'media-keyboard-image',
  components: {Dropzone},
};
</script>
