<template>
<div :class="['scroll-area', {short: editorOpen}]">
    <slot></slot>
  </div>
</template>

<script>
import Vuex from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'scroll-area',
  mounted() {
    if (typeof window !== 'undefined') {
      this.$parent.$on('mounted', () => {
        if (this.$store.state.app.type !== 'mobile') {
          this.ps = new PerfectScrollbar(this.$el, {
            suppressScrollX: true,
            swipePropagation: false,
          });
        }
        this.$el.addEventListener('scroll', this.setScroll);

        // Hack to get the scrollbars to show as soon as the panel opens.
        if (this.$store.state.app.type !== 'mobile' && this.ps) {
          setTimeout(() => {
            this.ps.update();
          }, 1);
        }
      });
    }
  },
  methods: {
    setScroll() {
      const curScrollStart = this.$store.state.panel.scrollStart;
      const curScrollEnd = this.$store.state.panel.scrollEnd;
      // -1 because scrolling slowly, user cannot reach full scroll height.
      const scrollHeight = this.$el.scrollHeight - this.$el.offsetHeight - 1;
      const {scrollTop} = this.$el;

      if (scrollHeight > 0) {
        if (scrollTop >= scrollHeight && !curScrollEnd) {
          this.$store.commit('setScrollAreaPosition', {start: false, end: true, top: scrollTop});
        } else if (scrollTop < scrollHeight && scrollTop > 0 && curScrollEnd) {
          this.$store.commit('setScrollAreaPosition', {start: false, end: false, top: scrollTop});
        } else if (scrollTop === 0 && !curScrollStart) {
          this.$store.commit('setScrollAreaPosition', {start: true, end: false, top: scrollTop});
        } else if (scrollTop > 0 && curScrollStart) {
          this.$store.commit('setScrollAreaPosition', {start: false, end: false, top: scrollTop});
        }
      }
    },
  },
  computed: Vuex.mapState({
    scrollTop: state => state.panel.scrollTop,
    scrollToBottom: state => state.panel.scrollToBottom,
    direction: state => state.scroll.direction,
    updateScroll: state => state.panel.updateScroll,
    editorOpen: state => state.editor.show,
  }),
  watch: {
    editorOpen(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit('scrollToBottom');
        }, 500);
      }
    },
    scrollTop(val) {
      this.$el.scrollTop = val;
      if (this.$store.state.app.type !== 'mobile' && this.ps) {
        this.ps.update();
      }
    },
    updateScroll(val) {
      if (val) {
        if (this.$store.state.app.type !== 'mobile' && this.ps) {
          setTimeout(() => {
            this.ps.update();
          }, 1);
        }
        this.$store.commit('updateScrollBars');
      }
    },
    scrollToBottom(val) {
      if (!val) {
        return;
      }

      this.$store.commit('scrollToBottom', false);

      if (this.$store.state.app.type !== 'mobile' && this.ps) {
        this.ps.update();
      }
      this.$nextTick(() => {
        this.$store.commit('setScrollAreaPosition', {
          top: this.$el.scrollHeight - this.$el.offsetHeight,
        });
      });
    },
  },
  beforeDestroy() {
    if (this.$store.state.app.type !== 'mobile' && this.ps) {
      this.ps.destroy();
    }
    this.$el.removeEventListener('scroll', this.setScroll);
  },
};
</script>
