<template>
  <div class="content">
    <social-feed-update :update="update" index="0" v-if="update"></social-feed-update>
    <social-feed-comments :update="update" index="0" v-if="update"></social-feed-comments>
  </div>
</template>

<script>
import Vuex from 'vuex';
import SocialFeedComments from './SocialFeedComments.vue';
import SocialFeedUpdate from './SocialFeedUpdate.vue';

export default {
  name: 'social-feed-update-view',
  components: {SocialFeedUpdate, SocialFeedComments},
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'SOCIAL FEED',
      buttons: [],
    });
  },
  computed: Vuex.mapState({
    update: state => state.socialFeed.updates[0],
  }),
};
</script>
