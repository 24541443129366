<template>
  <div :class="['game-box', game, {inactive: !data.active, 'max-width': !auth, 'no-link': !auth || !hasLeaderboards && context === 'profile'}]">

    <!-- Inactive Display -->
    <div class="overlay" v-if="!data.active" @click="switchGame"></div>
    <span class="play" v-if="!data.active">Play {{ title }}</span>

    <!-- Active Display -->
    <div class="title absolute-top" v-if="data.active">
      {{ title }}
      <div class="ranking">
        <template v-if="context === 'profile'">
          <live-time :timestamp="data.t"></live-time>
          <span :class="['icon', 'time', {'right-margin-15': data.rank}]"></span>
        </template>
        <template v-if="data.rank">
          #{{ data.rank | numberWithCommas }}
          <span class="icon ranking"></span>
        </template>
      </div>
    </div>

    <span class="click-area" v-if="data.active" @click="switchGame"></span>

    <!-- Launcher View -->
    <div class="box click bottom" v-if="data.active && context === 'launcher'" @click.prevent="viewNotifications">
      View Notifications
      <div class="counter">{{ data.notifications | numberWithCommas }}</div>
    </div>

    <!-- User Profile View -->
    <div class="box click bottom" v-if="auth && data.active && hasLeaderboards && context === 'profile'" @click.prevent="viewLeaderboards">
      View Leaderboards
      <div class="icon forward"></div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import {numberWithCommas} from '../utils';

export default {
  name: 'game-display',
  components: {LiveTime},
  props: ['data', 'context', 'user'],
  data() {
    const {state} = this.$store;

    return {
      stateNs: this.context === 'launcher' ? state.launcher : state.launcher.profile,
    };
  },
  methods: {
    viewNotifications() {
      this.$parent.viewNotifications(this.data.game);
    },
    viewLeaderboards() {
      this.$store.commit('setLeaderboardGame', {
        gid: this.data.game,
        uid: this.user.uid,
        user: this.user.username,
      });
      this.$store.commit('changeView', 'leaderboards-view');
    },
    switchGame() {
      // TODO: This logic is duplicated in Game.vue

      // Reload the current game if that is what they clicked on.
      // Otherwise, take them to the new game.
      if (this.game === this.$store.state.activeGame.game) {
        this.$store.commit('setHideIframe', true);
        this.$nextTick(() => {
          this.$store.commit('setHideIframe', false);
        });
      } else if (this.$store.state.app.type === 'mobile') {
        // If we are in the mobile app, open the game in the browser.
        window.parent.postMessage({
          action: 'openInBrowser',
          url: `https://goldfire.me/${this.game}`,
        }, '*');
      } else if (this.$store.state.app.type === 'steam') {
        // TODO: this find isn't great.
        const steamUrl = this.$store.state.gameData
          .find(game => game.gid === this.data.game)
          .apps.find(x => x.icon === 'steam');
        if (steamUrl) {
          window.open(steamUrl.url);
        } else {
          window.open(`https://goldfire.me/${this.game}`);
        }
      } else {
        this.$store.commit('setHideIframe', true);
        this.$nextTick(() => {
          this.$store.commit('setHideIframe', false);
          this.$router.push(`/${this.game}`);
        });
      }

      if (this.$store.state.toolbar.activeTab === 'launcher') {
        this.$store.commit('toggleTab', 'launcher');
      }
    },
  },
  filters: {
    numberWithCommas,
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    game(state) {
      return this.data ? state.gameData.find(game => game.gid === this.data.game).game : '';
    },
    hasLeaderboards(state) {
      return this.data ? state.gameData.find(game => game.gid === this.data.game).leaderboards : false;
    },
    title(state) {
      return this.data ? state.gameData.find(game => game.gid === this.data.game).title : '';
    },
  }),
};
</script>
