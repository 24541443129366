<template>
  <img
    :src="data.avatar ? avatarUrl(data.avatar) : deletedAvatar"
    v-tooltip="tooltip"
    :class="['avatar', {click: !noAction && data.uid}, {inactive}]"
    @click="viewProfile(player.uid)"
  >
</template>

<script>
import {avatarUrl} from '../utils';
import deletedUserAvatar from '../assets/images/icons/deleted-user-avatar.png';

export default {
  name: 'avatar',
  props: ['player', 'noAction', 'showTooltip', 'history'],
  data() {
    return {
      deletedAvatar: deletedUserAvatar,
    };
  },
  computed: {
    data() {
      return this.player || {};
    },
    tooltip() {
      if (this.showTooltip) {
        return {content: (this.data.username || this.data.user), classes: this.data.role};
      }

      return false;
    },
    inactive() {
      return (!this.noAction && this.data.status && this.data.status !== 'on') || !this.data.uid;
    },
  },
  methods: {
    avatarUrl,
    viewProfile() {
      if (!this.noAction && this.data.uid) {
        if (!this.$store.state.user.auth) {
          this.$router.push(`/u/${this.data.username || this.data.user}`);
        }

        this.$store.dispatch('viewProfile', {uid: this.data.uid, shouldAddHistory: !this.history});

        if (this.history) {
          this.history();
        }
      }
    },
  },
};
</script>
