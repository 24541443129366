<template>
  <div class="login">
    <div class="content">
      <h3>Create Identity</h3>

      <!-- Username Input -->
      <div class="top-margin-10 bottom-margin-30 full">
        <input
          type="text"
          :class="{underline: true, error: error.userLen || error.badUser || error.userTaken}"
          placeholder="Username"
          v-model="user"
          @keyup.enter="signup"
          ref="user"
          v-focus="userFocus"
        >
        <div class="red input-error" v-if="error.userLen">Username must be 3-15 characters.</div>
        <div class="red input-error" v-else-if="error.badUser">Usernames must be alphanumeric.</div>
        <div class="red input-error" v-else-if="error.userTaken">
          Username is already taken.<br>
          <a href='#' class="link" @click.prevent="toggleLogin">Login</a> if this is you.
        </div>
      </div>

      <!-- Play Now Button -->
      <button-loader
        value="Play Now"
        :styles="{'color': true, 'bottom-margin-30': true}"
        :loading="loading"
        :click="signup"
      ></button-loader>
      <div class="red input-error" v-if="error.unknown">An unknown error occured, please try again.</div>

      <!-- Forgot Pass/Login Link -->
      <a href="#" class="link" @click.prevent="toggleLogin">Already have an account?</a>
    </div>
  </div>
</template>

<script>
import ButtonLoader from './ButtonLoader.vue';
import {readCookie, setCookie, iOSDevice} from '../utils';

export default {
  name: 'guest-signup',
  components: {ButtonLoader},
  data() {
    return {
      loading: false,
      userFocus: true,
      user: '',
      error: {
        userLen: false,
        badUser: false,
        userTaken: false,
        unknown: false,
      },
    };
  },
  mounted() {
    // Listen for the response from the app.
    if (this.$store.state.app.type === 'mobile') {
      window.addEventListener('message', this.appLoginMessage);
    }
  },
  methods: {
    toggleLogin() {
      this.$store.commit('setAuthData', {activeView: 'login'});
    },
    appLoginMessage(event) {
      let {data} = event;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {}
      }

      if (data.action !== 'mobileLogin') {
        return;
      }

      // Set the session cookie.
      const expires = new Date(Date.now() + 31536000000).toGMTString();
      document.cookie = `sid=${data.sid}; expires=${expires}; path=/`;

      // Run the authentication across the server.
      this.$store.dispatch('reAuth');

      // Cancel the event listener.
      window.removeEventListener('message', this.appLoginMessage);
    },
    signup() {
      const err = this.error;

      // Reset the errors.
      err.userLen = false;
      err.badUser = false;
      err.userTaken = false;
      err.unknown = false;

      // Run the same static checks as on the server.
      if (!this.user || this.user === '' || this.user.length < 3 || this.user.length > 15) {
        err.userLen = true;
      }
      if (!/^[A-Za-z0-9_]{3,15}$/.test(this.user)) {
        err.badUser = true;
      }

      // If there are no errors, send the request to the server.
      if (!err.userLen && !err.badUser) {
        // Update the state of the button
        this.loading = true;

        // Send the signup request to the server.
        this.$store.dispatch('guestSignup', {
          user: this.user,
          src: readCookie('src'),
        }).then((data) => {
          this.loading = false;
          Object.assign(this.error, data.errors);

          if (this.error.userTaken) {
            this.userFocus = true;
          }

          // Clear the src cookie.
          setCookie('src', null, 0);

          // Show terms acceptance modal on iOS.
          const isIOS = this.$store.state.app.type === 'mobile' && iOSDevice();
          if (this.$store.state.activeGame.gid === 4 && isIOS) {
            this.$store.commit('setAuthData', {showTerms: true});
          }
        });
      }
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('message', this.appLoginMessage);
    }
  },
};
</script>
