<template>
  <span
    v-if="show"
    :class="['text-counter', 'medium', 'regular', 'left-margin-15']"
    @click="onClick"
  >
    <span class="icon dark flag"></span>
  </span>
</template>

<script>
import Vuex from 'vuex';
import {iOSDevice} from '../utils';

export default {
  name: 'flag-icon',
  computed: Vuex.mapState({
    show: state => state.app.type === 'mobile' && iOSDevice(),
  }),
  methods: {
    onClick() {
      if (window.confirm('Report this post to moderators?')) {
        // TODO: This should actually do something at some point.
      }
    },
  },
};
</script>
