<template>
  <div class="container forums">
    <div class="text-center bottom-margin-30 strong"><h3>{{ title }}</h3></div>
    <forum-thread-view v-if="show"></forum-thread-view>

    <!-- Pagination -->
    <div class="flex center top-margin-30">
      <router-link :to="backLink" :class="['button', 'outline', 'small', 'auto', 'right-margin-5', {disabled: !curPage}]">
         Back
      </router-link>
      <router-link :to="nextLink" :class="['button', 'outline', 'small', 'auto', 'left-margin-5', {disabled: posts.length < 25}]">
         Next
      </router-link>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumThreadView from '../ForumThreadView.vue';

export default {
  name: 'forums-thread',
  components: {ForumThreadView},
  data() {
    return {
      show: false,
    };
  },
  meta() {
    return {
      title: this.title,
    };
  },
  created() {
    if (this.$store.state.forums.activeThread.posts.length > 0) {
      this.show = true;
    }
  },
  mounted() {
    const {state} = this.$store;
    const {pid} = state.route.params;
    const tid = state.route.params.id;

    if (this.$store.state.user.auth) {
      this.$store.commit('setForumThreadPostId', pid);
      this.$store.commit('toggleTab', 'forums');
      this.$store.dispatch('viewForumThread', {_id: tid});
      this.$router.replace('/');
    } else if (this.posts.length === 0) {
      this.show = true;
      this.$store.dispatch('viewForumThread', {_id: tid});
    } else if (pid) {
      this.show = true;

      // Scroll to the PID.
      const post = this.$el.querySelector(`#id-${pid}`);
      if (post) {
        document.body.scrollTop = post.offsetTop;
      }
    }
  },
  computed: Vuex.mapState({
    tid: state => state.route.params.id,
    title: state => state.forums.activeThread.title,
    posts: state => state.forums.activeThread.posts,
    curPage: state => parseInt(state.route.params.page || 0, 10),
    backLink(state) {
      const curPage = parseInt(state.route.params.page, 10);
      const back = curPage > 1 ? `/${curPage - 1}` : '';
      return `/forums/thread/${state.route.params.id}/${state.route.params.title}${back}`;
    },
    nextLink(state) {
      const next = (parseInt(state.route.params.page, 10) || 0) + 1;
      return `/forums/thread/${state.route.params.id}/${state.route.params.title}/${next}`;
    },
  }),
  watch: {
    curPage(val) {
      if (val) {
        this.$store.commit('setForumPosts', {posts: [], concat: false});
        this.$store.dispatch('loadForumPosts', {tid: this.tid, page: val});
      }
    },
  },
  beforeDestroy() {
    document.body.style.height = '';
  },
};
</script>
