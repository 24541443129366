<template>
  <div class="container dashboard flex wrap justify">
    <div class="stat flex column middle quarter" @click="viewTab('forums', 'forums-view')">
      <span class="value">{{ forums }}</span>
      <span class="label">Forum Posts</span>
    </div>
    <div class="stat flex column middle quarter" @click="viewTab('forums', 'social-feed-view')">
      <span class="value">{{ socialFeed }}</span>
      <span class="label">Social Feed</span>
    </div>
    <div class="stat flex column middle quarter" @click="viewTab('chat', 'chat-list-view')">
      <span class="value">{{ messages }}</span>
      <span class="label">Messages</span>
    </div>
    <div class="stat flex column middle quarter" @click="viewTab('launcher', 'launcher-view')">
      <span class="value">{{ notifications }}</span>
      <span class="label">Notifications</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'index-dashboard',
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    // this.$store.dispatch('loadOnlineFriends');
    this.$store.dispatch('getSocialFeedCounter');
    this.$store.dispatch('getForumCounter');
    this.$store.dispatch('getGamesOnlineCounters', [1, 3, 4]);
    this.$store.dispatch('sub', `socialFeedCounter:${this.$store.state.user.uid}`);
    this.$store.dispatch('sub', 'updateForumCategoryUnreadCount:all');

    // Update the online counters with pub/sub.
    this.$store.dispatch('sub', 'setOnlineCounter:all');
  },
  computed: Vuex.mapState({
    messages: state => state.chat.counter,
    notifications: state => state.launcher.counter,
    socialFeed: state => state.socialFeed.counter,
    forums: state => state.forums.counter,
    view: state => state.forums.activeView,
  }),
  watch: {
    view(val) {
      if (val === 'forum-threads-list-view') {
        this.$store.dispatch('getForumCounter');
      }
    },
  },
  methods: {
    viewTab(tab, view) {
      this.$store.commit('toggleTabView', {tab, view});
    },
  },
  meta: {
    titleTemplate: null,
  },
  beforeDestroy() {
    this.$store.dispatch('unsub', `socialFeedCounter:${this.$store.state.user.uid}`);
    this.$store.dispatch('unsub', 'updateForumCategoryUnreadCount:all');
    this.$store.dispatch('unsub', 'setOnlineCounter:all');

    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
