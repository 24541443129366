<template>
  <div class="squire-wrapper">
    <div
      id="squire"
      class="editable"
      tabindex="2"
      @keyup.enter="enterDown($event, false)"
    ></div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import parser from 'js-video-url-parser';
import striptags from 'striptags';
import PerfectScrollbar from 'perfect-scrollbar';

let Squire = {};
if (typeof document !== 'undefined') {
  Squire = require('squire-rte');

  /**
   * Creates a link from text.
   * This adds functionality not found in squire because the internal formatting
   * forces squire to break the original text into new BRs, causing strange
   * behavior when the link is in the middle of the text.
   * This method is similar to the internal one, but does not force the message
   * to be reformatted.
   * @param {The URL} url The url.
   * @param {object} attributes The link attributes.
   * @param {string} text The text to turn into a string.
   * @return {Object} The instance of squire.
   */
  Squire.prototype.makeLinkWithText = function ( url, attributes, text ) {
    var range = this.getSelection();
    Squire.deleteContentsOfRange(range, this._root)
    Squire.insertNodeInRange(
        range,
        this._doc.createTextNode(text)
    );

    attributes = Object.assign(
        {},
        this._config.tagAttributes.a,
        attributes,
        { href: url },
    );
    this.changeFormat({
      tag: 'A',
      attributes,
    }, {
      tag: 'A',
    }, range);

    return this.focus();
  };
}

export default {
  name: 'squire',
  data() {
    return {
      editor: {},
    };
  },
  props: ['type'],
  mounted() {
    // If this is the server, don't do any of this setup.
    if (typeof window === 'undefined') {
      return;
    }

    // Setup the new Squire editor instance.
    this.editor = new Squire(this.$el.querySelector('#squire'));

    // Update the width on mobile.
    this.editor.addEventListener('focus', () => {
      if (this.notForums && document.body.offsetWidth < 700 && document.body.offsetHeight < 450) {
        this.$store.commit('setPanelWide', true);
      }

      // Hack to fix issue in iOS with keyboard.
      document.body.style.overflow = 'auto';
    });
    this.editor.addEventListener('blur', () => {
      if (this.notForums && document.body.offsetWidth < 700 && document.body.offsetHeight < 450) {
        this.$store.commit('setPanelWide', false);
      }

      // Hack to fix issue in iOS with keyboard.
      document.body.style.overflow = '';
    });

    // Handle the 'willPaste' event to strip the formatting.
    this.editor.addEventListener('willPaste', (event) => {
      // Add the document fragment to a holder DIV so we can get the HTML.
      const div = document.createElement('div');
      div.appendChild(event.fragment);

      // Sanitize the input.
      const cleanHtml = striptags(div.innerHTML, ['b', 'i', 'u', 's', 'br']);

      // Modify the document fragment.
      event.fragment = document.createRange().createContextualFragment(cleanHtml);
    });

    // Setup event listeners for the different filters.
    this.$parent.$on('bold', () => {
      this.editor[this.editor.hasFormat('B') ? 'removeBold' : 'bold']();
    });
    this.$parent.$on('italic', () => {
      this.editor[this.editor.hasFormat('I') ? 'removeItalic' : 'italic']();
    });
    this.$parent.$on('underline', () => {
      this.editor[this.editor.hasFormat('U') ? 'removeUnderline' : 'underline']();
    });
    this.$parent.$on('strike', () => {
      this.editor[this.editor.hasFormat('S') ? 'removeStrikethrough' : 'strikethrough']();
    });
    this.$parent.$on('heading', () => {
      if (!this.editor.hasFormat('H2')) {
        this.editor.modifyBlocks(function(frag) {
          return this.createElement('H2', this._config.tagAttributes.header, [frag]);
        });
      } else {
        this.editor.modifyBlocks(function(frag) {
          return this.createDefaultBlock([frag.firstChild.firstChild]);
        });
      }

      this.editor.focus();
    });
    this.$parent.$on('ordered', () => {
      this.editor[this.editor.hasFormat('OL') ? 'removeList' : 'makeOrderedList']();
    });
    this.$parent.$on('unordered', () => {
      this.editor[this.editor.hasFormat('UL') ? 'removeList' : 'makeUnorderedList']();
    });

    // Listen for a path change to update the UI buttons.
    this.editor.addEventListener('pathChange', () => {
      this.$emit('path-change', this.editor);
    });

    // Listen for inserted emoji.
    this.$parent.$on('insertEmoji', (src) => {
      this.editor.insertHTML(`<img src="${src}" class="emoji">`);
      this.editor.focus();
    });

    // Listen for inserted links.
    this.$parent.$on('insertLink', ({url, text}) => {
      this.editor.makeLinkWithText(url, {target: '_blank'}, text);
      this.editor.focus();
    });

    // Listen for inserted videos.
    this.$parent.$on('insertVideo', ({url}) => {
      const data = parser.parse(url);
      let embed = '';

      if (!data || !data.provider.match(/youtube|vimeo|twitch/)) {
        this.editor.focus();
        return;
      }

      if (data.provider === 'youtube') {
        embed = `<iframe src="//www.youtube.com/embed/${data.id}" frameborder="0" allowfullscreen></iframe>`;
      } else if (data.provider === 'vimeo') {
        embed = `<iframe src="//player.vimeo.com/vieo/${data.id}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
      } else if (data.provider === 'twitch') {
        let twitchUrl = `//player.twitch.tv/?channel=${data.channel}`;

        // Handle video vs channel embeds differently.
        if (data.channel === 'videos') {
          const videoId = url.split('/').pop();
          twitchUrl = `//player.twitch.tv/?video=v${videoId}&autoplay=false`;
        }

        embed = `<iframe src="${twitchUrl}" frameborder="0" class="videoplayer" allowfullscreen></iframe>`;
      }

      // Wrap the iframe.
      embed = `<div><span class="video-wrapper">${embed}</span></div>`;

      // Embed chat below the video.
      if (data.provider === 'twitch' && data.channel !== 'videos' && !this.notForums) {
        embed += `<div><span class="video-wrapper"><iframe src="https://www.twitch.tv/${data.channel}/chat" frameborder="0" class="videoplayer" scrolling="true" id="${data.channel}"></iframe></span></div>`;
      }

      // Insert the video into the editor.
      this.editor.insertHTML(embed);

      // Move the cursor to the end.
      this.editor.focus();
    });

    // Listen for inserted image.
    this.$parent.$on('insertImage', ({img, thumb}) => {
      this.editor.insertImage(thumb || img, {class: 'image'});
      this.editor.focus();
      this.editor.moveCursorToEnd();
    });

    // Listen for the HTML value changing.
    this.editor.addEventListener('input', () => {
      this.updateValue();
    });

    this.editor.setKeyHandler('enter', (sq, event, range) => {
      this.enterDown(event, true);
    });

    // Setup the custom scrollbars.
    if (this.$store.state.app.type !== 'mobile') {
      this.$parent.$on('mounted', () => {
        this.ps = new PerfectScrollbar(this.$el, {suppressScrollX: true});
      });
    }
  },
  methods: {
    updateValue() {
      this.$store.commit('setEditorValue', this.editor.getHTML());
    },
    enterDown(event, down) {
      if (!event.shiftKey) {
        if (this.type === 'chat' || this.type === 'feed') {
          event.preventDefault();
        }
        this.$store.commit('setEditorEnterDown', down);
      }
    },
  },
  computed: Vuex.mapState({
    showEditor: state => state.editor.show,
    reset: state => state.editor.reset,
    initalValue: state => state.editor.initialValue,
    notForums() {
      return this.type !== 'new' && this.type !== 'reply';
    },
  }),
  watch: {
    initialValue(val) {
      if (val !== '') {
        this.editor.setHTML(val);
        this.$store.state.editor.initialValue = '';
      }
    },
    showEditor(val) {
      if (val && this.type !== 'new') {
        const initVal = this.$store.state.editor.initialValue;
        this.editor.setHTML(initVal);
        this.editor.focus();
        this.editor.moveCursorToEnd();
      } else {
        this.editor.setHTML('');
        this.$store.commit('setEditorReset', false);
      }
    },
    reset(val) {
      if (val && this.type !== 'new') {
        this.editor.setHTML('');
        this.$store.commit('setEditorReset', false);
        this.editor.focus();
      }
    },
  },
  beforeDestroy() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps.destroy();
    }
  },
};
</script>
