<template>
  <div class="content">
    <friend-box v-for="player in players" :player="player" :toggle="toggle" class="inf" :id="`id-${player.uid}`" :key="player.uid"></friend-box>
    <div v-if="players.length === 0 && !isLoading" class="box center">
      <span v-if="!searchText || searchText.length === 0">You haven't added any friends yet.</span>
      <span v-else>No friends match that query.</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import FriendBox from './FriendBox.vue';
import {infiniteScrolling} from '../mixins';
import {debounce} from '../utils';

export default {
  name: 'friends-view',
  components: {FriendBox},
  mixins: [infiniteScrolling],
  data() {
    return {
      toggle: null,
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'FRIENDS',
      buttons: [],
      input: {
        placeholder: 'Search Friends...',
        value: '',
      },
    });

    // Calculate the max to show per "page" and load data.
    this.load();
  },
  methods: {
    search: debounce(function search() {
      this.performSearch();
    }, 500),
    performSearch() {
      this.$store.commit('scrollToTop');
      this.$store.commit('setFriendsList', {items: []});
      this.load();
    },
  },
  watch: {
    searchText(val) {
      if (val !== '') {
        this.search();
      } else {
        this.performSearch();
      }
    },
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        minRowHeight: 60,
        actionName: 'loadFriends',
        mutationName: 'setFriendsList',
        items: state.friends.players,
        keys: ['t'],
      };
    },
    players: state => state.friends.players,
    searchText(state) {
      return state.panel.input && state.panel.input.value ? state.panel.input.value : '';
    },
    isLoading: state => state.panel.loading,
  }),
  beforeDestroy() {
    this.$store.dispatch('unsubFriendsList');
  },
  destroyed() {
    this.$store.commit('setPlayerStatus');
    this.$store.commit('setFriendsList', {items: [], concat: false});
  },
};
</script>
