<template>
  <div class="header">
    <div :class="{action: true, left: true, active: isBackActive}" @click="goBackView">
      <div class="icon click back" v-show="isBackActive"></div>
    </div>
    <div class="truncate" v-if="!loading">
      {{ title }}
      <span v-if="status" :class="[status, 'icon', 'left-margin-5']"></span>
    </div>
    <div class="spinner auto-center little" v-else></div>
    <div class="action right">
      <div v-if="altIcon" :class="['icon', 'click', altIcon]" @click="altClick"></div>
      <span v-if="altStatus" :class="['text-counter', 'color', {click: altClick}]" @click="altClick">{{ altStatus }}</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'panel-header',
  computed: Vuex.mapState({
    isBackActive: state => state.panel.history.length > 0,
    title: state => state.panel.title,
    status: state => state.panel.status,
    altStatus: state => state.panel.altStatus,
    altIcon: state => state.panel.altIcon,
    altMethod: state => state.panel.altMethod,
    loading: state => state.scroll.loading && !state.panel.loading,
  }),
  methods: {
    goBackView() {
      this.$store.dispatch('goBackView');
    },
    altClick() {
      if (this.altMethod.mutation) {
        this.$store.commit(this.altMethod.mutation, this.altMethod.data);
      }
      if (this.altMethod.action) {
        this.$store.dispatch(this.altMethod.action, this.altMethod.data);
      }
      if (this.altMethod.method) {
        this.altMethod.method(this.altMethod.data);
      }
    },
  },
};
</script>
