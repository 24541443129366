<template>
  <div class="content">
    <!-- Send Gift -->
    <div class="box input no-border">
      <template v-if="giftView === 0">
        <span class="left-margin-10">Buying for <b>yourself</b>.</span>
        <button class="button fixed small" @click="toggleGift">Send Gift</button>
      </template>
      <template v-else-if="giftView === 1">
        <input type="text" class="gift" maxlength="15" v-model="query" placeholder="Enter Username" @blur="toggleGift" v-focus>
      </template>
    </div>
    <div class="red input-error" v-if="giftError">That username doesn't exist, please try again.</div>
    <dropdown-list :items="usernames" @select="setUsername" v-if="usernames.length > 0"></dropdown-list>

    <!-- Product List -->
    <div class="box input" v-for="product in products">
      <div class="icon product left-margin-10" :style="shopIcon(product)"></div>
      <span class="text-counter extra-large bold fifth">{{ product.title }}</span>
      <div :class="['text-counter', 'small', 'regular', 'fourth', {green: product.bonus > 0.3}]">
        <span v-if="product.bonus">{{ product.percentBonus }} MORE</span>
      </div>
      <button class="button fixed" @click="selectProduct(product)">{{ product.price }}</button>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import DropdownList from './DropdownList.vue';
import {percentFromDecimal, debounce} from '../utils';

export default {
  name: 'shop-view',
  components: {DropdownList},
  data() {
    return {
      giftView: 0,
      query: '',
      usernames: [],
      giftError: false,
    };
  },
  mounted() {
    this.$store.commit('setShopGift', this.query);
    this.$store.commit('setHistory', []);
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [
        {label: 'Manage', view: 'shop-manage-payments-view'},
        {label: 'History', view: 'shop-history-view'},
      ],
    });

    if (this.$store.state.activeGame && this.$store.state.activeGame.shop) {
      if (!this.$store.state.activeGame.shop.length) {
        this.$store.dispatch('getShopProducts', this.$store.state.activeGame.gid);
      } else {
        this.$store.dispatch('getShopCards');
      }
    }
  },
  computed: Vuex.mapState({
    products(state) {
      const game = state.activeGame;
      if (!game || !game.shop) {
        return [];
      }

      return game.shop.map((product) => {
        const percentBonus = percentFromDecimal(product.bonus);
        const {appShop} = state.activeGame;
        let price = `$${product.price}`;

        // Check if we have a custom price from the app store.
        if (appShop) {
          const appProduct = appShop.find(p => p.product === product.id);
          if (appProduct) {
            price = appProduct.price;
          }
        }

        return Object.assign({percentBonus}, product, {price});
      });
    },
  }),
  watch: {
    query() {
      // Do a fuzzy search on countries.
      if (this.$el.querySelector('.gift:focus') && this.query && this.query.length >= 3) {
        this.search();
      }

      // Reset the list if less than 2 characters.
      if (!this.query || this.query.length < 3) {
        this.usernames = [];
      }

      // Keep the query in sync with the state.
      this.$store.commit('setShopGift', this.query);
    },
  },
  methods: {
    selectProduct(product) {
      this.$store.commit('selectShopProduct', {product, gid: this.$store.state.activeGame.gid});
      this.$store.commit('changeView', 'shop-payment-view');
    },
    toggleGift() {
      this.usernames = [];

      if (this.giftView === 1 && this.query === '') {
        this.giftView = 0;
      } else if (this.query.length > 0) {
        this.$store.dispatch('verifyUsername', this.query).then((res) => {
          this.giftError = res.error;

          if (this.giftError) {
            this.query = '';
            this.toggleGift();
          }
        });
      } else {
        this.giftView = 1;
      }
    },
    setUsername(val) {
      this.query = val;
      this.$el.querySelector('.gift').blur();
    },
    shopIcon(product) {
      let baseUrl = '/images/games/shop/';
      const {game} = this.$store.state.activeGame;
      if (process.env.NODE_ENV === 'development') {
        baseUrl = '/src/assets/images/games/shop/';
      }

      return `background-image: url(${baseUrl}${game}-${product.icon})`;
    },
    search: debounce(function() {
      const data = {query: this.query, gid: this.$store.state.activeGame.gid};
      this.$store.dispatch('searchUsernames', data).then((list) => {
        this.usernames = list;
      });
    }, 250),
  },
};
</script>
