<template>
  <div class="box notice popup medal" @mouseover="mouseOver" @mouseout="mouseOut" v-if="loaded">
    <!-- Avatar/Icon -->
    <img :src="medalUrl(data.gid, data.ref)" class="medal-img square right-margin-10">

    <!-- Content -->
    <div class="text full heading flex column justify" style="height: 60px;">
      <span class="flex row">
        <span class="user">
          Medal Earned:&nbsp;
        </span>
        <span class="name">
          {{ title }}
        </span>
      </span>

      <!-- Social Sharing -->
      <span>
        <a :href="fbLink" target="_blank" :class="['icon', 'logo', 'small', 'click', 'facebook']"></a>
        <a :href="twLink" target="_blank" :class="['icon', 'logo', 'small', 'click', 'left-margin-10', 'twitter']"></a>
      </span>
    </div>

    <!-- Close Button -->
    <span class="icon close light click right center-self right-margin-10" @click="clear"></span>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {medalUrl} from '../utils';

export default {
  name: 'medal-popup',
  props: ['data'],
  data() {
    return {
      timer: null,
      loaded: false,
    };
  },
  mounted() {
    const img = new Image(this.medalUrl(this.data.gid, this.data.ref));
    if (img.complete) {
      this.loaded = true;
      this.startTimer();
      this.$store.commit('playSound', 'medal');
    } else {
      img.addEventListener('load', () => {
        this.loaded = true;
        this.startTimer();
        this.$store.commit('playSound', 'medal');
      });
    }
  },
  methods: {
    medalUrl,
    clear() {
      this.$store.commit('remNotificationFromQueue', this.data._id);
      this.mouseOver();
    },
    mouseOver() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    mouseOut() {
      if (!this.timer) {
        this.startTimer();
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.clear();
      }, 4000);
    },
  },
  computed: Vuex.mapState({
    title() {
      return this.data.text.match(/<b>(.+?)<\/b>/g)[1].replace(/<\/?b>/g, '');
    },
    game(state) {
      return state.gameData.find(g => g.gid === this.data.gid);
    },
    fbLink() {
      const url = `https://goldfire.me/${this.game.game}`;
      const descr = encodeURIComponent(`I just earned an achievement (${this.title}) on ${this.game.title}!`);
      return `https://www.facebook.com/dialog/feed?app_id=322781824406707&display=page&link=${url}&redirect_uri=http://goldfire.me&description=${descr}`;
    },
    twLink() {
      const url = `https://goldfire.me/${this.game.game}`;
      const descr = encodeURIComponent(`I just earned an achievement (${this.title}) on ${this.game.title}! Play with me: `);
      return `https://twitter.com/share?text=${descr}&url=${url}&related=GoldFireStudios`;
    },
  }),
};
</script>
