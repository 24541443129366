<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import LauncherView from './LauncherView.vue';
import NotificationsView from './NotificationsView.vue';
import LeaderboardsView from './LeaderboardsView.vue';
import LeaderboardView from './LeaderboardView.vue';
import ProfileView from './ProfileView.vue';
import SettingsView from './SettingsView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'launcher',
  components: {
    PanelContent,
    LauncherView,
    NotificationsView,
    LeaderboardsView,
    LeaderboardView,
    ProfileView,
    SettingsView,
    EmptyView,
  },
  computed: Vuex.mapState({
    activeView: state => state.launcher.activeView,
  }),
};
</script>
