<template>
  <div
    :class="['box', 'notice', 'color', {click: notification.act || notification.gid}, notification.read ? 'inactive' : '']"
    @click="action"
  >
    <img v-if="notification.avatar" :src="avatarUrl(notification.avatar)" class="avatar">
    <img v-if="notification.type === 'medal'" :src="medalUrl($parent.gid, notification.ref)" class="avatar square">
    <span v-else-if="!notification.avatar && type" :class="['icon', 'right-margin-10', type]"></span>
    <div class="text wrap">
      <div v-html="text"></div>
      <live-time class="time right" :timestamp="notification.t"></live-time>
    </div>
  </div>
</template>

<script>
import LiveTime from './LiveTime.vue';
import {avatarUrl, medalUrl} from '../utils';
import {notification} from '../mixins';

export default {
  name: 'notification-box',
  components: {LiveTime},
  mixins: [notification],
  methods: {
    avatarUrl,
    medalUrl,
  },
};
</script>
