<template>
  <div :class="['page', 'game', {'no-scroll': !data.scroll, full: !auth, [data.game]: !connected, connected, 'left-toolbar': auth && left}]">

    <!-- Background Video -->
    <video class="game-bg-video" autoplay loop webkit-playinline muted :poster="`${baseUrl}/images/games/bg-${data.game}.jpg`" v-if="data && data.bgVideo & !connected && $store.state.app.type !== 'mobile'">
      <source :src="`${baseUrl}/images/games/bg-${data.game}.webm`" type="video/webm"></source>
      <source :src="`${baseUrl}/images/games/bg-${data.game}.mp4`" type="video/mp4"></source>
    </video>

    <div :class="['game-wrapper', {mobile: connected && !data.noMobileScale}]">
      <!-- Login Modal -->
      <transition name="fade">
        <div class="login-modal-container flex center middle" @click="toggleLogin($event, false)" v-if="login">
          <div :class="['login-modal', {tall: loginView === 'signup', guest: loginView === 'guest-signup', light: loginView === 'guest-signup', native: isApp}]">
            <component :is="loginView"></component>
          </div>
        </div>
      </transition>

      <!-- Rotate Phone Overlay -->
      <div class="rotate-phone-container" v-if="activeTab === 'empty' && connected && !hidden && !hideIframe && data.orientation === 'landscape'">
        <div class="rotate-icon"></div>
        Rotate to Play
        <div class="light small">Disable orientation lock.</div>
      </div>

      <!-- Preload data for this game -->
      <iframe
        :src="preload"
        class="preload-iframe"
        v-if="pageLoaded && preload && !connected && $store.state.app.type !== 'steam'"
      ></iframe>

      <!-- Load the game in Iframe -->
      <iframe
        :src="savedUrl || url"
        :class="['game-iframe', {landscape: data.orientation === 'landscape'}]"
        :style="style"
        frameborder="0"
        :scrolling="data.scroll ? 'yes' : 'no'"
        allow="autoplay"
        v-if="connected && !hidden && !hideIframe"
        v-focus
      ></iframe>

      <!-- Game Info -->
      <template v-else>
        <!-- Header -->
        <div class="top">
          <!-- Logo/Login -->
          <div class="topnav">
            <router-link to="/" class="icon click goldfire light" style="margin-top: -7px;"></router-link>
            <div class="rightnav listing">
              <!-- Addicting Games Logo -->
              <a href="http://www.addictinggames.com" rel="nofollow" target="_blank" title="io games" v-if="data.addictingGames && !$store.state.app.type">
                <div class="addicting-games" alt="io games"></div>
              </a>

              <!-- iogames.space button for Exocraft -->
              <a href="https://iogames.space" target="_blank" class="button small auto slim right-margin-10" v-if="data.ioGamesButton && !fromSource && !$store.state.app.type">
                More .io Games
              </a>

              <!-- Login/Logout -->
              <template v-if="!fromSource || auth">
                <button class="small white auto" @click="toggleLogin($event, true)" v-if="!auth">Login</button>
                <router-link to="/bye" class="button small outline white auto logout" v-else>Logout</router-link>
              </template>
            </div>
          </div>
        </div>

        <!-- Game Info -->
        <div class="flex column middle center text-center grow">
          <div class="game-info half">
            <h1>{{ data.title }}</h1>
            <h4>{{ data.descr }}</h4>
            <button class="auto color" @click="playGame" v-if="!loading">Play {{ data.title }}</button>
            <div class="spinner light auto-center" v-else></div>
          </div>
        </div>

        <!-- Screenshots Bar -->
        <div class="game-screenshots center middle bottom-margin-30">
          <span :class="['icon', 'back', 'light', 'click', {disabled: page <= 0}]" @click="incPage(-1)"></span>
          <div v-for="screen in screenshots" class="game-screenshot flex middle center" @click="viewFull(screen)">
            <img :src="thumb(screen)" :srcset="thumbset(screen)" class="game-screenshot-thumb">
            <span class="icon play-video" v-if="screen === '000' && data.video"></span>
          </div>
          <span :class="['icon', 'forward', 'light', 'click', {disabled: disableRightArrow}]" @click="incPage(1)"></span>
        </div>

        <!-- Full Screenshot View -->
        <transition name="fade">
          <div class="screenshot-modal-wrapper flex middle center" v-if="typeof full === 'number'">
            <div class="screenshot-modal flex middle center">
              <span :class="['icon', 'back', 'light', 'click', {disabled: full <= 0}]" @click="incFull(-1)"></span>
              <div>
                <iframe
                  :src="data.video"
                  frameborder="0"
                  allowfullscreen="true"
                  v-if="full === 0 && data.video"
                ></iframe>
                <img :src="fullUrl" v-else>
                <div class="close-button flex center middle" @click="closeFull">
                  <span class="icon close"></span>
                </div>
              </div>
              <span :class="['icon', 'forward', 'light', 'click', {disabled: disableFullRightArrow}]" @click="incFull(1)"></span>
            </div>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import Raven from 'raven-js';
import Login from './Login.vue';
import Signup from './Signup.vue';
import GuestSignup from '../GuestSignup.vue';
import GuestComplete from '../GuestComplete.vue';
import MultiFactorAuth from './MultiFactorAuth.vue';
import {readCookie, setCookie} from '../../utils';

// Include visibility API.
let Visibility;
if (typeof window !== 'undefined') {
  Visibility = require('visibilityjs');
}

if (typeof window !== 'undefined') {
  require('add-to-homescreen');
  require('../../../node_modules/smartbanner.js/dist/smartbanner.min');
}

export default {
  name: 'game',
  components: {
    Login,
    Signup,
    GuestSignup,
    GuestComplete,
    MultiFactorAuth,
  },
  data() {
    return {
      baseUrl: process.env.NODE_ENV === 'development' ? '/src/assets' : '',
      limit: 0,
      page: 0,
      full: null,
      fullUrl: '',
      loading: false,
      connectOnLogin: false,
      savedUrl: null,
      hidden: false,
      style: {
        width: '0px',
        height: '0px',
      },
      pageLoaded: false,
    };
  },
  meta() {
    let meta = [
      {
        name: 'apple-mobile-web-app-title',
        content: this.data.title,
      },
      {
        name: 'application-name',
        content: this.data.title,
      },
    ];

    // Add og:image tag.
    if (this.data.ogImage) {
      meta.push({
        property: 'og:image',
        content: `https://goldfire.me${this.baseUrl}/images/games/og-${this.data.game}.jpg`,
      });
      meta.push({
        property: 'og:title',
        content: this.data.title,
      });
      meta.push({
        property: 'og:description',
        content: this.data.descr,
      });
      meta.push({
        property: 'og:site_name',
        content: 'GoldFire',
      });
      meta.push({
        property: 'og:type',
        content: 'website',
      });
    }

    // Show the app install banner.
    if (this.data.appStorePrompt) {
      meta = meta.concat([
        {
          name: 'smartbanner:title',
          content: this.data.title,
        },
        {
          name: 'smartbanner:author',
          content: 'GoldFire Studios',
        },
        {
          name: 'smartbanner:price',
          content: 'FREE',
        },
        {
          name: 'smartbanner:price-suffix-apple',
          content: ' - On the App Store',
        },
        {
          name: 'smartbanner:price-suffix-google',
          content: ' - In Google Play',
        },
        {
          name: 'smartbanner:icon-apple',
          content: `/images/games/icons/${this.data.game}-ios-app.png`,
        },
        {
          name: 'smartbanner:icon-google',
          content: `/images/games/icons/${this.data.game}-android-app.png`,
        },
        {
          name: 'smartbanner:button',
          content: 'VIEW',
        },
        {
          name: 'smartbanner:button-url-apple',
          content: this.data.appStorePrompt.apple,
        },
        {
          name: 'smartbanner:button-url-google',
          content: this.data.appStorePrompt.google,
        },
        {
          name: 'smartbanner:enabled-platforms',
          content: 'android,ios',
        },
        {
          name: 'smartbanner:exclude-user-agent-regex',
          content: '^.*-Mobile$',
        },
      ]);
    } else {
      meta.push({
        name: 'smartbanner:enabled-platforms',
        content: 'none',
      });
    }

    return {
      title: this.data.title || '',
      meta,
      link: [
        {
          rel: 'apple-touch-icon',
          href: `/images/games/icons/${this.data.game}-ios.png`,
        },
        {
          rel: 'icon',
          size: '196x196',
          href: `/images/games/icons/${this.data.game}-android.png`,
        },
      ],
    };
  },
  created() {
    // If this route doesn't exist, redirect to the homepage.
    if (!this.data || typeof this.data.gid === 'undefined') {
      this.$router.push('/');
    }

    // If this came from a source, track that with a cookie.
    if (typeof window !== 'undefined' && this.$store.state.route.query.src) {
      setCookie('src', this.$store.state.route.query.src, 86400000);
      this.$router.push(this.$store.state.route.path);
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.setLimit);
      window.addEventListener('orientationchange', this.setLimit);
      window.addEventListener('keyup', this.arrowKeys);
      window.addEventListener('message', this.message);
    }

    this.setLimit();

    // Hide the scrolling.
    document.body.className = 'overflow-hide';

    // If they came from social, make note of that and remove query string.
    const fromSocial = this.$store.state.route.query.rel === 'social';
    if (fromSocial) {
      this.$router.push(this.$store.state.route.path);
    }

    // If they logged in through social, connect them.
    if (this.auth && !this.connected && fromSocial) {
      this.$router.push(this.$store.state.route.path);
      this.playGame();
    }

    // Update the active time on this game.
    this.updateActive();

    // Update what app this game is running in.
    this.updateAppType();

    // Show the login/signup window.
    if (this.$store.state.route.query.login) {
      if (!this.connected) {
        this.connectOnLogin = true;
        this.toggleLogin(null, true, this.data.guests && !this.$store.state.route.query.noguest ? 'play' : undefined);
      }
      this.$router.replace(this.$store.state.route.path);
    }

    // If we are on mobile and the page gets backgrounded, kill the game to save power.
    if (Visibility && this.isMobile) {
      Visibility.change((e, state) => {
        this.hidden = (state === 'hidden');
      });
    }

    // Setup event to mark the page as loaded.
    if (!this.$store.state.route.meta.nav && typeof window !== 'undefined') {
      this.$nextTick(() => {
        const img = this.$el.querySelector('.game-screenshot-thumb');
        if (img.complete) {
          img.onload = () => {
            this.pageLoaded = true;
          };
        } else {
          this.pageLoaded = true;
        }
      });
    } else {
      this.pageLoaded = true;
    }

    // Show the NPS prompt from UserVoice.
    setTimeout(() => {
      if (this.auth && this.connected) {
        UserVoice.push(['identify', {
          email: this.$store.state.user.email,
          name: this.$store.state.user.user,
          id: this.$store.state.user.uid,
          account: {
            id: this.$store.state.user.uid,
            name: this.$store.state.user.user,
            ltv: this.$store.state.user.ltv,
          },
        }]);
        UserVoice.push(['autoprompt', {}]);
      }
    }, 60000);
  },
  methods: {
    updateIframeSize() {
      const width = this.$el.querySelector('.game-wrapper').offsetWidth;
      const height = this.$el.querySelector('.game-wrapper').offsetHeight;
      this.style.width = `${width}px`;
      this.style.height = `${height}px`;
    },
    setLimit() {
      this.limit = Math.floor((this.$el.offsetWidth - 78) / 234);

      // Update the size of the iframe.
      this.updateIframeSize();
    },
    incPage(val) {
      this.page += val;
    },
    thumb(screen) {
      return `${this.baseUrl}/images/games/screenshots/${this.data.game}/thumbs/${screen}.jpg?v=4`;
    },
    thumbset(screen) {
      return `${this.baseUrl}/images/games/screenshots/${this.data.game}/thumbs/${screen}@2x.jpg?v=4 2x`;
    },
    viewFull(screen) {
      this.full = this.screens.indexOf(screen);
      this.fullUrl = `${this.baseUrl}/images/games/screenshots/${this.data.game}/${screen}.jpg?v=4`;
    },
    incFull(val) {
      this.full += val;
      this.viewFull(this.screens[this.full]);
    },
    closeFull() {
      this.full = null;
      this.fullUrl = '';
    },
    arrowKeys(event) {
      if (typeof this.full === 'number') {
        if (event.which === 39 && !this.disableFullRightArrow) { // right
          this.incFull(1);
        } else if (event.which === 37 && this.full > 0) { // left
          this.incFull(-1);
        }
      }
    },
    toggleLogin(event, val, btn) {
      const isBg = (event && this.login && this.$el.querySelector('.login-modal-container') === event.target);
      if (!this.login || !event || isBg) {
        // Use the correct login display.
        if (this.data.guests) {
          if (btn === 'play' && !readCookie('lastLogin')) {
            this.$store.commit('setAuthData', {activeView: 'guest-signup'});
          } else {
            this.$store.commit('setAuthData', {activeView: 'login'});
          }
        }

        // Show the complete signup display.
        if (btn === 'complete') {
          this.$store.commit('setAuthData', {activeView: 'guest-complete'});
        }

        // Update the login display.
        this.$store.commit('showLogin', val);
      }
    },
    playGame() {
      if (this.auth && !this.connected) { // Connect them.
        if (!this.data.guests && this.guest) {
          this.toggleLogin(null, true, 'complete');
        } else {
          this.loading = true;
          this.$store.dispatch('connectGame', this.data.gid).then(() => {
            this.loading = false;
          });
        }
      } else if (!this.auth) { // Login/Signup
        this.connectOnLogin = true;
        this.toggleLogin(null, true, 'play');
      } else {
        this.connected = true;
      }
    },
    updateAppType() {
      // Check if we are in a mobile app.
      if (window.navigator.userAgent.match(/CasinoRPG-Mobile|Exocraft-Mobile/)) {
        this.$store.commit('setAppType', 'mobile');
      }

      // Check for Windows Store.
      if (typeof window.Windows === 'object' || window.navigator.userAgent.includes('CasinoRPG-Windows')) {
        this.$store.commit('setAppType', 'windows');
      }

      // Check for Steam.
      if (window.navigator.appVersion.includes('Electron') && !window.navigator.userAgent.includes('CasinoRPG-Windows')) {
        this.$store.commit('setAppType', 'steam');
        this.updateSteam();
      }
    },
    updateActive() {
      if (this.auth && this.connected) {
        this.$store.dispatch('updateActiveGame', {
          gid: this.data.gid,
        });

        // If on mobile, show the prompt to install to homescreen.
        if (typeof addToHomescreen !== 'undefined' && typeof window.Windows !== 'object' && !this.data.appStorePrompt) {
          // window.addToHomescreen.removeSession();
          const mandatory = window.screen.height < 500;
          window.addToHomescreen({
            debug: false,
            icon: false,
            modal: true,
            startDelay: mandatory ? 0 : 3,
            lifespan: 0,
            displayPace: mandatory ? 60 : 4320,
            maxDisplayCount: 0,
            mandatory,
            message: {
              ios: 'To play in full-screen: tap %icon and then <strong>Add to Home Screen</strong>.',
              android: `To add ${this.data.title} to the home screen, open the browser option menu and tap on <strong>Add to homescreen</strong>. <small>The menu can be accessed by pressing the menu hardware button if your device has one, or by tapping the top right menu icon %icon.</small>`,
            },
          });
        }

        // Redirect through login route if needed.
        if (this.data.login) {
          if (!this.$store.state.route.query.redirect) {
            const uid = this.$store.state.user.uid;
            const sess = encodeURIComponent(readCookie('sid'));
            // this.$el.querySelector('.game-iframe').contentWindow.location = `${this.data.login}?uid=${uid}&sess=${sess}`;
            window.location = `${this.data.login}?uid=${uid}&sess=${sess}`;
          } else {
            // If there is also a URL in the query, set the iframe to that.
            const queryUrl = this.$store.state.route.query.url;
            if (queryUrl) {
              this.savedUrl = queryUrl;
            }

            // Remove the query string.
            this.$router.replace(this.$store.state.route.path);
          }
        }

        // Update the iframe size.
        this.$nextTick(() => {
          // Lock the orientation in the mobile app.
          if (this.$store.state.app.type === 'mobile') {
            window.parent.postMessage({
              action: 'lockOrientation',
            }, '*');
          }

          // If this is in Steam, update the steamid.
          if (this.$store.state.app.type === 'steam') {
            this.updateSteam();
          }

          this.updateIframeSize();
        });
      }
    },
    updateSteam() {
      // Listen for data coming from the Steam app.
      window.steam.ipc.on('steam', (event, data) => {
        if (data.action === 'steamMetaData') {
          this.$store.commit('setSteamData', {
            id: data.steamId,
            lang: data.gameLang,
            appId: data.appId,
          });

          this.$store.dispatch('updateSteamId', {
            steamid: data.steamId,
          });
        }
      });

      // Send request to Steam to get the metadata for the user.
      window.steam.ipc.sendToHost('steamMetaData');
    },

    /**
     * Handle communication between GoldFire and the game.
     */
    message(event) {
      // Don't continue if there is no iframe loaded.
      if (!this.url) {
        return;
      }

      // Determine the origin of the iframe.
      const iframe = this.$el.querySelector('.game-iframe');
      const content = iframe ? iframe.contentWindow : '';
      const iframeOrigin = iframe ? (iframe.src.match(/^.+:\/\/[^/]+/) || [])[0] : '';
      const origin = (this.url.match(/^.+:\/\/[^/]+/) || [])[0];
      const appType = this.$store.state.app.type;

      // Make sure this message is coming from the game.
      const notOwnOrigin = event.origin !== 'https://beta.goldfire.me' && event.origin !== 'https://goldfire.me';
      if (event.origin !== iframeOrigin && notOwnOrigin) {
        return;
      }

      // Safely parse the data.
      let {data} = event;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {}
      }

      // Handle session requests so the game can run auth.
      if (data.action === 'getSession') {
        content.postMessage({
          action: 'session',
          uid: this.$store.state.user.uid,
          sid: readCookie('sid'),
          guest: this.guest,
          cmp: readCookie('game-cmp'),
          cmpid: readCookie('game-cmpid'),
        }, origin);
      }

      // Enable logging out from the game.
      if (data.action === 'logout') {
        this.$router.push('/bye');
      }

      // Set the app type being used.
      if (data.action === 'setAppType') {
        this.$store.commit('setAppType', data.app);

        // Lock the orientation in the mobile app.
        if (this.auth && data.app === 'mobile') {
          window.parent.postMessage({
            action: 'lockOrientation',
          }, '*');
        }
      }

      // Get the app type.
      if (data.action === 'getAppType') {
        content.postMessage({
          action: 'getAppType',
          type: this.$store.state.app.type,
        }, origin);
      }

      // Get the app cache.
      if (data.action === 'getAppCache') {
        const isSteam = this.$store.state.app.type === 'steam';

        // Receive the cache data from the parent.
        const recCacheData = (appEvent) => {
          const appData = typeof appEvent.data === 'object' ? appEvent.data : {};

          if (appData.action === 'appData') {
            window.removeEventListener('message', recCacheData, false);

            // Send the data bacck to the game.
            content.postMessage({
              action: 'getAppCache',
              files: appData.files,
            }, origin);
          }
        };

        // Ask the app for the data and receive it.
        if (!isSteam) {
          window.addEventListener('message', recCacheData, false);
          window.parent.postMessage({
            action: 'getAppCache',
          }, '*');
        } else {
          window.loadSteamAppCache = (appCache) => {
            recCacheData({
              data: {
                action: 'appData',
                files: appCache,
              },
            });
          };
          window.steam.ipc.sendToHost('getAppCache');
        }
      }

      // If the game can't set cookies, redirect to unlock it.
      if (data.action === 'cookiesBlocked' && typeof window !== 'undefined' && window.location.host.match('goldfire.me')) {
        window.location = `${this.$store.state.activeGame.auth}?url=${window.location}`;
      }

      // Open the product checkout view.
      if (data.action === 'buyProduct') {
        this.$store.dispatch('purchaseProduct', {gid: this.data.gid, product: data.product});
      }

      // Open a plyaer profile.
      if (data.action === 'viewProfile') {
        this.$store.dispatch('viewProfile', {uid: data.uid});
      }

      // Send a chat message in the background.
      if (data.action === 'sendChatMessage') {
        this.$store.dispatch('sendBackgroundChatMessage', {uid: data.uid, message: data.message});
      }

      // View the shop view.
      if (data.action === 'viewShop') {
        this.$store.commit('toggleTab', 'shop');
      }

      // View the shop manage view.
      if (data.action === 'viewShopManage') {
        this.$store.commit('toggleTabView', {tab: 'shop', view: 'shop-manage-payments-view'});
      }

      // View the settings view.
      if (data.action === 'viewSettings') {
        this.$store.commit('toggleTabView', {tab: 'launcher', view: 'settings-view'});
      }

      // View the support view.
      if (data.action === 'viewSupport') {
        this.$store.commit('toggleTab', 'info');
        setTimeout(() => {
          UserVoice.showPrompt('contact');
        }, 750);
      }

      // View the forums panel.
      if (data.action === 'viewForums') {
        this.$store.commit('toggleTab', 'forums');
      }

      // View the forums panel.
      if (data.action === 'viewForumCategory') {
        this.$store.commit('toggleTab', 'forums');
        this.$store.dispatch('viewThreads', parseInt(data.category, 10));
      }

      // View the forums panel.
      if (data.action === 'viewForumThread') {
        this.$store.commit('toggleTab', 'forums');
        this.$store.dispatch('viewForumThread', {_id: data.tid});
      }

      // Update the way check.
      if (data.action === 'awayCheck' && typeof window !== 'undefined') {
        window.updateLastEvent();
      }

      // Show the guest complete display.
      if (data.action === 'showGuestPrompt' && this.guest) {
        this.toggleLogin(null, true, 'complete');
      }

      // Receive app product data.
      if (data.action === 'getAppProducts') {
        content.postMessage({
          action: 'getAppProducts',
          products: this.$store.state.activeGame.appShop,
        }, origin);
      }

      // Receive app product data.
      if (data.action === 'appProductData') {
        this.$store.commit('setAppShopProducts', {
          products: data.products,
          gfProducts: data.gfProducts,
        });
      }

      // Receive notice from app that it has resized.
      if (data.action === 'updateAppSize') {
        this.updateIframeSize();
      }

      // Show review prompt in mobile app.
      if (data.action === 'showReviewPrompt') {
        window.parent.postMessage({
          action: 'showReviewPrompt',
        }, '*');

        // Notify the Windows Store app.
        const hasExternal = typeof window.external !== 'undefined' && 'notify' in window.external;
        if (hasExternal && this.$store.state.app.type === 'windows') {
          window.external.notify('reviewPrompt');
        }
      }

      // Set the data for the updates display.
      if (data.action === 'setUpdates') {
        this.$store.commit('setUpdates', {
          show: true,
          data: data.updates,
          all: typeof data.all === 'undefined' ? true : data.all,
        });
      }

      // Switch to a different game.
      if (data.action === 'switchGame') {
        // TODO: This logic is duplicated in GameDisplay.vue
        const {game} = this.$store.state.gameData[data.game];

        if (appType === 'mobile') {
          // If we are in the mobile app, open the game in the browser.
          window.parent.postMessage({
            action: 'openInBrowser',
            url: `https://goldfire.me/${game}`,
          }, '*');
        } else if (appType === 'steam') {
          // TODO: this find isn't great.
          const steamUrl = this.$store.state.gameData[data.game].apps.find(x => x.icon === 'steam');

          // If we are in steam, we open the game in the browser.
          if (steamUrl) {
            window.open(steamUrl.url);
          } else {
            window.open(`https://goldfire.me/${game}`);
          }
        } else {
          this.$router.push(`/${game}`);
        }
      }

      // Force reload the game by destroying and re-creating the iframe.
      if (data.action === 'reloadGame') {
        this.$store.commit('setHideIframe', true);
        this.$nextTick(() => {
          this.$store.commit('setHideIframe', false);
        });
      }

      // Allow the game to open a URL in the in-app browser.
      if (data.action === 'openInAppLink' && data.url && appType === 'mobile') {
        window.parent.postMessage({
          action: 'openLink',
          url: data.url,
        }, '*');
      }

      // Allow opening a mobile link in the system browser.
      if (data.action === 'openLinkInBrowser' && data.url && appType === 'mobile') {
        window.parent.postMessage({
          action: 'openInBrowser',
          url: data.url,
        }, '*');
      }

      // Handle mobile payments.
      if (data.action === 'completeMobilePurchase') {
        const product = this.$store.state.shop.cart[0] || {};

        // Handle a failed purchase.
        if (data.failed) {
          // Temporary debugging logging.
          Raven.captureMessage('Mobile Payment Debug Message', {
            extra: {
              data,
              err: data.err,
              user: this.$store.state.user.user,
              product,
            },
          });

          this.$store.commit('toggleTab', 'shop');
        } else {
          this.$store.dispatch('processMobilePayment', {
            product: product.id,
            gid: product.gid,
            gift: this.$store.state.shop.gift,
            data: data.data,
          }).then((res) => {
            if (res.error) {
              this.$store.commit('toggleTab', 'shop');
            } else {
              this.$store.commit('changeView', 'shop-success-view');
            }
          });
        }
      }

      // Handle updating the guest/verify data on mobile.
      if (data.action === 'mobileLogin') {
        if (this.guest) {
          this.$store.dispatch('checkGuest', {user: this.user}).then(({guest, verify}) => {
            this.$store.commit('setSettingsData', {verify});
            this.$store.commit('setUserData', {guest: guest || 0});
          });
        } else {
          // Set the session cookie.
          const expires = new Date(Date.now() + 31536000000).toGMTString();
          document.cookie = `sid=${data.sid}; expires=${expires}; path=/`;

          // Run the authentication across the server.
          this.$store.dispatch('reAuth');
        }
      }

      // Handle signup/login with Apple.
      if (data.action === 'appleLogin') {
        // Setup the login route cookie.
        const expires = new Date(Date.now() + 31536000000).toGMTString();
        document.cookie = `loginRoute=${this.$store.state.route.path}; expires=${expires}; path=/`;

        // Send the apple login request.
        this.$store.dispatch('appleLogin', data);
      }

      // Handle Google Tag Manager event.
      if (data.action === 'trackEvent' && typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: data.event,
          orderId: this.$store.state.user.uid,
          value: data.value || 0,
        });
      }

      // Handle tracking the campaign.
      if (data.action === 'trackCmp') {
        const expires = new Date(Date.now() + 2592000000).toGMTString();
        document.cookie = `game-cmp=${data.cmp}; expires=${expires}; path=/`;
      }
    },
  },
  watch: {
    auth(val) {
      if (this.connectOnLogin && val && !this.connected) {
        this.connectOnLogin = false;
        this.playGame();
      }

      if (val) {
        this.updateActive();
        this.$store.commit('showLogin', false);
      }
    },
    connected(val) {
      if (val) {
        this.updateActive();
      }
    },
    data() {
      this.updateActive();
    },
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    guest: state => state.user.guest,
    user: state => state.user.user,
    login: state => state.auth.showLogin,
    data: state => state.activeGame,
    left: state => state.toolbar.left,
    loginView: state => state.auth.activeView,
    isMobile: state => state.app.type === 'mobile',
    hideIframe: state => state.launcher.hideIframe,
    activeTab: state => state.toolbar.activeTab,
    isApp(state) {
      return state.app.type === 'mobile'
        || state.app.type === 'steam'
        || state.app.type === 'windows';
    },
    connected(state) {
      return state.user.games.indexOf(this.data.gid) >= 0;
    },
    screens() {
      return this.data.screens || [];
    },
    screenshots() {
      return this.screens.slice(this.page, this.limit + this.page);
    },
    disableRightArrow() {
      return (this.page + this.limit >= this.screens.length);
    },
    disableFullRightArrow() {
      return (this.full + 1 >= this.screens.length);
    },
    url() {
      if (!this.data || !this.data.url) {
        return '';
      } else if (process.env.NODE_ENV === 'development') {
        return this.data.url.dev;
      } else if (process.env.TEST_ENV === 'true' || (typeof window !== 'undefined' && window.location.host.match('beta.goldfire.me'))) {
        return this.data.url.test;
      }

      return this.data.url.prod;
    },
    preload() {
      if (!this.data || !this.data.preload || process.env.NODE_ENV === 'development') {
        return '';
      } else if (process.env.TEST_ENV === 'true' || (typeof window !== 'undefined' && window.location.host.match('beta.goldfire.me'))) {
        return this.data.preload.test;
      }

      return this.data.preload.prod;
    },
    fromSource() {
      return this.$store.state.route.query.src || readCookie('src');
    },
  }),
  beforeDestroy() {
    if (window) {
      window.removeEventListener('resize', this.setLimit);
      window.removeEventListener('orientationchange', this.setLimit);
      window.removeEventListener('keyup', this.arrowKeys);
      window.removeEventListener('message', this.message);
    }

    // Re-enable the scrolling.
    document.body.className = '';

    // Close the info and leaderboard panels.
    if (this.$store.state.toolbar.activeTab.match(/info|leaderboard/)) {
      this.$store.commit('toggleTab', this.$store.state.toolbar.activeTab);
    }
  },
  beforeRouteEnter(to, from, next) {
    to.meta.nav = true;
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/bye') {
      // Send message to server to log them out.
      this.$store.dispatch('logout');
    } else {
      if (!to.params || !to.params.game) {
        this.$store.commit('setActiveGame');
      }

      if (this.$store.state.auth.showLogin) {
        this.$store.commit('showLogin', false);
      }

      next();
    }
  },
};
</script>
