<template>
  <login-wrapper>
    <h3 v-if="!isSignup">Login/Signup</h3>
    <h3 v-else>New Account</h3>
    <div class="top-margin-10 bottom-margin-30 full">
      <input
        type="text"
        :class="{underline: true, error: badEmail || isEmailTaken}"
        placeholder="E-mail Address"
        v-model="email"
        @keyup.enter="login"
        @change="onChange"
        v-focus="focusEmail"
      >
      <div class="red input-error" v-if="isEmailTaken">This e-mail is already in use.</div>
      <div class="red input-error" v-else-if="badEmail">Please enter a valid e-mail.</div>
      <div class="warning input-error" v-else-if="mailcheck" v-html="mailcheck" @click="fixEmail"></div>
      <input
        type="password"
        :class="['underline', 'top-margin-15', {error: error || passLen}]"
        placeholder="Password"
        v-model="pass"
        @keyup.enter="login"
        v-focus="!focusEmail"
      >
      <div class="red input-error" v-if="error && !isSignup">Wrong e-mail or password.</div>
      <div class="red input-error" v-if="passLen">Password should be 6+ characters.</div>
    </div>

    <!-- Login/Continue Signup Button -->
    <button-loader
      value="Continue"
      :styles="{'color': true, 'bottom-margin-30': true}"
      :loading="loading"
      :click="login"
    ></button-loader>

    <!-- Forgot Pass/Login Link -->
    <router-link to="/login/reset" class="link" v-if="!isSignup">Forgot your password?</router-link>
    <router-link to="/login" class="link" v-else>Already have an account?</router-link>
  </login-wrapper>
</template>

<script>
import Vuex from 'vuex';
import is from 'is_js';
import mailcheck from 'mailcheck';
import LoginWrapper from '../LoginWrapper.vue';
import ButtonLoader from '../ButtonLoader.vue';
import {readCookie} from '../../utils';

export default {
  name: 'login',
  components: {LoginWrapper, ButtonLoader},
  props: ['signup'],
  data() {
    let email = '';
    if (typeof window !== 'undefined' && !this.$route.meta.signup) {
      email = readCookie('lastLogin') || '';
    }

    return {
      email,
      emailFixed: '',
      pass: '',
      loading: false,
      error: false,
      emailTaken: false,
      badEmail: false,
      passLen: false,
      mailcheck: null,
    };
  },
  mounted() {
    // Listen for the response from the app.
    if (this.$store.state.app.type === 'mobile') {
      window.addEventListener('message', this.appLoginMessage);
    }

    // Init the e-mail auth data.
    if (this.email !== '') {
      this.$store.commit('setAuthData', {email: this.email});
    }
  },
  watch: {
    email() {
      this.$store.commit('setAuthData', {email: this.email});
    },
    pass() {
      this.$store.commit('setAuthData', {pass: this.pass});
    },
  },
  meta() {
    return {
      title: this.$route.meta.signup ? 'Signup' : 'Login',
    };
  },
  computed: Vuex.mapState({
    isSignup() {
      return this.$route.meta.signup || this.signup;
    },
    focusEmail() {
      return this.email === '';
    },
    isEmailTaken(state) {
      return this.emailTaken || state.auth.emailTaken;
    },
  }),
  methods: {
    login() {
      const isModal = (this.$el.offsetParent.className.match(/login-modal-container/));

      // Run the same static checks as on the server.
      if (this.isSignup) {
        this.loading = true;
        this.badEmail = false;
        this.passLen = false;

        if (!is.email(this.email)) {
          this.badEmail = true;
        }
        if (!this.pass || this.pass === '' || this.pass.length < 6) {
          this.passLen = true;
        }

        // Check with the server if this e-mail is taken.
        this.$store.dispatch('checkEmail', {
          email: this.email.toLowerCase(),
        }).then((err) => {
          this.loading = false;
          this.emailTaken = err;

          if (!this.emailTaken && !this.badEmail && !this.passLen) {
            if (isModal) {
              this.$store.commit('setAuthData', {activeView: 'signup'});
            } else {
              this.$router.push('/signup/2');
            }
          }
        });
      } else if (this.email === '' || this.pass === '') {
        this.error = true;
      } else {
        // Send the login request to the server.
        this.loading = true;
        this.$store.dispatch('login', {
          email: this.email.toLowerCase(),
          pass: this.pass,
          secret: readCookie('auth'),
        }).then((data) => {
          this.loading = false;
          this.error = data.err;
          if (!data.err) {
            // If they are signing up, send them to the signup flow.
            if (data.signup) {
              if (isModal) {
                this.$store.commit('setAuthData', {activeView: 'signup'});
              } else {
                this.$router.push('/signup/2');
              }
            } else if (data.multiFactor) {
              if (isModal) {
                this.$store.commit('setAuthData', {activeView: 'multi-factor-auth'});
              } else {
                this.$router.push('/login/auth');
              }
            } else if (typeof this.$parent.toggleLogin === 'function' && isModal) {
              this.$parent.toggleLogin();
            } else {
              this.$router.push('/');
            }
          }
        });
      }
    },
    appLoginMessage(event) {
      let {data} = event;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {}
      }

      if (data.action !== 'mobileLogin') {
        return;
      }

      // Set the session cookie.
      const expires = new Date(Date.now() + 31536000000).toGMTString();
      document.cookie = `sid=${data.sid}; expires=${expires}; path=/`;

      // Run the authentication across the server.
      this.$store.dispatch('reAuth');

      // Cancel the event listener.
      window.removeEventListener('message', this.appLoginMessage);
    },
    onChange() {
      if (!this.isSignup) {
        return;
      }

      this.mailcheck = null;
      mailcheck.run({
        email: this.email,
        suggested: (suggestion) => {
          this.mailcheck = `Did you mean <b>${suggestion.full}</b>?`;
          this.emailFixed = suggestion.full;
        },
      });
    },
    fixEmail() {
      this.email = this.emailFixed;
      this.mailcheck = null;
      this.$el.querySelector('input[type="password"]').focus();
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('message', this.appLoginMessage);
    }
  },
};
</script>
