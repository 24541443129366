<template>
  <div class="content">
    <div
      v-for="message in messages"
      :class="['inf', 'box', 'click', 'notice', 'color', {inactive: message.read}]"
      :id="`id-${message._id}`"
      @click="viewMessage(message)">
      
      <!-- Avatar -->
      <template v-if="message.players.length > 1">
        <img :src="avatarUrl(message.players[0].avatar)" class="avatar small multiple-avatar-1">
        <img :src="avatarUrl(message.players[1].avatar)" class="avatar small multiple-avatar-2">
      </template>
      <img v-else :src="avatarUrl(message.players[0].avatar)" class="avatar">

      <!-- Usernames/Message -->
      <div class="text full truncate">
        <div class="user">
          <span class="username truncate max-three-quarter">{{ message.usernames}}</span>
          <status :uid="message.players[0].uid" v-if="message.players.length === 1"></status>
        </div>
        <live-time class="time right" :timestamp="message.t"></live-time>
        <span v-html="message.text"></span>
      </div>
    </div>
    <div v-if="messages.length === 0 && !isLoading" class="box center">
      You don't have any messages.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import Status from './Status.vue';
import {avatarUrl, shortUsernames, convertEmoji} from '../utils';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'chat-list-view',
  components: {LiveTime, Status},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'MESSAGES',
      buttons: [{
        label: 'Friends',
        view: {mutation: 'setFriendListType', data: {type: 'friend'}},
      }],
    });

    this.load(); // load first page of data
  },
  methods: {
    avatarUrl,
    viewMessage(message) {
      this.$store.commit('resetChatThread');
      this.$store.commit('viewChatMessage', {
        id: message._id,
        user: shortUsernames(message.players),
        uid: message.players[0].uid,
        name: message.name,
      });

      this.$store.commit('changeView', 'chat-message-view');
    },
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        minRowHeight: 60,
        actionName: 'loadChatThreadList',
        mutationName: 'setChatThreadList',
        items: state.chat.messages,
        keys: ['t'],
      };
    },
    messages(state) {
      return state.chat.messages
        .map((message) => {
          const text = convertEmoji(message.text);
          const usernames = message.name ? message.name : shortUsernames(message.players);
          const status = message.players.length > 1 ? '' : message.players[0].t;

          return Object.assign({}, message, {text, usernames, status});
        });
    },
    isLoading: state => state.panel.loading,
  }),
  beforeDestroy() {
    // Loop through all messages and unsubscribe.
    const players = this.$store.state.chat.messages
      .map(chat => chat.players)
      .reduce((acc, cur) => [...acc, ...cur], []);

    this.$store.dispatch('bulkUnsub', players.map(player => `playerStatus:${player.uid}`));

    // Unsubscribe from chat list updates.
    this.$store.dispatch('unsub', `updateChatList:${this.$store.state.user.uid}`);

    // Clear out the chat list.
    this.$store.commit('setChatThreadList', {items: [], concat: false});
  },
};
</script>
