<template>
  <transition :name="transition" >
    <div :class="['panel', {wide: wide, 'left-toolbar': left}]" v-show="activeTab !== 'empty'">
      <transition :name="transition === 'slide' ? 'fade' : ''">
        <component :is="activeTab"></component>
      </transition>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';
import Empty from './Empty.vue';
import Launcher from './Launcher.vue';
import Chat from './Chat.vue';
import Forums from './Forums.vue';
import Leaderboards from './Leaderboards.vue';
import Shop from './Shop.vue';
import Info from './Info.vue';

export default {
  name: 'panel',
  components: {Empty, Launcher, Chat, Forums, Leaderboards, Shop, Info},
  computed: Vuex.mapState({
    activeTab: state => state.toolbar.activeTab,
    transition: state => state.toolbar.transition,
    wide: state => state.panel.wide,
    launcherView: state => state.launcher.activeView,
    chatView: state => state.chat.activeView,
    forumsView: state => state.forums.activeView,
    leaderboardsView: state => state.leaderboards.activeView,
    shopView: state => state.shop.activeView,
    left: state => state.toolbar.left,
  }),
  methods: {
    cancelRequests(tab, view) {
      this.$store.dispatch('cancelSocketRequests', {tab, view});
    },
  },
  watch: {
    launcherView(val, oldVal) {
      this.$store.commit('scrollToTop');
      this.cancelRequests('launcher', oldVal);
    },
    chatView(val, oldVal) {
      this.$store.commit('scrollToTop');
      this.cancelRequests('chat', oldVal);
    },
    forumsView(val, oldVal) {
      this.$store.commit('scrollToTop');
      this.cancelRequests('forums', oldVal);
    },
    leaderboardsView(val, oldVal) {
      this.$store.commit('scrollToTop');
      this.cancelRequests('leaderboards', oldVal);
    },
    shopView(val, oldVal) {
      this.$store.commit('scrollToTop');
      this.cancelRequests('shop', oldVal);
    },
    activeTab(val) {
      // Update Google Analytics.
      if (typeof window !== 'undefined' && typeof ga === 'function' && val !== 'empty') {
        ga('send', 'event', 'Toolbar', 'open', val);
      }

      // Focus the game again.
      if (val === 'empty' && typeof document !== 'undefined') {
        if (document.body.querySelector('.game-iframe')) {
          document.body.querySelector('.game-iframe').focus();
        }
      }

      // Update the scroll bars.
      if (val !== 'empty') {
        this.$nextTick(() => {
          this.$store.commit('updateScrollBars');
        });
      }
    },
  },
};
</script>
