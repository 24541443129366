<template>
  <div :class="['toolbar', left ? 'toolbar-left' : 'toolbar-right']">
    <!-- Top Tabs -->
    <toolbar-icon v-for="tab in topTabs" :type="tab" :key="tab"></toolbar-icon>

    <!-- This pushed the icons below it to the bottom -->
    <div class="stretch"></div>

    <!-- Bottom Tabs -->
    <toolbar-icon v-for="tab in bottomTabs" :type="tab" :key="tab"></toolbar-icon>

    <!-- Shadow overlay on the toolbar -->
    <div class="shadow"></div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ToolbarIcon from './ToolbarIcon.vue';

export default {
  name: 'toolbar',
  components: {ToolbarIcon},
  computed: Vuex.mapState({
    topTabs: state => state.toolbar.topTabs,
    bottomTabs: state => state.toolbar.bottomTabs,
    left: state => state.toolbar.left,
  }),
  beforeMount() {
    // Pre-load certain data when the toolbar is mounted.
    this.$store.dispatch('initSettings');
    this.$store.dispatch('loadLauncher');
  },
};
</script>
