<template>
  <div class="container">
    <h1>Players Directory</h1>

    <!-- Search Bar -->
    <div class="flex justify top-margin-30">
      <input type="text" class="light" placeholder="Search players..." v-model="query">
    </div>

    <!-- List of Players -->
    <div class="flex justify wrap top-margin-30">
      <router-link v-for="(player, index) in players" :to="`/u/${player.user}`" :key="index" class="box click fourth flex center column">
        <img :src="avatarUrl(player.avatar)" class="avatar">
        <span class="username top-margin-5">{{ player.user }}</span>
      </router-link>
    </div>

    <!-- Pagination -->
    <div class="flex center top-margin-30">
      <router-link :to="backLink" :class="['button', 'outline', 'small', 'auto', 'right-margin-5', {disabled: !curPage}]">
         Back
      </router-link>
      <router-link :to="nextLink" :class="['button', 'outline', 'small', 'auto', 'left-margin-5', {disabled: players.length < 100}]">
         Next
      </router-link>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {avatarUrl} from '../../utils';

export default {
  name: 'players',
  meta: {
    title: 'Players Directory',
  },
  data() {
    return {
      query: '',
      delayLoad: null,
    };
  },
  computed: Vuex.mapState({
    players: state => state.directory.players,
    curPage: state => parseInt(state.route.params.page || 0, 10),
    backLink: (state) => {
      const curPage = parseInt(state.route.params.page, 10);
      const back = curPage > 1 ? `/${curPage - 1}` : '';
      return `/directory${back}`;
    },
    nextLink: (state) => {
      const next = (parseInt(state.route.params.page, 10) || 0) + 1;
      return `/directory/${next}`;
    },
  }),
  methods: {
    avatarUrl,
    load() {
      this.$store.dispatch('loadDirectory', {
        page: this.curPage,
        query: this.query,
      });
    },
  },
  mounted() {
    if (this.$store.state.directory.players.length === 0) {
      this.load();
    }
  },
  watch: {
    $route() {
      this.load();
    },
    query(val, oldVal) {
      if ((val && val.length >= 3) || (val === '' && oldVal.length > 0)) {
        if (this.curPage > 0) {
          this.$router.push('/directory');
        } else {
          if (this.delayLoad) {
            clearTimeout(this.delayLoad);
          }

          this.delayLoad = setTimeout(() => {
            this.delayLoad = null;
            this.load();
          }, 300);
        }
      }
    },
  },
};
</script>