<template>
  <div class="signup">
    <div class="content">
      <h3>Check your e-mail and enter the 6-digit code.</h3>
      <div class="bottom-margin-30">
        <input
          type="text"
          class="underline"
          placeholder="Verification Code"
          v-model="code"
          @keyup.enter="submit"
          v-focus
        >
      </div>

      <!-- Login Button -->
      <button-loader
        value="Complete Login"
        :styles="{'color': true, 'top-margin-15': true}"
        :loading="loading"
        :click="submit"
      ></button-loader>
      <div class="red input-error" v-if="error">The code you entered was invalid.</div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import is from 'is_js';
import Cleave from '../Cleave.vue';
import GenderSelect from '../GenderSelect.vue';
import ButtonLoader from '../ButtonLoader.vue';

export default {
  name: 'multi-factor-auth',
  components: {Cleave, GenderSelect, ButtonLoader},
  data() {
    return {
      loading: false,
      code: '',
      error: false,
    };
  },
  computed: Vuex.mapState({
    email: state => state.auth.email,
    pass: state => state.auth.pass,
  }),
  methods: {
    submit() {
      const isModal = (this.$el.offsetParent.className.match(/login-modal-container/));

      if (this.code === '') {
        this.error = true;
        return;
      }

      // Update the state of the button.
      this.loading = true;
      this.error = false;

      // Send the login request to the server.
      this.$store.dispatch('login', {
        email: this.email.toLowerCase(),
        pass: this.pass,
        code: this.code,
      }).then((data) => {
        this.loading = false;
        this.error = data.err;
        if (!data.err) {
          // If they are signing up, send them to the signup flow.
          if (typeof this.$parent.toggleLogin === 'function' && isModal) {
            this.$parent.toggleLogin();
          } else {
            this.$router.push('/');
          }
        }
      });
    },
  },
};
</script>
