<template>
  <span
    :title="altTime"
    @mouseover="hover = true"
    @mouseout="hover = false"
  >
    {{ hoverText && hover ? hoverText : formattedTimestamp }}
  </span>
</template>

<script>
import Vuex from 'vuex';
import moment from 'moment';
import {formatTimestamp} from '../utils';

export default {
  name: 'live-time',
  props: ['timestamp', 'hoverText', 'format'],
  data() {
    return {
      formattedTimestamp: formatTimestamp(this.timestamp, false, this.format),
      hover: false,
    };
  },
  computed: Vuex.mapState({
    altTime() {
      return moment(this.timestamp).format('dddd, MMMM Do YYYY, h:mm:ss A');
    },
    now: state => state.now,
  }),
  watch: {
    now() {
      this.formattedTimestamp = formatTimestamp(this.timestamp, false, this.format);
    },
  },
};
</script>
