<template>
  <div class="container forums">
    <div class="text-center bottom-margin-30 strong"><h3>{{ `${this.title} Forums` }}</h3></div>
    <forum-threads-list-view v-if="show"></forum-threads-list-view>
    <div class="spinner auto-center" v-if="loading"></div>

    <!-- Pagination -->
    <div class="flex center top-margin-30">
      <router-link :to="backLink" :class="['button', 'outline', 'small', 'auto', 'right-margin-5', {disabled: !curPage}]">
         Back
      </router-link>
      <router-link :to="nextLink" :class="['button', 'outline', 'small', 'auto', 'left-margin-5', {disabled: threads.length < 25}]">
         Next
      </router-link>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumThreadsListView from '../ForumThreadsListView.vue';

export default {
  name: 'forums-category',
  components: {ForumThreadsListView},
  data() {
    return {
      loading: true,
      gid: parseInt(this.$store.state.route.params.category, 10),
      show: false,
    };
  },
  meta() {
    return {
      title: `${this.title} Forums`,
    };
  },
  created() {
    if (this.$store.state.forums.threads.length > 0) {
      this.show = true;
    }
  },
  mounted() {
    if (this.$store.state.user.auth) {
      this.$store.commit('toggleTab', 'forums');
      this.$store.dispatch('viewThreads', this.gid);
      this.$router.replace('/');
    } else if (this.$store.state.forums.threads.length === 0) {
      this.loading = false;
      this.show = true;
      this.$store.dispatch('viewThreads', this.gid);
    } else {
      this.loading = false;
    }
  },
  computed: Vuex.mapState({
    title(state) {
      const data = state.gameData.find(g => g.gid === this.gid);

      return data ? data.title : '';
    },
    threads: state => state.forums.threads,
    curPage: state => parseInt(state.route.params.page || 0, 10),
    backLink(state) {
      const curPage = parseInt(state.route.params.page, 10);
      const back = curPage > 1 ? `/${curPage - 1}` : '';
      return `/forums/${this.gid}${back}`;
    },
    nextLink(state) {
      const next = (parseInt(state.route.params.page, 10) || 0) + 1;
      return `/forums/${this.gid}/${next}`;
    },
  }),
  watch: {
    curPage(val) {
      this.$store.commit('setForumThreads', {});
      this.$store.dispatch('loadForumThreads', {gid: this.gid, page: val});
    },
  },
};
</script>
