<template>
  <div class="modal-container flex middle center">
    <div class="modal flex column">
      <!-- Header -->
      <div class="header">
        <div class="action"></div>
        <div class="truncate">{{ title }}</div>
        <div class="action">
          <div class="icon click close" @click="close"></div>
        </div>
      </div>

      <!-- App Review Prompt -->
      <app-review-prompt v-if="!showAll && showReviewPrompt"></app-review-prompt>

      <!-- Body -->
      <div :class="['content', 'flex', 'column', {center: !updates.length}]">
        <div class="spinner auto-center" v-if="!updates.length"></div>

        <!-- Updates List -->
        <info-box v-for="(update, i) in updates" :title="update.title" :time="update.time" type="''" :key="i">
          <div class="update-list" v-html="update.text"></div>
        </info-box>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import InfoBox from './InfoBox.vue';
import AppReviewPrompt from './AppReviewPrompt.vue';

export default {
  name: 'updates-modal',
  components: {InfoBox, AppReviewPrompt},
  computed: Vuex.mapState({
    showAll: state => state.updates.all,
    title: state => state.updates.all ? 'UPDATES' : 'UPDATES SINCE LAST VISIT',
    showReviewPrompt: (state) => {
      return state.app.type === 'mobile' || state.app.type === 'windows';
    },
    updates: (state) => {
      return state.updates.data.map((update) => {
        const text = update.text
          .trim()
          .split(/\n/)
          .map((line) => {
            if (line.startsWith('<li>')) {
              line = `<ul>${line}</ul>`;
            }

            return `<div class="${line[0] === '*' ? 'line' : 'text-line'}">${line}${line[0] === '*' ? '</div>' : ''}</div>`;
          })
          .join('')
          .replace(/\*FEATURE\*/g, '<span class="update-tag feature">Feature</span><div>')
          .replace(/\*UPDATE\*/g, '<span class="update-tag update">Update</span><div>')
          .replace(/\*FIX\*/g, '<span class="update-tag fix">Fix</span><div>');

        return {
          title: update.title,
          time: new Date(update.time),
          text,
        };
      });
    },
  }),
  methods: {
    close() {
      this.$store.commit('setUpdates', {show: false, data: []});
    },
  },
  beforeDestroy() {
    const iframe = this.$root.$el.querySelector('.game-iframe');
    const content = iframe ? iframe.contentWindow : '';
    if (content) {
      const origin = (iframe.src.match(/^.+:\/\/[^/]+/) || [])[0];
      content.postMessage({action: 'hideUpdates'}, origin);
    }
  },
};
</script>
