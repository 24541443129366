<template>
  <transition name="fade">
    <div class="login-modal-container flex middle center" v-if="show">
      <div class="login-modal light flex column middle center">
        <h3>Is your e-mail address correct?</h3>

        <!-- Update Email Form -->
        <div class="flex row justify bottom-margin-30">
          <input
            type="text"
            placeholder="E-mail Address"
            v-model="email"
            @keyup.enter="update"
            v-focus
          >
          <button class="auto color small left-margin-15" @click="update">Update</button>
        </div>

        <!-- Link To Close Modal -->
        <a href="#" @click.prevent="close" class="link top-margin-30">Yes, my e-mail is correct.</a>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'email-check-modal',
  data() {
    return {
      email: this.$store.state.user.email,
    };
  },
  computed: Vuex.mapState({
    show: state => state.user.emailcheck,
    userEmail: state => state.user.email,
  }),
  watch: {
    userEmail(val) {
      this.email = val;
    },
  },
  methods: {
    close() {
      this.$store.commit('setUserData', {emailcheck: false});
    },
    update() {
      this.$store.dispatch('updateEmail', this.email);
      this.close();
    },
  },
};
</script>
