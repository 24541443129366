<template>
  <span>
    <transition name="drawer">
      <text-editor v-show="showEditor"></text-editor>
    </transition>
  </span>
</template>

<script>
import Vuex from 'vuex';
import TextEditor from './TextEditor.vue';

export default {
  name: 'forum-thread-footer',
  components: {TextEditor},
  computed: Vuex.mapState({
    showEditor: state => state.editor.show,
  }),
  beforeDestroy() {
    if (this.showEditor) {
      this.$store.commit('toggleEditor', {type: null});
    }
  },
};
</script>
