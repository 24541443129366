<template>
  <div class="content">
    <notification-box
      v-for="notification in notifications"
      class="inf"
      :id="`id-${notification._id}`"
      :notification="notification"
      :key="notification._id"
    >
    </notification-box>
    <div v-if="notifications.length === 0 && !isLoading" class="box center">
      You don't have any recent notifications.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import NotificationBox from './NotificationBox.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'notifications-view',
  components: {NotificationBox},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'NOTIFICATIONS',
      buttons: [],
    });

    // Update the counters.
    const game = this.$store.state.launcher.games.find(data => data.game === this.gid);
    this.$store.commit('incToolbarNotifications', -game.notifications);
    this.$store.commit('resetGameNotifications', this.gid);

    this.load(); // load first page of data
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        minRowHeight: 60,
        actionName: 'loadNotifications',
        actionParams: {gid: this.gid},
        mutationName: 'setNotifications',
        items: state.launcher.notifications,
        keys: ['_id'],
      };
    },
    notifications: state => state.launcher.notifications,
    gid: state => state.launcher.notificationsId,
    isLoading: state => state.panel.loading,
  }),
  beforeDestroy() {
    // Clear out the notifications list.
    this.$store.commit('setNotifications', {items: [], concat: false});
    this.$store.dispatch('markNotificationsRead', this.gid);
    this.$store.commit('resetGameNotifications', this.gid);
  },
};
</script>
