<template>
  <social-feed></social-feed>
</template>

<script>
import Vuex from 'vuex';
import SocialFeed from './SocialFeed.vue';

export default {
  name: 'social-feed-view',
  components: {SocialFeed},
  watch: {
    enterDown(val, oldVal) {
      if (!val && oldVal) {
        this.$store.dispatch('postSocialUpdate');
      }
    },
  },
  computed: Vuex.mapState({
    enterDown: state => state.editor.enterDown,
  }),
  beforeDestroy() {
    this.$store.dispatch('setLastSocialFeedView');
  },
};
</script>
