<template>
  <transition :name="transition" mode="out-in">
    <div class="box notice user full-bleed click middle" @click="toggleBox" key="on" v-if="!clicked">
      <avatar :player="player" :noAction="true"></avatar>
      <div class="text full forty">
        <div class="user">
          <username :player="player" :isClickable="false"></username>
        </div>
        <active-game :uid="player.uid" :gid="player.game"></active-game>
      </div>
      <span class="text-counter small light time" style="width: 22%;">
        <status :uid="player.uid"></status>
        <status-live-time :uid="player.uid"></status-live-time>
      </span>
    </div>

    <!-- Profile/Message Buttons -->
    <div class="box user" key="off" v-else>
      <button :class="['small', {'left-btn': player.canMessage || showRemove}]" @click="viewProfile">Profile</button>
      <button v-if="player.canMessage" class="small right-btn" @click="newChat">Message</button>
      <button v-if="showRemove" class="small right-btn" @click="onRemove">Remove</button>
    </div>
  </transition>
</template>

<script>
import StatusLiveTime from './StatusLiveTime.vue';
import Status from './Status.vue';
import ActiveGame from './ActiveGame.vue';
import Avatar from './Avatar.vue';
import Username from './Username.vue';

export default {
  name: 'friend-box',
  components: {StatusLiveTime, Status, ActiveGame, Avatar, Username},
  props: ['player', 'toggle', 'remove'],
  data() {
    return {
      transition: null,
    };
  },
  mounted() {
    this.transition = 'fade-fast';
  },
  computed: {
    clicked() {
      return this.toggle === this.player.uid;
    },
    showRemove() {
      return this.remove || this.player.uid === this.$store.state.user.uid;
    },
  },
  methods: {
    toggleBox() {
      if (this.$store.state.chat.friendListType === 'group') {
        this.$store.dispatch('addGroupChat', this.player.uid);
      } else {
        this.$parent.toggle = this.player.uid;
      }
    },
    viewProfile() {
      this.$store.dispatch('viewProfile', {uid: this.player.uid, shouldAddHistory: false});
      this.history();
    },
    newChat() {
      this.$store.dispatch('startChatThread', this.player.uid);
    },
    history() {
      this.$store.commit('addHistory', {
        mutation: 'setFriendListType',
        data: {type: 'friend'},
      });
    },
    onRemove() {
      this.$emit('remove', this.player.uid);
    },
  },
};
</script>
