<template>
  <div class="login-modal-container flex center middle">
    <div :class="['login-modal', {native: isMobile}]">
      <div class="signup privacy">
        <div class="content column">
          <h3>Keep It Legal</h3>
          <div class="subtitle">Please review and accept our terms and privacy policies. Update your data at any time from your profile settings.</div>

          <!-- Accept Buttons -->
          <div class="top-margin-10 full">
            <div class="buttons-container flex row justify">
              <div class="consent-box flex center column">
                <div class="terms-icon"></div>
                <a href="/terms" target="_blank">Terms & Conditions</a>
                <div :class="['button', 'tiny', 'fixed', {untoggle: !termsAccepted}]" @click="termsAccepted = !termsAccepted">Accept</div>
              </div>

              <div class="consent-box flex center column">
                <div class="privacy-icon"></div>
                <a href="/privacy" target="_blank">Privacy Policy</a>
                <div :class="['button', 'tiny', 'fixed', {untoggle: !privacyAccepted}]" @click="privacyAccepted = !privacyAccepted">Accept</div>
              </div>

              <div class="consent-box flex center column">
                <div class="marketing-icon"></div>
                <a href="/privacy#2" target="_blank">Marketing Policy</a>
                <div :class="['button', 'tiny', 'fixed', {untoggle: !marketingAccepted}]" @click="marketingAccepted = !marketingAccepted">Accept</div>
              </div>
            </div>
          </div>

          <!-- Play Now Button -->
          <button-loader
            value="Give Consent"
            :styles="{'color': true, 'top-margin-30': true, 'disabled': !termsAccepted || !privacyAccepted || !marketingAccepted}"
            :loading="loading"
            :click="complete"
          ></button-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ButtonLoader from './ButtonLoader.vue';

export default {
  name: 'privacy-consent',
  components: {ButtonLoader},
  data() {
    return {
      loading: false,
      termsAccepted: false,
      privacyAccepted: false,
      marketingAccepted: false,
    };
  },
  computed: Vuex.mapState({
    isMobile: state => state.app.type === 'mobile',
  }),
  methods: {
    complete() {
      this.loading = true;
      this.$store.dispatch('privacyConsent').then((data) => {
        this.loading = false;
        
        // Close the modal and update the data.
        this.$store.commit('setUserData', {gdpr: true});
        if (document.body.querySelector('.game-iframe')) {
          document.body.querySelector('.game-iframe').focus();
        }
      });
    },
  },
};
</script>
