<template>
  <div class="content">
    <template v-for="transaction in history">
      <shop-history-box :transaction="transaction"></shop-history-box>
    </template>
    <div v-if="history.length === 0 && !isLoading" class="box center">
      You have no transaction history.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ShopHistoryBox from './ShopHistoryBox.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'shop-history-view',
  components: {ShopHistoryBox},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'HISTORY',
      buttons: [],
    });

    this.load();
  },
  computed: Vuex.mapState({
    history: state => state.shop.history,
    isLoading: state => state.panel.loading,
    infiniteScrollingCfg(state) {
      return {
        mutationName: 'setShopHistory',
        actionName: 'getShopHistory',
        items: state.shop.history,
        keys: ['_id'],
      };
    },
  }),
};
</script>
