<template>
  <div class="content">
    <!-- Product -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Credit Card Checkout -->
    <info-box title="Credit/Debit Card" type="card">
      <!-- Saved Credit Cards Checkout -->
      <template v-if="showSaved && creditCards.length > 0">
        <credit-card-box
          v-for="(card, index) in creditCards"
          :card="card"
          :selected="curCard === index"
          @click="curCard = index"
          :key="index"
        ></credit-card-box>
        <button class="credit-card top-margin-10" @click="showSaved = false">Use a different credit/debit card.</button>
      </template>

      <!-- Checkout Form -->
      <template v-else>
        <div class="box input">
          <cleave
            :class="[{error: error.card}]"
            placeholder="Card Number"
            v-model="card"
            :options="{creditCard: true}"
            v-focus
            @enter="purchase"
          ></cleave>
        </div>
        <div class="red input-error" v-if="error.card">Enter a valid card number.</div>
        <span class="flex row justify top-margin-10">
          <span class="name">
            <span class="box input">
              <input type="text" v-model="name" :class="{error: error.name}" placeholder="Name on Card" @keyup.enter="purchase">
            </span>
            <div class="red input-error" v-if="error.name">Enter a name.</div>
          </span>
          <span class="zip">
            <span class="box input">
              <input type="text" v-model="zip" :class="{error: error.zip || error.badZip}" placeholder="Postal Code" @keyup.enter="purchase">
            </span>
            <div class="red input-error" v-if="error.zip">Enter a postal code.</div>
            <div class="red input-error" v-if="error.badZip">Incorrect postal code.</div>
          </span>
        </span>
        <span class="flex row justify top-margin-10">
          <span class="name">
            <span class="box input">
              <cleave
                :class="[{error: error.expire}]"
                placeholder="Expiration (mm/yy)"
                v-model="expire"
                :options="{date: true, datePattern: ['m', 'y']}"
                @enter="purchase"
              ></cleave>
            </span>
            <div class="red input-error" v-if="error.expire">Enter a valid expiration (mm/yy).</div>
          </span>
          <span class="zip">
            <span class="box input">
              <input type="text" v-model="cvc" :class="{error: error.cvc || error.badCvc}" placeholder="Security Code" @keyup.enter="purchase">
            </span>
            <div class="red input-error" v-if="error.cvc">Enter a CVC.</div>
            <div class="red input-error" v-if="error.badCvc">Incorrect CVC.</div>
          </span>
        </span>
        <div class="top-margin-15 bottom-margin-5 subtext small" v-if="!product.recurring">
          <input type="checkbox" id="save-cc" value="1" v-model="save">
          <label for="save-cc"><span></span>Securely save card for future use.</label>
        </div>
      </template>
    </info-box>

    <!-- Purchase Button -->
    <button-loader
      value="Purchase"
      :styles="{'top-margin-0': true}"
      :loading="loading"
      :click="purchase"
    ></button-loader>
    <div class="red input-error text-center" v-if="error.unknown">An unknown error occured. Please try again.</div>
    <div class="red input-error text-center" v-if="error.decline">Your card was declined. Please verify that you entered the correct information.</div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import Cleave from './Cleave.vue';
import InfoBox from './InfoBox.vue';
import ShopProductBox from './ShopProductBox.vue';
import ButtonLoader from './ButtonLoader.vue';
import CreditCardBox from './CreditCardBox.vue';

export default {
  name: 'shop-card-payment-view',
  components: {Cleave, InfoBox, ShopProductBox, ButtonLoader, CreditCardBox},
  data() {
    return {
      card: '',
      name: '',
      zip: '',
      expire: '',
      cvc: '',
      save: true,
      loading: false,
      showSaved: true,
      curCard: 0,
      error: {
        card: false,
        expire: false,
        cvc: false,
        name: false,
        zip: false,
        badCvc: false,
        decline: false,
        badZip: false,
        unknown: false,
      },
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    // Make sure the user has the Stripe key set.
    if (typeof Stripe !== 'undefined' && !Stripe.key) {
      const isProd = (process.env.NODE_ENV === 'production' && process.env.TEST_ENV !== 'true');
      Stripe.setPublishableKey(isProd ? 'pk_l9mGyI8QRm1ixl95d0ux1dyyG2XY5' : 'pk_MKooN4vSllf0NTLx84FtbA6K2eggZ');
    }
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
    creditCards: state => state.shop.manage.cards,
  }),
  methods: {
    toggleInputs(disable) {
      const elms = this.$el.querySelectorAll('input');
      [].forEach.call(elms, (elm) => {
        elm.disabled = disable;
      });
    },
    purchase() {
      // If they are using a stored card, send that to the server instead.
      if (this.showSaved && this.creditCards.length > 0) {
        this.processPayment({card: this.creditCards[this.curCard].id});
        return;
      }

      const expire = this.expire.split('/');
      const mm = expire ? expire[0] : '';
      const yy = expire ? `20${expire[1]}` : '';
      const card = this.card.replace(' ', '');

      // Validate the expiration date, card number, etc.
      Object.keys(this.error).forEach((key) => {
        this.error[key] = false;
      });
      if (!Stripe.card.validateCardNumber(card)) {
        this.error.card = true;
      }
      if (!Stripe.card.validateExpiry(mm, yy)) {
        this.error.expire = true;
      }
      if (!Stripe.card.validateCVC(this.cvc)) {
        this.error.cvc = true;
      }
      if (this.name === '') {
        this.error.name = true;
      }
      if (this.zip === '') {
        this.error.zip = true;
      }

      // Submit the payment if there are no errors.
      if (!Object.keys(this.error).some(key => this.error[key] === true)) {
        this.loading = true;
        this.toggleInputs(true);

        // Send the payment to stripe.
        Stripe.createToken({
          number: card,
          cvc: this.cvc,
          exp_month: mm,
          exp_year: yy,
          name: this.name,
          address_zip: this.zip,
        }, (status, res) => {
          if (status !== 200 || res.error) {
            this.toggleInputs(false);
            this.loading = false;
            this.error.unknown = true;
            // Temporary debugging logging.
            Raven.captureMessage('Card Payment Debug Message', {
              extra: {
                status,
                err: res.error,
                res,
              },
            });
            return;
          }

          // Send the payment details to the server for processing.
          this.processPayment({id: res.id});
        });
      }
    },
    processPayment({card, id}) {
      this.loading = true;

      // Send the request to process the payment (either a saved card or new).
      this.$store.dispatch('processCreditCard', {
        product: this.product.id,
        id,
        card,
        gid: this.product.gid,
        save: this.save,
        gift: this.$store.state.shop.gift,
        type: 'Credit Card',
      }).then((data) => {
        this.loading = false;
        this.toggleInputs(false);

        // Update the errors and check if there are any.
        Object.keys(data.error).forEach((key) => {
          this.error[key] = true;
        });

        // Check if there are errors before proceeding.
        if (!Object.keys(this.error).some(key => this.error[key] === true)) {
          this.$store.commit('changeView', 'shop-success-view');
        }
      });
    },
  },
};
</script>
