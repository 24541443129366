<template>
  <div id="media" class="info media-type column center middle">
    <input class="light bottom-margin-10" placeholder="YouTube, Vimeo or Twitch Link" v-model="url" @keyup.enter="insert" v-focus>
    <button class="small color" @click="insert">Insert Video</button>
  </div>
</template>

<script>
export default {
  name: 'media-keyboard-video',
  data() {
    return {
      url: '',
    };
  },
  methods: {
    insert() {
      this.$parent.$emit('insert', {
        type: 'video',
        data: {
          url: this.url,
        },
      });
      this.$parent.closeMedia();
    },
  },
  serverCacheKey() {
    return true;
  },
};
</script>
