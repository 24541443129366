<template>
  <div class="box blue user wide" v-if="reviews">
    <!-- Prompt Text -->
    <div v-if="view === 'default'">Are you enjoying the game?</div>
    <div v-if="view === 'review'">Mind leaving a review? It really helps!</div>
    <div v-if="view === 'feedback'">Could you tell us how we can improve?</div>

    <!-- Buttons -->
    <div class="flex row end" v-if="view === 'default'">
      <button class="small slim auto white right-margin-10" @click="view = 'review'">Love It!</button>
      <button class="small slim auto white" @click="view = 'feedback'">Could Be Better</button>
    </div>
    <button class="small slim auto white" @click="review" v-if="view === 'review'">Leave Review</button>
    <button class="small slim auto white" @click="feedback" v-if="view === 'feedback'">Send Feedback</button>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'app-review-prompt',
  data() {
    return {
      view: 'default',
    };
  },
  created() {
    // Send request to the server to make sure we can show this prompt.
    this.$store.dispatch('checkReviewPrompt', {
      type: this.appType,
      gid: this.gid,
    });
  },
  computed: Vuex.mapState({
    reviews: state => state.updates.reviews,
    appType: state => state.app.type,
    gid: state => state.activeGame.gid,
  }),
  methods: {
    review() {
      // Don't show the prompt again.
      this.disableFuturePrompts();

      // Open the review prompt.
      window.parent.postMessage({
        action: 'showReviewPrompt',
      }, '*');

      // Notify the Windows Store app.
      const hasExternal = typeof window.external !== 'undefined' && 'notify' in window.external;
      if (hasExternal && this.$store.state.app.type === 'windows') {
        window.external.notify('reviewPrompt');
      }

      // Close the prompt.
      this.close();
    },
    feedback() {
      // Don't show the prompt again.
      this.disableFuturePrompts();

      // Open the support window.
      this.$store.commit('toggleTab', 'info');
      setTimeout(() => {
        UserVoice.showPrompt('contact');
      }, 750);

      // Close the prompt.
      this.close();
    },
    disableFuturePrompts() {
      this.$store.dispatch('setReviewPrompt', {
        type: this.appType,
        gid: this.gid,
      });
    },
    close() {
      this.$store.commit('setUpdates', {reviews: false});
    },
  },
};
</script>
