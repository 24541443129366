<template>
  <div class="box no-padding click" @click="viewThreads">
    <div class="middle">
      <div :class="['game-icon', data.id, 'right-margin-10']"></div>
      {{ data.name }}
    </div>
    <span v-if="data.unread" class="counter right-margin-10">{{ data.unread }}</span>
  </div>
</template>

<script>
export default {
  name: 'forum-category',
  props: ['data', 'index'],
  methods: {
    viewThreads() {
      if (this.$store.state.user.auth) {
        this.$store.dispatch('viewThreads', this.data.gid);
      } else {
        this.$router.push(`/forums/${this.data.gid}`);
      }
    },
  },
};
</script>
