<template>
  <div class="page full flex center middle">
    <div class="spinner auto-center"></div>
  </div>
</template>

<script>
export default {
  name: 'view-notifications',
  mounted() {
    if (this.$store.state.user.auth) {
      this.$router.push({path: '/', query: {notices: 1}});
    } else {
      this.$router.push('/login');
    }
  },
};
</script>
