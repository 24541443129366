<template>
  <span v-if="update.commenting" class="post comment-container">
    <social-feed-comment v-for="comment in update.comments" :comment="comment" :update="update" :key="comment.t"></social-feed-comment>
    <span class="box input no-border" v-if="auth && !guest">
      <input type="text" placeholder="Your comment..." class="small" v-model="currentComment" @keyup.enter="saveComment">
    </span>
  </span>
</template>

<script>
import Vuex from 'vuex';
import SocialFeedComment from './SocialFeedComment.vue';

export default {
  name: 'social-feed-comments',
  props: ['update'],
  components: {SocialFeedComment},
  data() {
    return {
      currentComment: '',
    };
  },
  methods: {
    saveComment() {
      const comment = {
        content: this.currentComment,
      };

      this.$store.dispatch('submitUpdateComment', {
        comment,
        updateId: this.update._id,
      });
      this.currentComment = '';
    },
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    guest: state => state.user.guest,
  }),
};
</script>
