<template>
  <transition name="slide">
    <div
      :class="{tab: true, active: activeTab === type}"
      v-show="show"
      :key="type"
      @click="toggleTab(type)"
      @mouseover="hover = true"
      @mouseout="hover = false"
    >
      <transition name="scale">
        <div v-if="counter" class="toolbar-counter">{{ counter }}</div>
      </transition>
      <div :class="[{icon: type !== 'launcher'}, type]">
        <img v-if="type === 'launcher'" :src="avatar">
      </div>

      <!-- Label (for mobile portrait mode or desktop hover) -->
      <transition name="toolbar-label-delay">
        <div class="icon-label" :style="{display: mobile ? 'none' : 'flex'}" v-if="showLabel">{{ labelText }}</div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';
import {avatarUrl, isMobile} from '../utils';

let screenfull = {};
if (typeof document !== 'undefined') {
  screenfull = require('screenfull');
}

export default {
  name: 'toolbar-icon',
  props: ['type'],
  data() {
    return {
      hover: false,
    };
  },
  computed: Vuex.mapState({
    activeTab: (state) => state.toolbar.activeTab,
    avatar: (state) => avatarUrl(state.user.avatar),
    counter(state) {
      return state[this.type] && this.type !== 'forums' ? Math.min(99, state[this.type].counter) || 0 : 0;
    },
    game: (state) => state.activeGame,
    mobile: (state) => state.app.type === 'mobile' || isMobile(state.user.ua),
    showLabel() {
      const landscape = this.game.orientation === 'landscape';

      return (this.game && this.game.gid > 0 && this.activeTab === 'empty' && landscape && this.mobile)
        || (this.hover && !this.mobile && this.activeTab === 'empty');
    },
    show(state) {
      let show = true;

      // Only show info and leaderbord tabs on games.
      const isActiveGame = this.game && this.game.gid > 0;
      if (this.type === 'info' && !isActiveGame) {
        show = false;
      }
      if (this.type === 'leaderboards' && (!this.game || !this.game.leaderboards)) {
        show = false;
      }
      if (this.type === 'shop' && ((!isActiveGame && this.activeTab !== 'shop') || state.user.guest)) {
        show = false;
      }

      // Hide the full-screen button on iOS since it doesn't work.
      const isBrowser = typeof window !== 'undefined' && typeof window.navigator !== 'undefined';
      if (this.type === 'fullscreen' && isBrowser) {
        const isApp = state.app.type === 'mobile';
        if (isApp || typeof window.Windows === 'object') {
          show = false;
        }
      }

      return show;
    },
    labelText() {
      if (this.type === 'launcher') {
        return 'Profile/Settings';
      }
      if (this.type === 'chat') {
        return 'Private Chat';
      }
      if (this.type === 'forums') {
        return 'Community';
      }
      if (this.type === 'leaderboards') {
        return 'Leaderboards';
      }
      if (this.type === 'shop') {
        return 'Shop';
      }
      if (this.type === 'info') {
        return 'Info/Support';
      }
      if (this.type === 'fullscreen') {
        return 'Fullscreen';
      }

      return '';
    },
  }),
  methods: {
    toggleTab(tab) {
      if (tab !== 'fullscreen') {
        this.requestNotificationPermission();
        this.$store.commit('toggleTab', tab);
      } else {
        if (this.$store.state.app.type === 'steam') {
          window.steam.ipc.sendToHost('toggleFullscreen');
        } else {
          screenfull.toggle();
        }
      }
    },
    requestNotificationPermission() {
      if ('Notification' in window && typeof Notification.requestPermission === 'function' && Notification.permission == 'default') {
        Notification.requestPermission();
      }
    },
  },
};
</script>
