<template>
  <div :class="['box', 'credit-card', {toggle: !canDelete}, {selected: selected}]" @click="click">
    <template v-if="!card.deleting">
      <div :class="['left-margin-10', 'icon', card.type + '-card']"></div>
      <span class="text flex column end">
        <span class="title">**** **** **** {{card.number}}</span>
        <span class="subtext">{{card.expiration}}</span>
      </span>
      <button class="button square error no-padding" @click="toggleDeleteCC" v-if="canDelete">
        <div class="icon trash"></div>
      </button>
    </template>
    <template v-else>
      <span class="text">
        <span>Are you sure?</span>
      </span>
      <button class="button square no-padding" style="margin-right: 1px;" @click="deleteCC">Yes</button>
      <button class="button square no-padding" @click="toggleDeleteCC">No</button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'credit-card-box',
  props: ['card', 'canDelete', 'selected'],
  methods: {
    toggleDeleteCC() {
      this.$emit('toggle');
    },
    deleteCC() {
      this.$emit('delete');
    },
    click() {
      this.$emit('click');
    },
  },
};
</script>
