<template>
  <div class="content">
    <chat-message
      v-for="(message, index) in messages"
      class="inf"
      :id="`id-${message._id}`"
      :message="message"
      :player="getPlayer(message.player)"
      :first="checkFirst(index)"
      :key="message._id"
    ></chat-message>
    <span v-html="styles"></span>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ChatMessage from './ChatMessage.vue';
import {shortUsernames} from '../utils';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'chat-message-view',
  components: {ChatMessage},
  mixins: [infiniteScrolling],
  data() {
    return {
      scrolledToBottom: false,
      msgsLength: 0,
      statusClass: 'offline',
      styles: '<style>.panel .buttons {position: absolute !important;}</style>',
      panel: {
        title: '',
        buttons: [],
        status: 'offline',
        altIcon: 'settings',
        altMethod: {
          mutation: 'viewChatSettings',
          data: {players: this.$store.state.chat.activeMessage.players},
        },
        footerView: 'chat-message-footer',
      },
    };
  },
  mounted() {
    this.panel = Object.assign({}, this.panel, {title: this.name || this.user});
    this.$store.commit('setScrollData', {start: false});
    this.load();
    this.$store.dispatch('sub', `playerStatus:${this.uid}`);
    this.$store.dispatch('sub', `recChatMessage:${this.id}`);
  },
  watch: {
    panel(val) {
      this.$store.commit('setPanelData', val);
    },
    players(val) {
      this.panel.altMethod.data.players = val;
    },
    messages(val) {
      // If we are receiving a single new message, jump to the bottom.
      if (val && val.length > 0) {
        const somebodyIsTyping = val[val.length - 1].typing;
        const newMsgPosted = (val.filter(msg => !msg.typing).length - 1) === this.msgsLength;

        if (somebodyIsTyping || newMsgPosted) {
          this.purge('first', val);
          
          // Determine if the user has already scrolled up and block auto scrolling down.
          const content = this.$parent.$el;
          const atBottom = content.scrollHeight - (content.scrollTop + content.clientHeight) <= 50;
          if (atBottom) {
            this.$nextTick(() => {
              this.$store.commit('scrollToBottom');
            });
          }
        }
      }

      if (val && val[val.length - 1] && !val[val.length - 1].typing) {
        this.msgsLength = val.length;
      }
    },
    enterDown(val, oldVal) {
      if (!val && oldVal) {
        this.$store.dispatch('sendChatMessage');
      }
    },
    editorHtml(val, oldVal) {
      if (val !== '<div><br></div>' && val !== '<br>' && (oldVal === '<div><br></div>' || oldVal === '')) {
        this.$store.dispatch('chatTyping', true);
      } else if (val === '<div><br></div>' && oldVal) {
        this.$store.dispatch('chatTyping', false);
      }
    },
    status(val) {
      if (this.players.length > 2) {
        return;
      }

      this.updateStatus(val);
    },
    title(val) {
      this.panel.title = val;
    },
  },
  methods: {
    getPlayer(index) {
      return this.players[index];
    },
    checkFirst(index) {
      const cur = this.messages[index];
      const prev = this.messages[index - 1];

      return !(prev && prev.player === cur.player);
    },
    updateStatus(val) {
      this.statusClass = 'offline';
      if (val && val.away && val.status === 'on') {
        this.statusClass = 'away';
      } else if (val && val.status === 'on') {
        this.statusClass = 'online';
      }

      this.panel = Object.assign({}, this.panel, {
        buttons: this.$store.state.panel.buttons,
        status: this.statusClass,
      });
    },
    loadRes() {
      // Update the panel data.
      if (this.players.length <= 2) {
        this.updateStatus(this.status);
      } else {
        const players = this.players.filter(player => player.uid !== this.$store.state.user.uid);
        this.panel = Object.assign({}, this.panel, {
          buttons: this.$store.state.panel.buttons,
          status: null,
          title: this.name || shortUsernames(players),
        });
      }

      // Scroll to the bottom.
      if (!this.scrolledToBottom) {
        this.$nextTick(() => {
          this.$store.commit('scrollToBottom');
        });
      }

      this.scrolledToBottom = true;
    },
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        actionName: 'loadChatThread',
        actionParams: {id: this.id},
        mutationName: 'setChatThread',
        minRowHeight: 48,
        items: state.chat.activeMessage.messages,
        onLoaded: this.loadRes,
        keys: ['_id'],
      };
    },
    messages: state => state.chat.activeMessage.messages,
    players: state => state.chat.activeMessage.players,
    id: state => state.chat.activeMessage.id,
    user: state => state.chat.activeMessage.user,
    name: state => state.chat.activeMessage.name,
    uid: state => state.chat.activeMessage.uid,
    showEditor: state => state.editor.show,
    enterDown: state => state.editor.enterDown,
    editorHtml: state => state.editor.value,
    status(state) {
      return state.status.players[this.uid] || {status: 'off'};
    },
    title: state => this.name || state.chat.activeMessage.user,
  }),
  beforeDestroy() {
    this.$store.dispatch('unsub', `recChatMessage:${this.id}`);
    this.$store.dispatch('unsub', `playerStatus:${this.uid}`);
    this.$store.dispatch('chatTyping', false);
  },
  destroyed() {
    this.$store.commit('resetChatThread');
  },
};
</script>
