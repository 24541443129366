<template>
  <div id="media" class="info media-type column center middle">
    <input id="url" type="text" class="light bottom-margin-10" placeholder="Link URL" @keyup.enter="insert" v-model="url" v-focus>
    <input id="text" type="text" class="light bottom-margin-10" placeholder="Link Text" @keyup.enter="insert" v-model="text">
    <button class="small color" @click="insert">Insert Link</button>
  </div>
</template>

<script>
export default {
  name: 'media-keyboard-link',
  data() {
    return {
      text: this.$parent.$parent.$children[0].editor.getSelectedText(),
      url: '',
    };
  },
  methods: {
    insert() {
      this.$parent.$emit('insert', {
        type: 'link',
        data: {
          url: this.url,
          text: this.text,
        },
      });
      this.$parent.closeMedia();
    },
  },
};
</script>
