<template>
  <div class="content">
    <!-- Game Update Button -->
    <div class="flex row justify">
      <button :class="[{'half-gap': data.guide || data.dynamicGuide}]" @click="showUpdates" v-if="data.dynamicUpdates">Updates</button>
      <a :href="data.updates" :class="['button', {'half-gap': data.guide || data.dynamicGuide}]" target="_blank" v-else>Updates</a>
      <button class="half-gap" @click="showGuide" v-if="data.dynamicGuide">Guide</button>
      <a :href="data.guide" class="button half-gap" target="_blank" v-else-if="data.guide">Guide</a>
    </div>

    <!-- Logout Button -->
    <div>
      <button @click="logout">Logout</button>
    </div>

    <!-- Apps Section -->
    <info-box title="Apps" v-if="data.apps.length > 0 && !isMobile">
      <a v-for="app in data.apps" :href="app.url" target="_blank" :class="['icon', 'logo', 'click', app.icon]"></a>
    </info-box>

    <!-- Social Section -->
    <info-box title="Share">
      <a :href="fbLink" @click="click(fbLink)" :class="['icon', 'logo', 'click', 'facebook']" target="_blank"></a>
      <a :href="tweetLink" @click="click(tweetLink)" :class="['icon', 'logo', 'click', 'twitter']" target="_blank"></a>
      <a :href="pinLink" @click="click(pinLink)" :class="['icon', 'logo', 'click', 'pinterest']" target="_blank"></a>
      <a :href="gramLink" @click="click(gramLink)" :class="['icon', 'logo', 'click', 'instagram']" target="_blank"></a>
      <a :href="twitchLink" @click="click(twitchLink)" :class="['icon', 'logo', 'click', 'twitch']" target="_blank"></a>
      <a :href="youtubeLink" @click="click(youtubeLink)" :class="['icon', 'logo', 'click', 'youtube']" target="_blank"></a>
      <a :href="discordLink" @click="click(discordLink)" :class="['icon', 'logo', 'click', 'discord']" target="_blank"></a>
    </info-box>

    <!-- Online Users Section -->
    <info-box title="Online Players" type="avatar-list" :counter="online.length">
      <div class="spinner auto-center" v-if="loading"></div>
      <friends :friends="online" :parentLoading="loading" space-between="10"></friends>
    </info-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import InfoBox from './InfoBox.vue';
import Friends from './Friends.vue';
import {iOSDevice} from '../utils';

export default {
  name: 'info-view',
  components: {InfoBox, Friends},
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'SUPPORT & INFO',
      buttons: this.data.feedback ? [
        {label: 'Feedback', id: 'feedback-button'},
        {label: 'Support', id: 'support-button'},
      ] : [],
    });

    // Setup the UserVoice widgets.
    this.$nextTick(() => {
      if (typeof UserVoice !== 'undefined' && this.data.feedback) {
        UserVoice.push(['set', {
          accent_color: '#3b8ad6',
          screenshot_enabled: false,
        }]);
        UserVoice.push(['identify', {
          email: this.$store.state.user.email,
          name: this.$store.state.user.user,
          id: this.$store.state.user.uid,
          account: {
            id: this.$store.state.user.uid,
            name: this.$store.state.user.user,
            ltv: this.$store.state.user.ltv,
          },
        }]);
        UserVoice.push(['addTrigger', '#feedback-button', {
          mode: 'smartvote',
          smartvote_category_ids: [this.data.feedbackCat],
        }]);
        UserVoice.push(['addTrigger', '#support-button', {
          mode: 'contact',
        }]);
      }
    });

    // Get the online players.
    this.$store.dispatch('getOnlinePlayers', this.data.gid);
    this.$store.dispatch('sub', `setOnlineGame:${this.data.gid}`);
  },
  methods: {
    logout() {
      this.$store.commit('toggleTab', 'info');
      this.$router.push('/bye');
    },
    showUpdates() {
      const iframe = this.$root.$el.querySelector('.game-iframe');
      const content = iframe ? iframe.contentWindow : '';
      if (content) {
        const origin = (iframe.src.match(/^.+:\/\/[^/]+/) || [])[0];
        content.postMessage({action: 'showUpdates'}, origin);
      }

      this.$store.commit('setUpdates', {show: true});
      this.$store.commit('toggleTab', 'info');
    },
    showGuide() {
      const iframe = this.$root.$el.querySelector('.game-iframe');
      const content = iframe ? iframe.contentWindow : '';
      if (content) {
        const origin = (iframe.src.match(/^.+:\/\/[^/]+/) || [])[0];
        content.postMessage({action: 'showGuide'}, origin);
      }

      this.$store.commit('toggleTab', 'info');
    },
    click(url) {
      // If we are in the mobile app, open the game in the browser.
      if (this.$store.state.app.type === 'mobile') {
        window.parent.postMessage({
          action: 'openInBrowser',
          url,
        }, '*');
      }
    },
  },
  computed: Vuex.mapState({
    online: state => state.info.online,
    data: state => state.activeGame,
    isMobile: state => state.app.type === 'mobile',
    isIOS: state => state.app.type === 'mobile' && iOSDevice(),
    fbLink() {
      const url = `https://goldfire.me/${this.data.game}`;
      const descr = encodeURIComponent(`I'm currently playing ${this.data.title} by GoldFire Studios. Come play with me!`);
      return `https://www.facebook.com/dialog/feed?app_id=322781824406707&display=page&link=${url}&redirect_uri=http://goldfire.me&description=${descr}`;
    },
    tweetLink() {
      const url = `https://goldfire.me/${this.data.game}`;
      const descr = encodeURIComponent(`I'm currently playing ${this.data.title} by @GoldFireStudios! Play with me: `);
      return `https://twitter.com/share?text=${descr}&url=${url}&related=GoldFireStudios`;
    },
    pinLink() {
      const url = encodeURIComponent(`https://goldfire.me/${this.data.game}`);
      const descr = encodeURIComponent(`Come join me in the game ${this.data.title}!`);
      return `https://pinterest.com/pin/create/button/?url=${url}&description=${descr}`;
    },
    gramLink() {
      return `https://www.instagram.com/explore/tags/${this.data.game}`;
    },
    twitchLink() {
      return `https://www.twitch.tv/directory/game/${this.data.title}`;
    },
    youtubeLink() {
      return `https://www.youtube.com/results?search_query=${this.data.title}`;
    },
    discordLink() {
      return 'https://discord.gg/jvHGtRX';
    },
  }),
  watch: {
    online() {
      this.loading = false;
    },
  },
  beforeDestroy() {
    this.$store.commit('setOnlinePlayers', []);
    this.$store.dispatch('unsub', `setOnlineGame:${this.data.gid}`);
  },
};
</script>
