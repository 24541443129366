<template>
  <span
    :class="['username', {click: clickable}, {mod: isMod}, {dev: isDev}]"
    @click="viewProfile(player.uid)"
  >{{ player ? player.username || player.user : '' }}</span>
</template>

<script>
export default {
  name: 'username',
  props: ['player', 'isClickable', 'history'],
  computed: {
    clickable() {
      return this.isClickable !== false && this.player && this.player.uid;
    },
    isMod() {
      return this.player && this.player.role && this.player.role === 'mod';
    },
    isDev() {
      return this.player && this.player.role && this.player.role === 'dev';
    },
  },
  methods: {
    viewProfile() {
      // By default, usernames can be clicked to load a user profile.
      if (this.clickable) {
        this.$store.dispatch('viewProfile', {uid: this.player.uid, shouldAddHistory: !this.history});

        // Update the history.
        if (this.history) {
          this.history();
        }
      }
    },
  },
};
</script>
