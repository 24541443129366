<template>
  <div class="inner"></div>
</template>

<script>
export default {
  name: 'empty',
  serverCacheKey() {
    return true;
  },
};
</script>
