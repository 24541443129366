<template>
  <div class="signup">
    <div class="content">
      <h3>Tell us about yourself.</h3>
      <div class="flex justify bottom-margin-30 signup-container">
        <div class="signup-forms">
          <!-- Username -->
          <input
            type="text"
            :class="{underline: true, error: error.userLen || error.badUser || error.userTaken}"
            placeholder="Username"
            v-model="user"
            @keyup.enter="signup"
            v-focus
          >
          <div class="red input-error" v-if="error.userLen">Username must be 3-15 characters.</div>
          <div class="red input-error" v-else-if="error.badUser">Usernames must be alphanumeric.</div>
          <div class="red input-error" v-else-if="error.userTaken">Username is already taken.</div>

          <!-- Birthday -->
          <cleave
            :class="['underline', 'top-margin-15', {error: error.age}]"
            placeholder="Birthday (mm/dd/yyyy)"
            v-model="bday"
            :options="{date: true, datePattern: ['m', 'd', 'Y']}"
            @enter="signup"
          ></cleave>
          <div class="red input-error" v-if="error.age">You must be 13+ to join.</div>
        </div>

        <!-- Gender Selection -->
        <div class="gender-select">
          <div class="top-margin-10">I am...</div>
          <gender-select v-model="gender"></gender-select>
        </div>
      </div>

      <!-- Signup Button -->
      <button-loader
        value="Start Playing"
        :styles="{'color': true, 'top-margin-15': true}"
        :loading="loading"
        :click="signup"
      ></button-loader>
      <div class="red input-error" v-if="error.unknown">An unknown error occured, please try again.</div>

      <!-- Login Link -->
      <router-link to="/login" class="link top-margin-30">Already have an account?</router-link>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import is from 'is_js';
import Cleave from '../Cleave.vue';
import GenderSelect from '../GenderSelect.vue';
import ButtonLoader from '../ButtonLoader.vue';
import {readCookie, setCookie} from '../../utils';

export default {
  name: 'signup',
  components: {Cleave, GenderSelect, ButtonLoader},
  data() {
    return {
      loading: false,
      user: '',
      bday: '',
      gender: 'Male',
      error: {
        userLen: false,
        badUser: false,
        age: false,
        emailTaken: false,
        userTaken: false,
        unknown: false,
      },
    };
  },
  computed: Vuex.mapState({
    email: state => state.auth.email,
    pass: state => state.auth.pass,
  }),
  methods: {
    signup() {
      const isModal = (this.$el.offsetParent.className.match(/login-modal-container/));
      let err = this.error;

      // Reset the errors.
      err.userLen = false;
      err.badUser = false;
      err.age = false;
      err.emailTaken = false;
      err.userTaken = false;
      err.unknown = false;

      // Run the same static checks as on the server.
      if (!this.user || this.user === '' || this.user.length < 3 || this.user.length > 15) {
        err.userLen = true;
      }
      if (!/^[A-Za-z0-9_]{3,15}$/.test(this.user)) {
        err.badUser = true;
      }
      if (!is.dateString(this.bday)) {
        err.age = true;
      }

      // If there are no errors, send the request to the server.
      if (!err.userLen && !err.badUser && !err.age) {
        // Update the state of the button
        this.loading = true;

        // Send the signup request to the server.
        this.$store.dispatch('signup', {
          email: this.email.toLowerCase(),
          pass: this.pass,
          user: this.user,
          bday: this.bday,
          gender: this.gender,
          src: readCookie('src'),
        }).then((data) => {
          this.loading = false;
          Object.assign(this.error, data.errors);

          if (this.error.emailTaken) {
            this.$store.commit('setAuthData', {emailTaken: true});
            this.$router.push('/signup');
            return;
          }

          err = this.error;
          const hasErr = Object.keys(err).some(key => err[key] === true);
          if (!hasErr) {
            if (typeof this.$parent.toggleLogin === 'function' && isModal) {
              this.$parent.toggleLogin();
            } else {
              this.$router.push('/');
            }
          }

          // Clear the src cookie.
          setCookie('src', null, 0);
        });
      }
    },
  },
};
</script>