<template>
  <div class="page full flex center middle">
    <h2>You have been unsubscribed.</h2>
    <router-link to="/" class="button auto top-margin-30">Back to Games</router-link>
  </div>
</template>

<script>
export default {
  name: 'unsubscribe',
  mounted() {
    this.$store.dispatch('unsubscribeEmail', {
      sig: this.$store.state.route.params.sig,
      email: this.$store.state.route.params.email,
    });
  },
  serverCacheKey() {
    return true;
  },
};
</script>
