<template>
  <div class="container">
    <h1>Terms & Conditions</h1>
    <div style="text-align: left; margin-top: 30px;">
      <p class="strong">1. Acceptance of Terms</p>
      <p>Each time you use or cause access to this web site, you agree to be bound by these Terms & Conditions, and as amended from time to time with or without notice to you. In addition, if you are using a particular service on or through this web site, you will be subject to any rules or guidelines applicable to those services and they shall be incorporated by reference into these Terms of Service.</p>

      <p class="strong">2. Service Description</p>
      <p>GoldFire Studios (GoldFire) currently provides users with access to a rich collection of resources, including, various communications tools, forums, shopping services, and personalized content its network of properties (the "Service"). You also understand and agree that the Service may include advertisements and that these advertisements are necessary for GoldFire to provide the Service. You also understand and agree that the service may include certain communications from GoldFire, such as service announcements, administrative messages and the GoldFire Newsletter, and that these communications are considered part of GoldFire membership and you will not be able to opt out of receiving them. Unless explicitly stated otherwise, any new features that augment or enhance the current Service, including the release of new GoldFire properties, shall be subject to the TOS. You understand and agree that the Service is provided "AS-IS" and that GoldFire assumes no responsibility for the timeliness, deletion, mis-delivery or failure to store any user communications or personalization settings.</p>

      <p class="strong">3. Registration</p>
      <p>In consideration of your use of the Service, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form (such information being the "Registration Data"), (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete and (c) agree to create only one account per person. If you provide any information that is untrue, inaccurate, not current or incomplete, or GoldFire has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, GoldFire has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).</p>

      <p class="strong">4. Account Security</p>
      <p>You will receive a password and account designation upon completing the Service's registration process. You are responsible for maintaining the confidentiality of the password and account, and are fully responsible for all activities that occur under your password or account. You agree to immediately notify GoldFire of any unauthorized use of your password or account or any other breach of security. GoldFire cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 4.</p>

      <p class="strong">5. User Conduct</p>
      <p>You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages or other materials ("Content"), whether publicly posted or privately transmitted, are the sole responsibility of the person from which such Content originated. This means that you, and not GoldFire, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via the Service. GoldFire does not control the Content posted via the Service and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using the Service, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will GoldFire be liable in any way for any Content, including, but not limited to, for any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the Service.</p>
      <p>You agree to not use the Service to:</p>
      <p><strong>(a)</strong> upload, post, email, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable; (b) harm minors in any way; (c) impersonate any person or entity, including, but not limited to, a GoldFire official, forum leader, guide or host, or falsely state or otherwise misrepresent your affiliation with a person or entity;</p>
      <p><strong>(d)</strong> forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Service;</p>
      <p><strong>(e)</strong> upload, post, email, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</p>
      <p><strong>(f)</strong> upload, post, email, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights ("Rights") of any party;</p>
      <p><strong>(g)</strong> upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation, except in those areas (such as shopping rooms) that are designated for such purpose (please read our complete Spam Policy);</p>
      <p><strong>(h)</strong> upload, post, email, transmit or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
      <p><strong>(i)</strong> disrupt the normal flow of dialogue, cause a screen to "scroll" faster than other users of the Service are able to type, or otherwise act in a manner that negatively affects other users' ability to engage in real time exchanges;</p>
      <p><strong>(j)</strong> interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service;</p>
      <p><strong>(k)</strong> intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, regulations promulgated by the U.S. Securities and Exchange Commission, any rules of any national or other securities exchange, including, without limitation, the New York Stock Exchange, the American Stock Exchange or the NASDAQ, and any regulations having the force of law;</p>
      <p><strong>(l)</strong> "stalk" or otherwise harass another; or</p>
      <p><strong>(m)</strong> collect or store personal data about other users.</p>
      <p>You acknowledge that GoldFire may OR MAY NOT pre-screen Content, but that GoldFire and its designees shall have the right (but not the obligation) in their sole discretion to PRE-SCREEN, refuse, or move any Content that is available via the Service. Without limiting the foregoing, GoldFire and its designees shall have the right to remove any Content that violates the TOS or is otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you may not rely on any Content created by GoldFire or submitted to GoldFire, including without limitation information in GoldFire message boards, and in all other parts of the Service.</p>
      <p>You acknowledge, consent and agree that GoldFire may access, preserve, and disclose your account information and Content if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the TOS; (c) respond to claims that any Content violates the rights of third-parties; (d) respond to your requests for customer service; or (e) protect the rights, property, or personal safety of GoldFire, its users and the public.</p>
      <p>You understand that the technical processing and transmission of the Service, including your Content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</p>
      <p>You understand that the Service and software embodied within the Service may include security components that permit digital materials to be protected, and use of these materials is subject to usage rules set by GoldFire and/or content providers who provide content to the Service. You may not attempt to override or circumvent any of the usage rules embedded into the Service. Any unauthorized reproduction, publication, further distribution or public exhibition of the materials provided on the Service, in whole or in part, is strictly prohibited.</p>

      <p class="strong">6. Submitted Content</p>
      <p>GoldFire does not claim ownership of Content you submit or make available for inclusion on the Service. However, with respect to Content you submit or make available for inclusion on publicly accessible areas of the Service, you grant GoldFire the following world-wide, royalty free and non-exclusive license(s), as applicable:</p>
      <ul>
        <li>With respect to Content you submit or make available for inclusion on publicly accessible areas of GoldFire, the license to use, distribute, reproduce, modify, adapt, publicly perform and publicly display such Content on the Service solely for the purposes of providing and promoting the specific Service which such Content was submitted or made available. This license exists only for as long as you elect to continue to include such Content on the Service and will terminate at the time you remove or GoldFire removes such Content from the Service.</li>
        <li>With respect to photos, graphics, audio or video you submit or make available for inclusion on publicly accessible area of the Service, the license to use, distribute, reproduce, modify, adapt, publicly perform and publicly display such Content on the Service solely for the purpose for which such Content was submitted or made available. This license exists only for as long as you elect to continue to include such Content on the Service and will terminate at the time you remove or GoldFire removes such Content from the Service.</li>
        <li>With respect to Content other than photos, graphics, audio or video you submit or make available for inclusion on publicly accessible areas of the Service, the perpetual, irrevocable and fully sublicensable license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and publicly display such Content (in whole or in part) and to incorporate such Content into other works in any format or medium now known or later developed.</li>
      </ul>
      <p>"Publicly accessible" areas of the Service are those areas of GoldFire that are intended by GoldFire to be available to the general public. By way of example, publicly accessible areas of the Service would include GoldFire news and resources that are open to both members and visitors. However, publicly accessible areas of the Service would not include portions of GoldFire that are limited to members, GoldFire services intended for private communication such as private messages, or areas off of GoldFire such as portions of World Wide Web sites that are accessible through via hypertext or other links but are not hosted or served by GoldFire.</p>

      <p class="strong">7. Indemnity</p>
      <p>You agree to indemnify and hold GoldFire, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, post, transmit or make available through the Service, your use of the Service, your connection to the Service, your violation of the TOS, or your violation of any rights of another.</p>

      <p class="strong">8. No resale of Service</p>
      <p>You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of the Service (including your GoldFire I.D.), use of the Service, or access to the Service.</p>

      <p class="strong">9. Modification of Service</p>
      <p>GoldFire reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that GoldFire shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>

      <p class="strong">10. Termination</p>
      <p>You agree that GoldFire may, under certain circumstances and without prior notice, immediately terminate your GoldFire account and access to the Service. Cause for such termination shall include, but not be limited to, (a) breaches or violations of the TOS or other incorporated agreements or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) discontinuance or material modification to the Service (or any part thereof), (e) unexpected technical or security issues or problems, and (f) extended periods of inactivity. Termination of your GoldFire account includes (a) removal of access to all offerings within the Service, including but not limited to GoldFire message boards, (b) deletion of your password and all related information, files and content associated with or inside your account (or any part thereof), and (c) barring further use of the Service. Further, you agree that all terminations for cause shall be made in GoldFire' sole discretion and that GoldFire shall not be liable to you or any third-party for any termination of your account, any associated email address, or access to the Service.</p>

      <p class="strong">11. Disclaimer of Warranties</p>
      <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
      <p>1. YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. GoldFire EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
      <p>2. GoldFire MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (V) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED. </p>
      <p>3. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>
      <p>4. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM GoldFire OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS. </p>
      <p>5. A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER SCREEN OR WHILE USING THE SERVICE. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR ANYONE IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR PHYSICIAN PRIOR TO USING THE SERVICE. IMMEDIATELY DISCONTINUE USE OF THE SERVICE AND CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING THE SERVICE -- DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.</p>

      <p class="strong">12. Limitation of Liability</p>
      <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT GoldFire SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF GoldFire HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</p>

      <p class="strong">13. Violations</p>
      <p>Please report any violations of these Terms & Conditions to the&nbsp;<router-link to="/contact">contact form</router-link>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  meta: {
    title: 'Terms',
  },
  serverCacheKey() {
    return true;
  },
};
</script>
