<template>
  <transition-group name="screen-drawer" class="medal-queue full">
    <medal-popup v-if="medal" :data="medal" :key="medal._id"></medal-popup>
  </transition-group>
</template>

<script>
import Vuex from 'vuex';
import MedalPopup from './MedalPopup.vue';

export default {
  name: 'medal-popup-queue',
  components: {MedalPopup},
  computed: Vuex.mapState({
    medals: state => state.notices.filter(n => n.type === 'medal'),
    medal() {
      return this.medals[0];
    },
  }),
};
</script>
