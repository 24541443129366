<template>
  <div class="container flex center middle">
    <template v-if="!loading">
      <!-- Gender/Birthday Selection -->
      <div class="signup guest" v-if="guest">
        <div class="content">
          <h3>Complete your profile.</h3>

          <!-- Login Form -->
          <div class="top-margin-10 bottom-margin-30">
            <!-- Birthday -->
            <cleave
              :class="['underline', 'top-margin-15', {error: badAge}]"
              placeholder="Birthday (mm/dd/yyyy)"
              v-model="bday"
              :options="{date: true, datePattern: ['m', 'd', 'Y']}"
              @enter="processVerify"
              v-focus
            ></cleave>
            <div class="red input-error" v-if="badAge">You must be 13+ to join.</div>

            <!-- Gender -->
            <div class="gender-select top-margin-30">
              <gender-select v-model="gender" class="center full"></gender-select>
            </div>
          </div>

          <!-- Signup Button -->
          <button class="color top-margin-30 bottom-margin-30" @click="processVerify">Verify Account</button>
          <div class="red input-error" v-if="unknownErr">An unknown error occured, please try again.</div>
        </div>
      </div>

      <!-- Confirmation/Error Display -->
      <div class="contact-us auto-center top-margin-30" v-else>
        <h3 v-if="!error">Your account is now verified, now go have some fun!</h3>
        <h3 v-else>An unknown error occured, please try re-sending the verification.</h3>
        <router-link to="/" class="button auto top-margin-30" v-if="!error">Back Home</router-link>
        <button class="color" @click="resendVerify" v-else>Resend Verification</button>
      </div>
    </template>

    <!-- Loading Display -->
    <div key="loading" class="spinner" v-else></div>
  </div>
</template>

<script>
import is from 'is_js';
import Cleave from '../Cleave.vue';
import GenderSelect from '../GenderSelect.vue';

export default {
  name: 'verify',
  components: {Cleave, GenderSelect},
  data() {
    return {
      loading: true,
      error: false,
      guest: false,
      user: this.$route.params.user,
      key: this.$route.params.key,
      bday: '',
      gender: 'Male',
      badAge: false,
      unknownErr: false,
    };
  },
  mounted() {
    this.checkGuest();
  },
  methods: {
    checkGuest() {
      this.$store.dispatch('checkGuest', {user: this.user}).then((data) => {
        this.loading = false;
        this.guest = data.guest;

        if (!this.guest) {
          this.processVerify();
        }
      });
    },
    processVerify() {
      if (this.guest && !is.dateString(this.bday)) {
        this.badAge = true;
        return;
      }

      this.loading = true;
      this.$store.dispatch('verifyAccount', {
        user: this.user,
        key: this.key,
        bday: this.bday,
        gender: this.gender,
      }).then((data) => {
        this.loading = false;
        this.error = data.error;
        this.badAge = data.badAge;

        if (!this.error && !this.badAge) {
          this.guest = false;
        }
      });
    },
    resendVerify() {
      this.$store.dispatch('resendVerification');
      this.$router.push('/');
    },
  },
};
</script>
