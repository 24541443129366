<template>
  <div class="signup">
    <div class="content">
      <h3>Almost done, just a few more details to start playing!</h3>

      <!-- Login Form -->
      <div class="top-margin-10 bottom-margin-30">
        <!-- Username -->
        <input
          type="text"
          :class="{underline: true, 'top-margin-15': true, error: error.userLen || error.badUser || error.userTaken}"
          placeholder="Username"
          v-model="user"
          @keyup.enter="signup"
          v-focus
        >
        <div class="red input-error" v-if="error.userLen">Username must be 3-15 characters.</div>
        <div class="red input-error" v-else-if="error.badUser">Usernames must be alphanumeric.</div>
        <div class="red input-error" v-else-if="error.userTaken">Username is already taken.</div>

        <!-- E-mail -->
        <input
          type="text"
          :class="{underline: true, error: error.badEmail || error.emailTaken}"
          placeholder="E-mail Address"
          v-model="email"
          @keyup.enter="signup"
          v-if="show.email"
        >
        <div class="red input-error" v-if="error.emailTaken">This e-mail is already in use.</div>
        <div class="red input-error" v-else-if="error.badEmail">Please enter a valid e-mail.</div>

        <!-- Birthday -->
        <cleave
          :class="['underline', 'top-margin-15', {error: error.age}]"
          placeholder="Birthday (mm/dd/yyyy)"
          v-model="bday"
          :options="{date: true, datePattern: ['m', 'd', 'Y']}"
          @enter="signup"
          v-if="show.bday"
        ></cleave>
        <div class="red input-error" v-if="error.age">You must be 13+ to join.</div>

        <!-- Gender -->
        <div class="gender-select top-margin-30" v-if="show.gender">
          <gender-select v-model="gender" class="center full"></gender-select>
        </div>
      </div>

      <!-- Signup Button -->
      <button-loader
        value="Complete Signup"
        :styles="{'color': true, 'bottom-margin-30': true}"
        :loading="loading"
        :click="signup"
      ></button-loader>
      <div class="red input-error" v-if="error.unknown">An unknown error occured, please try again.</div>
    </div>
  </div>
</template>

<script>
import is from 'is_js';
import ButtonLoader from '../ButtonLoader.vue';
import Cleave from '../Cleave.vue';
import GenderSelect from '../GenderSelect.vue';
import {readCookie} from '../../utils';

export default {
  name: 'social-signup',
  components: {ButtonLoader, Cleave, GenderSelect},
  data() {
    return {
      id: this.$store.state.route.query.id,
      type: this.$store.state.route.query.type,
      email: this.$store.state.route.query.email,
      gender: this.$store.state.route.query.gender,
      bday: this.$store.state.route.query.bday,
      user: '',
      error: {
        userLen: false,
        badUser: false,
        age: false,
        badEmail: false,
        emailTaken: false,
        userTaken: false,
        unknown: false,
      },
      loading: false,
      show: {
        email: !this.$store.state.route.query.email,
        bday: !this.$store.state.route.query.bday,
        gender: !this.$store.state.route.query.gender,
      },
    };
  },
  methods: {
    signup() {
      const err = this.error;

      // Reset the errors.
      err.userLen = false;
      err.badUser = false;
      err.age = false;
      err.badEmail = false;
      err.emailTaken = false;
      err.userTaken = false;
      err.unknown = false;

      // Run the same static checks as on the server.
      if (!this.user || this.user === '' || this.user.length < 3 || this.user.length > 15) {
        err.userLen = true;
      }
      if (!/^[A-Za-z0-9_]{3,15}$/.test(this.user)) {
        err.badUser = true;
      }
      if (!is.dateString(this.bday)) {
        err.age = true;
      }

      // If there are no errors, send the request to the server.
      let hasErr = Object.keys(err).some(key => err[key] === true);
      if (!hasErr) {
        // Update the state of the button
        this.loading = true;

        // Send the signup request to the server.
        this.$store.dispatch('signup', {
          email: this.email.toLowerCase(),
          pass: this.pass,
          user: this.user,
          bday: this.bday,
          gender: this.gender,
          [this.type]: this.id,
        }).then((data) => {
          this.loading = false;
          Object.assign(this.error, data.errors);

          hasErr = Object.keys(this.error).some(key => this.error[key] === true);
          if (!hasErr) {
            const loginRoute = readCookie('loginRoute');
            this.$router.push(loginRoute ? `${loginRoute}?rel=social` : '/');

            // Clear the route cookie.
            if (loginRoute) {
              document.cookie = 'loginRoute=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
          }
        });
      }
    },
  },
};
</script>
