<template>
  <span class="sub-value">{{ game }}</span>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'active-game',
  props: ['uid', 'gid'],
  computed: Vuex.mapState({
    game(state) {
      const status = state.status.players[this.uid];
      const gid = (status ? status.game || this.gid : this.gid) || 0;
      return state.gameData.find(game => game.gid === gid).title;
    },
  }),
};
</script>
