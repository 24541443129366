<template>
  <img :src="medal.src" v-tooltip="medal.title">
</template>

<script>
export default {
  name: 'medal-image',
  props: ['medal'],
  serverCacheKey(props) {
    return `${props.medal.src}::${props.medal.title}`;
  },
};
</script>
