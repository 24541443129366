<template>
  <div class="content filter-tags">
    <div class="scroll">
      <div v-for="tag in tags" 
        :class="['box','tag-filter', 'click', tag.selected ? 'selected' : '']" 
        @click="filter ? toggleFilter(tag.name) : toggleSelected(tag.name)">
        <span>{{tag.name}}</span>
        <div :class="['tag-filter-box', tag.color]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'forum-tags-view',
  mounted() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps = new PerfectScrollbar(this.$el.querySelector('.scroll'), {suppressScrollX: true});
    }
  },
  methods: Vuex.mapMutations({
    toggleFilter: 'toggleForumFilterTag',
    toggleSelected: 'toggleForumSelectTag',
  }),
  computed: Vuex.mapState({
    filter: state => state.forums.tags.filter,
    tags: (state) => {
      const tags = state.forums.categories
        .find(category => category.gid === state.forums.currentCategory)
        .tags;
      const selectedTags = state.forums.tags.filter ? state.forums.filteredTags : state.editor.selectedTags;
      const tagNames = Object.keys(tags);

      return tagNames.map(t => ({
        name: t,
        color: tags[t],
        selected: selectedTags.indexOf(t) !== -1,
      }));
    },
  }),
  beforeDestroy() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps.destroy();
    }
    this.$store.commit('setSelectedEditorTags', []);
  },
};
</script>
