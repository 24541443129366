<template>
  <div class="content">
    <div class="box click" @click="viewNotifications(0)">
      View Other Notifications
      <div class="counter">{{ netNotices }}</div>
    </div>

    <games-list :games="games" context="launcher"></games-list>
  </div>
</template>

<script>
import Vuex from 'vuex';
import GamesList from './GamesList.vue';

export default {
  name: 'launcher-view',
  components: {GamesList},
  mounted() {
    const buttons = [
      {label: 'Settings', view: 'settings-view'},
      {label: 'Profile', view: {action: 'viewProfile', data: {uid: this.uid}}},
    ];

    this.$store.commit('setPanelData', {
      title: 'YOUR GAMES',
      buttons,
    });

    if (this.games.length === 0) {
      this.$store.commit('togglePanelLoading', true);
    } else {
      this.$store.dispatch('refreshLauncherRankings');
    }
  },
  computed: Vuex.mapState({
    games: state => state.launcher.games.slice(1), // remove General/Goldfire
    uid: state => state.user.uid,
    netNotices: (state) => {
      return state.launcher.games[0] ? state.launcher.games[0].notifications : 0;
    },
  }),
  watch: {
    games(val) {
      if (val.length > 0) {
        this.$store.commit('togglePanelLoading', false);
      }
    },
  },
  methods: {
    viewNotifications(game) {
      this.$store.commit('setNotificationsId', game);
      this.$store.commit('changeView', 'notifications-view');
    },
  },
};
</script>
