<template>
  <div class="content full-height flex column center middle">
    <div class="icon pending bottom-margin-30"></div>
    <span class="strong normal bottom-margin-5">Pending</span>
    <span class="subtext medium light text-center">Your purchase is pending. We'll e-mail you when it is complete.</span>
  </div>
</template>

<script>
export default {
  name: 'shop-pending-view',
  mounted() {
    this.$store.commit('setHistory', []);
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [{label: 'Back to Store', view: 'shop-view'}],
    });
  },
};
</script>
