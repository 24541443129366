<template>
  <div :class="['box', 'post', {color: post.unread}]" :id="`id-${post._id}`">
    <a v-if="renderAnchor" :name="`id-${post._id}`"></a>
    <avatar :player="post.player" :history="history"></avatar>
    <div class="text full">
      <div class="user">
        <username :player="post.player" :history="history"></username>
      </div>
      <live-time class="time click right" :timestamp="post.t" hover-text="COPY LINK"></live-time>
      <div class="bottom-margin-30" v-html="message"></div>

      <span :class="['full', {'first-post': isMod && isThreadFirst}]">
        <!-- Main Icons -->
        <span class="left flex">
          <span class="text-counter medium regular right-margin-15" @click="reply" v-if="!isLocked && uid && !banned">
            <span class="icon comments right-margin-5"></span> Reply
          </span>
          <span class="text-counter medium regular right-margin-15" @click="quote" v-if="!isLocked && uid && !banned">
            <span class="icon quote right-margin-5"></span> Quote
          </span>
          <span v-if="post.views" class="text-counter medium regular">
            <span class="icon views right-margin-5"></span> {{views}}
          </span>
        </span>

        <span class="right flex" v-if="uid">
          <span
            :class="['text-counter', 'medium', 'regular', 'left-margin-15']"
            @click="edit"
            v-if="isOwnPost"
          >
            <span class="icon edit"></span>
          </span>
          <!-- Mod Icons -->
          <span class="flex row" v-if="isMod">
            <span class="text-counter medium regular left-margin-15" @click="status('sticky')" v-if="isThreadFirst">
              <span class="icon pin dark"></span>
            </span>
            <span
              :class="['text-counter', 'medium', 'regular', 'left-margin-15']"
              @click="status('locked')"
              v-if="isThreadFirst"
            >
              <span class="icon key dark"></span>
            </span>
            <delete-icon :remove="remove"></delete-icon>
          </span>

          <!-- Flag Icon -->
          <flag-icon v-if="!isMod"></flag-icon>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import Avatar from './Avatar.vue';
import Username from './Username.vue';
import DeleteIcon from './DeleteIcon.vue';
import FlagIcon from './FlagIcon.vue';
import {numberWithCommas, linkTargetBlank} from '../utils';

let ClipboardJS;
if (typeof window !== 'undefined') {
  ClipboardJS = require('clipboard');
}

export default {
  name: 'forum-thread-post',
  components: {LiveTime, Avatar, Username, DeleteIcon, FlagIcon},
  props: ['post', 'isThreadFirst'],
  mounted() {
    this.setupClipboard();

    // Update the links.
    linkTargetBlank(this.$el);
  },
  methods: {
    setupClipboard() {
      // Setup the handler to copy the post link to clipboard.
      const {activeThread} = this.$store.state.forums;
      if (typeof Clipboard !== 'undefined' && activeThread.title) {
        const title = activeThread.title.toLowerCase().replace(/'/, '').replace(/[^a-z0-9]/gi, '-');

        this.$nextTick(() => {
          let text = `https://goldfire.me/forums/thread/${activeThread.id}/${title}`;
          if (!this.post.views) {
            text += `/post/${this.post._id}`;
          }
          this.clipboard = new ClipboardJS(`#id-${this.post._id} .time`, {
            text: () => text,
          });
        });
      }
    },
    reply() {
      this.$store.commit('toggleEditor', {type: 'reply'});
      this.$store.commit('setEditorInitialValue', `@${this.post.player.username}&nbsp;`);
    },
    quote() {
      this.$store.commit('toggleEditor', {type: 'reply'});
      this.$store.commit('setEditorInitialValue', `<pre><b>${this.post.player.username} said:</b><br/>${this.post.message}</pre><br/><br/>`);
    },
    edit() {
      this.$store.commit('toggleEditor', {type: 'edit', pid: this.post._id});
      this.$store.commit('setEditorInitialValue', `${this.post.message}`);
    },
    status(type) {
      this.$store.dispatch('toggleForumThreadStatus', {tid: this.$parent.tid, type})
        .then(() => {
          this.$store.dispatch('goBackView');
        });
    },
    remove() {
      this.$store.dispatch('deleteForumPost', {
        id: this.post._id,
        player: {uid: this.post.player.uid},
      });
    },
    history() {
      this.$store.commit('addHistory', {
        action: 'viewForumThread',
        data: {_id: this.$store.state.forums.activeThread.id},
      });
    },
  },
  computed: Vuex.mapState({
    uid: state => state.user.uid,
    views() {
      return numberWithCommas(this.post.views);
    },
    message() {
      // Format the mentions.
      let message = this.post.message.replace(/@(.+?)\b/g, '<span class="mention">@$1</span>');

      // Format the images.
      const imgRegex = /<img src="(.+?)\?a=(.*?)" class="image">|<img class="image" src="(.+?)\?a=(.*?)">/g;
      const imgReplace = `
        <div class="relative" style="padding-top: $2%;">
          <a href="$1" target="_blank"><img src="$1" class="image"></a>
        </div>
      `;
      message = message.replace(imgRegex, imgReplace);

      return message;
    },
    isMod: state => state.user.role,
    isOwnPost() {
      return this.post.player.uid === this.uid;
    },
    renderAnchor: state => !state.user.auth,
    isLocked: state => state.forums.activeThread.locked,
    banned: state => state.user.forumban,
  }),
  updated() {
    // Update the clipboard.
    if (this.$store.state.forums.activeThread.id) {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
      this.setupClipboard();
    }

    // Update the links.
    linkTargetBlank(this.$el);
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
};
</script>
