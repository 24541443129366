<template>
  <div class="signup">
    <div class="content">
      <h3>You should receive an e-mail with a reset link shortly.</h3>

      <div class="flex center top-margin-30">
        <router-link to="/" class="button color half right-margin-5">Back Home</router-link>
        <router-link to="/login" class="button color half left-margin-5">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reset-confirmation',
  serverCacheKey() {
    return true;
  },
};
</script>
