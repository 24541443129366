<template>
  <div class="content">
    <div v-if="error" class="box center">
      Sorry, we couldn't find that user.
    </div>

    <!-- Don't display component until we have received the profile data -->
    <div v-if="profile.username" class="profile main">
      <!-- Profile Info -->
      <div class="box">
        <img :src="avatar" class="avatar large right-margin-10">
        <div class="profile demographics relative full">
          <!-- Moderation Tools -->
          <span
            :class="['icon', 'ban', {highlight: profile.forumban}, 'click', 'absolute-top-right']"
            @click="toggleForumBan"
            v-if="isMod"
          ></span>

          <!-- Player Info -->
          <span>
            <status :uid="uid"></status>
            <username :player="profile" :isClickable="false"></username>
          </span>
          <div class="text small demographics">
            <span><span class="icon right-margin-5 date"></span>Joined {{ joinDate }}</span>
            <span><span class="icon right-margin-5 location"></span>{{ profile.country }}</span>
            <span v-if="profile.gender"><span class="icon right-margin-5 gender"></span>{{ profile.gender }}</span>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <span class="button-bar">
        <div v-for="tab in tabs" @click="selectProfileTab(tab.component)" :class="['button', 'flex', 'column', 'center', 'middle', {active: activeTab === tab.component}]">
          <span class="text-counter extra-large">
            {{ tab.count }}
          </span>
          <span class="semi-strong">
            {{ tab.name }}
          </span>
        </div>
      </span>
    </div>

    <!-- Profile Content -->
    <social-feed :user="profile.uid" context="profile" v-if="activeTab === 'social-feed'"></social-feed>
    <games-list :games="games" :user="profile" context="profile" v-if="activeTab === 'games-list'"></games-list>
    <all-medals :games="games" v-if="activeTab === 'all-medals'"></all-medals>
    <friends :friends="friends" space-between="15" v-if="activeTab === 'friends'"></friends>
  </div>
</template>

<script>
import Vuex from 'vuex';
import SocialFeed from './SocialFeed.vue';
import GamesList from './GamesList.vue';
import AllMedals from './AllMedals.vue';
import Friends from './Friends.vue';
import Status from './Status.vue';
import Username from './Username.vue';
import {numberWithCommas, formatTimestamp, avatarUrl} from '../utils';

export default {
  name: 'profile-view',
  components: {SocialFeed, GamesList, AllMedals, Friends, Status, Username},
  computed: Vuex.mapState({
    error: state => state.launcher.profile.error,
    isMod: state => state.user.role,
    profile: state => state.launcher.profile,
    uid: state => state.launcher.profile.uid,
    activeTab: state => state.launcher.profile.activeTab,
    avatar: state => avatarUrl(state.launcher.profile.avatar),
    joinDate: state => formatTimestamp(state.launcher.profile.joinDate, true),
    friends: (state) => {
      // Keep status up to date and resort when status changes.
      return (state.launcher.profile.friends || [])
        .map((friend) => {
          const status = state.status.players[friend.uid];
          friend.status = status ? status.status : 'off';
          return friend;
        })
        .sort((a, b) => {
          const status = (() => {
            const aIsOn = a.status === 'on';
            const bIsOn = b.status === 'on';

            if ((aIsOn && bIsOn) || (!aIsOn && !bIsOn)) {
              return 0;
            } else if (aIsOn) {
              return -1;
            }

            return 1;
          })();

          return status || new Date(b.t) - new Date(a.t);
        });
    },
    games(state) {
      return (state.launcher.profile.games || []);
    },
    tabs(state) {
      return [
        {
          name: 'Feed',
          count: numberWithCommas(state.launcher.profile.updateCount),
          component: 'social-feed',
        },
        {
          name: 'Games',
          count: numberWithCommas(this.games.length),
          component: 'games-list',
        },
        {
          name: 'Medals',
          count: numberWithCommas(this.games.reduce((medalCount, game) => medalCount + game.earnedMedals.length, 0)),
          component: 'all-medals',
        },
        {
          name: 'Friends',
          count: numberWithCommas(this.friends.length),
          component: 'friends',
        },
      ];
    },
  }),
  watch: {
    uid(newVal, oldVal) {
      this.unsubscribeAll(oldVal);

      if (!newVal) {
        return;
      }

      this.$store.dispatch('sub', `playerStatus:${newVal}`);
      this.$store.dispatch('sub', `socialFeedCounterProfile:${newVal}`);
      this.$store.dispatch('sub', `gameAdded:${newVal}`);
      this.$store.dispatch('sub', `friendAdded:${newVal}`);
      this.$store.dispatch('sub', `friendRemoved:${newVal}`);
      this.$store.dispatch('sub', `medalEarned:${newVal}`);
    },
  },
  methods: {
    selectProfileTab(tabName) {
      this.$store.commit('selectProfileTab', tabName);
    },
    toggleForumBan() {
      this.$store.dispatch('toggleForumBan', this.uid);
    },
    unsubscribeAll(uid) {
      if (!uid) {
        return;
      }

      this.$store.dispatch('unsub', `playerStatus:${uid}`);
      this.$store.dispatch('unsub', `socialFeedCounterProfile:${uid}`);
      this.$store.dispatch('unsub', `gameAdded:${uid}`);
      this.$store.dispatch('unsub', `friendAdded:${uid}`);
      this.$store.dispatch('unsub', `friendRemoved:${uid}`);
      this.$store.dispatch('unsub', `medalEarned:${uid}`);
    },
  },
  beforeDestroy() {
    this.unsubscribeAll(this.uid);
    this.$store.commit('setProfileData', {});
  },
};
</script>
