<template>
  <div class="content">
    <div v-for="category in categories" :class="['box', 'click']" @click="viewLeaderboard(category)">
      <span>{{ category.name }}</span>
      <span :class="[{color: category.rank}, 'text-counter', 'small', 'bold']">{{ category.formattedRank }}</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {numberWithCommas} from '../utils';

export default {
  name: 'leaderboards-view',
  beforeMount() {
    if (!this.$store.state.leaderboards.gid) {
      this.$store.commit('setLeaderboardGame', {
        gid: this.$store.state.activeGame.gid,
        uid: this.$store.state.user.uid,
      });
    }
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'LEADERBOARDS',
      buttons: this.uid === this.$store.state.user.uid ? [{
        label: 'Medals',
        view: 'medals-view',
      }] : [],
    });

    this.$store.dispatch('loadLeaderboardCategories', {uid: this.uid, gid: this.gid});
  },
  computed: Vuex.mapState({
    uid: state => state.leaderboards.uid,
    gid: state => state.leaderboards.gid,
    categories(state) {
      return state.leaderboards.categories.map((category) => {
        const formattedRank = category.rank ? `#${numberWithCommas(category.rank)}` : 'N/A';
        return Object.assign({formattedRank}, category);
      });
    },
  }),
  methods: {
    viewLeaderboard(category) {
      this.$store.commit('viewLeaderboard', category);
    },
  },
};
</script>
