<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import ChatListView from './ChatListView.vue';
import ChatMessageView from './ChatMessageView.vue';
import ChatSettingsView from './ChatSettingsView.vue';
import FriendsView from './FriendsView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'chat',
  components: {
    PanelContent,
    ChatListView,
    ChatMessageView,
    ChatSettingsView,
    FriendsView,
    EmptyView,
  },
  computed: Vuex.mapState({
    activeView: state => state.chat.activeView,
  }),
};
</script>
