<template>
  <div class="box post comment">
    <avatar :player="comment.player" class="small left-margin-10"></avatar>
    <div class="text full">
      <div class="user">
        <username :player="comment.player"></username>
      </div>
      <live-time class="time right" :timestamp="comment.t"></live-time> 
      <span>{{comment.msg}}</span>
      <span class="right">
        <delete-icon v-if="canDelete" :remove="remove"></delete-icon>
      </span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import Avatar from './Avatar.vue';
import Username from './Username.vue';
import DeleteIcon from './DeleteIcon.vue';

export default {
  name: 'social-feed-comment',
  components: {LiveTime, Avatar, Username, DeleteIcon},
  props: ['update', 'comment'],
  computed: Vuex.mapState({
    canDelete(state) {
      const isModerator = state.user.role;
      const isOwnPost = state.user.uid === this.comment.player.uid;

      return isModerator || isOwnPost;
    },
  }),
  methods: {
    remove() {
      this.$store.dispatch('deleteSocialFeedComment', {
        id: this.update._id,
        uuid: this.update.player.uid,
        cuid: this.comment.player.uid,
        t: this.comment.t,
      });
    },
  },
};
</script>
