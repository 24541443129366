<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import LeaderboardsView from './LeaderboardsView.vue';
import LeaderboardView from './LeaderboardView.vue';
import MedalsView from './MedalsView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'leaderboards',
  components: {PanelContent, LeaderboardsView, LeaderboardView, MedalsView, EmptyView},
  computed: Vuex.mapState({
    activeView: state => state.leaderboards.activeView,
  }),
  beforeDestroy() {
    this.$store.commit('setLeaderboardGame', {gid: 0});
  },
};
</script>
