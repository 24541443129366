<template>
  <div>
    <span v-html="styles"></span>
    <div class="info-box">
      <span class="box">
        {{game.name}}
        <span class="counter">{{earnedMedalCount}} / {{game.medalCount}}</span>
      </span>
      <div class="info medals">
        <medal-image v-for="medal in displayedMedals" :medal="medal" :key="medal.src"></medal-image>
        <div class="clickable flex center" v-if="!isExpanded" @click="expandMedals">
          <span class="text-counter large light color flex center">+{{hiddenMedalCount}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedalImage from './MedalImage.vue';
import {medalUrl} from '../utils';

export default {
  name: 'game-medals',
  components: {MedalImage},
  props: ['game'],
  data() {
    return {
      isExpanded: false,
      medalsPerRow: 0,
      styles: '',
    };
  },
  mounted() {
    this.sizeMedals();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.sizeMedals);
    }

    // if we have fewer than what would be the collapsed medals (plus the expand button),
    // don't bother collapsing to begin with
    if (this.earnedMedalCount <= this.collapsedMedalCount + 1) {
      this.isExpanded = true;
    }
  },
  computed: {
    medals() {
      return this.game.earnedMedals.map((medal) => {
        return {
          title: medal.title,
          desc: medal.desc,
          secret: medal.secret,
          src: medalUrl(medal.gid, medal.img),
        };
      });
    },
    /**
     * Number of medals to display when collapsed.
     * Limit to three rows.
     * If over three, remove last medal in third row
     * (to be replaced with a div with count of hidden medals.)
     */
    collapsedMedalCount() {
      return (this.medalsPerRow * 3) - 1;
    },
    earnedMedalCount() {
      return this.game.earnedMedals.length;
    },
    displayedMedalCount() {
      return this.isExpanded
        ? this.earnedMedalCount
        : this.collapsedMedalCount;
    },
    hiddenMedalCount() {
      return this.isExpanded
        ? 0
        : this.game.earnedMedals.length - this.displayedMedalCount;
    },
    displayedMedals() {
      if (this.isExpanded) {
        // no need to truncate list
        return this.medals;
      }
      return this.medals.slice(0, this.displayedMedalCount);
    },
  },
  methods: {
    expandMedals() {
      this.isExpanded = true;
    },
    sizeMedals() {
      const medalWidth = 80;
      const medalsWidth = this.$el.querySelector('.medals').offsetWidth || 335;
      // ceil: always size images down rather than up for better image quality
      this.medalsPerRow = Math.ceil(medalsWidth / medalWidth);
      const width = medalsWidth / this.medalsPerRow;
      this.styles = `<style>.medals * {width: ${width}px; height: ${width}px}</style>`;
    },
  },
  beforeDestroy() {
    if (window) {
      window.removeEventListener('resize', this.sizeMedals);
    }
  },
};
</script>
