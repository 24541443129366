<template>
  <span>
    <!-- New Discussion Text Editor -->
    <transition name="drawer">
      <text-editor v-show="showEditor"></text-editor>
    </transition>

    <!-- Tags Selection -->
    <transition name="drawer">
      <forum-tag-view v-show="showTags"></forum-tag-view>
    </transition>
  </span>
</template>

<script>
import Vuex from 'vuex';
import TextEditor from './TextEditor.vue';
import ForumTagView from './ForumTagsView.vue';

export default {
  name: 'forum-threads-list-footer',
  components: {TextEditor, ForumTagView},
  computed: Vuex.mapState({
    showEditor: state => state.editor.show,
    showTags: state => state.forums.tags.show,
  }),
  beforeDestroy() {
    if (this.showEditor) {
      this.$store.commit('toggleEditor', {type: null});
    }
  },
};
</script>
