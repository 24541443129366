<template>
  <div :class="['content', {'no-padding': context === 'profile'}]">
    <div v-for="update in updates" class="inf" :id="`id-${update._id}`">
      <social-feed-update :update="update"></social-feed-update>
      <social-feed-comments :update="update"></social-feed-comments>
    </div>
    <div v-if="context === 'profile' && updates.length === 0 && !isLoading" class="box center">
      This user hasn't posted any updates yet.
    </div>
    <div v-else-if="context !== 'profile' && updates.length === 0 && !isLoading" class="box center">
      This area will show the feed of posts from yourself and anyone you are friends with.
    </div>

    <!-- If we are on the route logged out, show them a login button -->
    <router-link to="/login" class="button top-margin-15" v-if="!auth && updates.length > 0">Login to View All</router-link>
  </div>
</template>

<script>
import Vuex from 'vuex';
import SocialFeedComments from './SocialFeedComments.vue';
import SocialFeedUpdate from './SocialFeedUpdate.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'social-feed',
  components: {SocialFeedUpdate, SocialFeedComments},
  mixins: [infiniteScrolling],
  props: ['user', 'context'],
  mounted() {
    if (this.tab === 'forums') {
      this.$store.commit('setPanelData', {
        title: 'SOCIAL FEED',
        buttons: [{label: 'New Post', view: {mutation: 'toggleEditor', data: {type: 'feed'}}}],
        footerView: 'social-feed-footer',
      });
    }

    this.$store.dispatch('sub', `socialFeedUpdatePosted:${this.uid.toString()}`);
    this.$store.dispatch('sub', `socialFeedUpdateRemoved:${this.uid.toString()}`);
    this.$store.dispatch('sub', `socialFeedCommentRemoved:${this.uid.toString()}`);

    // Only load the social feed if we aren't loading into a profile route.
    if (this.auth || (!this.auth && !this.$store.state.socialFeed.updates.length)) {
      this.load();
    }
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    infiniteScrollingCfg(state) {
      return {
        actionParams: {id: this.user},
        actionName: 'getSocialFeed',
        mutationName: 'setSocialFeedUpdates',
        items: state.socialFeed.updates,
        keys: ['_id'],
      };
    },
    tab: state => state.toolbar.activeTab,
    updates: state => state.socialFeed.updates,
    uid: state => state.user.uid,
    isLoading: state => state.panel.loading,
  }),
  beforeDestroy() {
    this.$store.commit('setSocialFeedUpdates', {items: [], concat: false});
    this.$store.dispatch('unsub', `socialFeedUpdatePosted:${this.uid.toString()}`);
    this.$store.dispatch('unsub', `socialFeedUpdateRemoved:${this.uid.toString()}`);
    this.$store.dispatch('unsub', `socialFeedCommentRemoved:${this.uid.toString()}`);

    const likeChannels = [];
    const commentChannels = [];
    this.updates.forEach((u) => {
      likeChannels.push(`toggleLike:${u._id}`);
      commentChannels.push(`updateCommented:${u._id}`);
    });

    this.$store.dispatch('bulkUnsub', likeChannels);
    this.$store.dispatch('bulkUnsub', commentChannels);
  },
};
</script>
