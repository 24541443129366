<template>
  <div class="info-box fill emojis">
    <!-- Top Bar -->
    <span class="box extra-padding">
      <span class="icons left subtext">
        Embed Media
      </span>
      <span class="icon close dark right" @click="closeMedia"></span>
    </span>

    <!-- Different Media Type Views -->
    <component :is="mediaType" v-if="mediaType !== ''"></component>
    <template v-else>
      <div id="media" class="info">
        <span class="button light small third tall flex column center middle" @click="viewMediaType('link')">
          <span class="icon link"></span>
          Link
        </span>
        <span class="button light small third tall flex column center middle" @click="viewMediaType('image')">
          <span class="icon image"></span>
          Image
        </span>
        <span class="button light small third tall flex column center middle" @click="viewMediaType('video')">
          <span class="icon video"></span>
          Video
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import MediaKeyboardLink from './MediaKeyboardLink.vue';
import MediaKeyboardImage from './MediaKeyboardImage.vue';
import MediaKeyboardVideo from './MediaKeyboardVideo.vue';

export default {
  name: 'media-keyboard',
  components: {MediaKeyboardLink, MediaKeyboardImage, MediaKeyboardVideo},
  data() {
    return {
      mediaType: '',
    };
  },
  methods: {
    closeMedia() {
      this.$store.commit('toggleEditorMedia');
    },
    viewMediaType(type) {
      this.mediaType = `media-keyboard-${type}`;
    },
  },
};
</script>
