<template>
  <live-time :timestamp="timestamp" v-if="status !== 'on'"></live-time>
  <span v-else>now</span>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';

export default {
  name: 'status-live-time',
  components: {LiveTime},
  props: ['uid'],
  computed: Vuex.mapState({
    status(state) {
      const status = state.status.players[this.uid];

      return status && !status.away ? status.status : 'off';
    },
    timestamp(state) {
      const status = state.status.players[this.uid];

      return status ? status.t : Date.now();
    },
  }),
};
</script>
