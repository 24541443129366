<template>
  <div class="container forums">
    <div class="text-center bottom-margin-30 strong"><h3>Forums</h3></div>
    <forums-view v-if="!$store.state.user.auth"></forums-view>
  </div>
</template>

<script>
import ForumsView from '../ForumsView.vue';

export default {
  name: 'forums',
  components: {ForumsView},
  mounted() {
    if (this.$store.state.user.auth) {
      this.$store.commit('toggleTab', 'forums');
      this.$router.replace('/');
    }
  },
};
</script>
