<template>
  <div id="media" class="info column center middle">
    <div class="scroll">
      <input class="light bottom-margin-10" placeholder="Question" v-model="q" v-focus>
      <input
        class="light bottom-margin-10"
        v-for="(answer, index) in answers"
        :placeholder="`Answer #${index + 1}`"
        v-model="a[index]"
      >
      <button class="small outline" @click="answers++">Add Another Answer</button>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'poll-keyboard',
  data() {
    return {
      answers: 3,
      q: '',
      a: [],
    };
  },
  watch: {
    answers() {
      const el = this.$el.querySelector('.scroll');
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight - el.offsetHeight;
        if (this.$store.state.app.type !== 'mobile') {
          this.ps.update();
        }
      });
    },
    q() {
      this.$store.commit('setEditorPoll', {q: this.q, a: this.a});
    },
    a() {
      this.$store.commit('setEditorPoll', {q: this.q, a: this.a});
    },
  },
  mounted() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps = new PerfectScrollbar(this.$el.querySelector('.scroll'), {suppressScrollX: true});
    }
  },
  beforeDestroy() {
    if (this.$store.state.app.type !== 'mobile') {
      this.ps.destroy();
    }
  },
};
</script>
