<template>
  <div>
    <div v-if="friends.length === 0 && !isLoading && !parentLoading" class="box center">
      This user hasn't added any friends yet.
    </div>
    <div class="friends">
      <span v-html="styles" style="display: none"></span>
      <avatar v-for="friend in displayedFriends" :player="friend" :showTooltip="true" :key="friend.uid"></avatar>
      <img v-for="phantomFriend in numPhantomFriends" class="avatar hidden click">
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import Avatar from './Avatar.vue';
import {getNumPhantomElements} from '../utils';

export default {
  name: 'friends',
  components: {Avatar},
  props: ['friends', 'spaceBetween', 'parentLoading'],
  data() {
    return {
      styles: '',
      numPhantomFriends: 0,
      elementWidth: 40,
    };
  },
  mounted() {
    this.spaceElements();
    this.$store.dispatch('bulkSub', this.friends.map(friend => `playerStatus:${friend.uid}`));

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.spaceElements);
    }
  },
  computed: Vuex.mapState({
    displayedFriends() {
      return this.friends;
    },
    isLoading: state => state.panel.loading,
    containerWidth() {
      if (!this.$el) {
        return 365;
      }
      return this.$el.offsetWidth || 365;
    },
    // TODO: We should probably get this setup with the infinite scrolling like with friends list in chat.
    //
    // infiniteScrollingCfg() {
    //   return {
    //     minRowHeight: !this.$store.state.user.auth ? 1 : 55,
    //     itemsPerRow: Math.floor(this.containerWidth / (this.elementWidth + Number(this.spaceBetween))),
    //   };
    // },
  }),
  methods: {
    spaceElements() {
      this.styles = `
        <style>
          .friends img {
            margin: ${this.spaceBetween / 2}px;
          }
          .friends {
            margin: 0 -${this.spaceBetween / 2}px;
          }
        </style>
      `;

      this.numPhantomFriends = getNumPhantomElements({
        numElements: this.displayedFriends.length,
        elementWidth: this.elementWidth,
        spaceBetween: Number(this.spaceBetween),
        containerWidth: this.containerWidth,
      });
    },
  },
  watch: {
    displayedFriends() {
      this.spaceElements();
    },
  },
  beforeDestroy() {
    this.$store.dispatch('bulkUnsub', this.friends
      .map(friend => `playerStatus:${friend.uid}`));

    if (window) {
      window.removeEventListener('resize', this.spaceElements);
    }
  },
};
</script>
