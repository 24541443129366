<template>
  <div class="grow-shrink" :style="style">
    <!-- Show message if ignored. -->
    <div class="box center bottom" v-if="!showEditor && ignore">
      Unable to message this player.
    </div>

    <!-- "Fake Input" to trigger editor -->
    <transition name="fade">
      <div class="box input bottom" v-if="!showEditor && !ignore">
        <input placeholder="Your message..." @focus="toggleEditor">
      </div>
    </transition>

    <!-- Text Editor -->
    <transition
      name="drawer"
      @before-enter="beforeEnter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
    >
      <text-editor v-show="showEditor" />
    </transition>
  </div>
</template>

<script>
import Vuex from 'vuex';
import TextEditor from './TextEditor.vue';

export default {
  name: 'chat-message-footer',
  components: {TextEditor},
  data() {
    return {
      height: 40,
      minHeight: 40,
      anim: false,
      scrollArea: this.$parent.$el ? this.$parent.$el.querySelector('.scroll-area') : null,
    };
  },
  methods: {
    toggleEditor() {
      this.$store.commit('toggleEditor', {type: 'chat'});
    },
    beforeEnter() {
      this.$nextTick(() => {
        this.height = this.$el.querySelector('.editor').offsetHeight + 60;
        this.anim = true;
        this.scrollTop();
      });
    },
    scrollTop() {
      requestAnimationFrame(() => {
        if (this.scrollArea) {
          this.$store.commit('scrollToBottom');
        }

        if (this.anim) {
          this.scrollTop();
        }
      });
    },
    afterEnter() {
      this.anim = false;
    },
    beforeLeave() {
      this.height = 40;
    },
  },
  computed: Vuex.mapState({
    ignore: state => state.chat.activeMessage.ignore,
    showEditor: state => state.editor.show,
    style() {
      return `height: ${this.height}px`;
    },
  }),
  beforeDestroy() {
    if (this.showEditor) {
      this.$store.commit('toggleEditor', {type: null});
    }
  },
};
</script>
