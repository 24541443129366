<template>
  <div :id="`id-${transaction._id}`" :class="['box', 'inf', 'notice', 'color', 'success', transaction.pending ? 'inactive' : '']">
    <span class="icon product right-margin-10" :style="shopIcon"></span>
    <div class="text">
      <div class="user">
        <span class="title">{{transaction.long_title}}</span>
      </div>
      <live-time class="time right" :timestamp="transaction.time"></live-time>
      <span class="subtext">{{transaction.paymentMethod}} - ${{transaction.price}}</span>
      <span v-if="transaction.pending" class="bottom-right">PENDING</span>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';

export default {
  name: 'shop-history-box',
  components: {LiveTime},
  props: ['transaction'],
  computed: Vuex.mapState({
    gift: state => state.shop.gift,
    game(state) {
      return state.gameData.find(g => g.gid === this.transaction.gid).game;
    },
    shopIcon() {
      let baseUrl = '/images/games/shop/';
      if (process.env.NODE_ENV === 'development') {
        baseUrl = '/src/assets/images/games/shop/';
      }

      // Show the default old Gold icon if there is no icon.
      let icon = `${this.game}-${this.transaction.icon}`;
      if (!this.transaction.icon || this.transaction.icon === '') {
        icon = 'gold.png';
      }

      return `background-image: url(${baseUrl}${icon})`;
    },
  }),
};
</script>
