<template>
  <div class="content">
    <!-- Product -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Loading/Errors -->
    <div class="spinner auto-center" v-if="loading"></div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ShopProductBox from './ShopProductBox.vue';

export default {
  name: 'shop-mobile-payment-view',
  components: {ShopProductBox},
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    if (typeof window !== 'undefined') {
      // Send request to app to initiate the purchase.
      window.parent.postMessage({
        action: 'makePurchase',
        product: this.product.id,
        gid: this.product.gid,
      }, '*');
    }
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
  }),
};
</script>
