<template>
  <div id="app" class="app">
    <page></page>
    <toolbar v-if="auth"></toolbar>
    <panel></panel>
    <notice-popup-queue></notice-popup-queue>
    <medal-popup-queue></medal-popup-queue>

    <!-- Xsolla Modal -->
    <xsolla-modal></xsolla-modal>

    <!-- E-mail Check Modal -->
    <email-check-modal></email-check-modal>

    <!-- Updates Modal -->
    <transition name="fade">
      <updates-modal v-if="showUpdates"></updates-modal>
    </transition>

    <!-- GDPR Modal -->
    <transition name="fade">
      <privacy-consent v-if="showGdpr"></privacy-consent>
    </transition>

    <!-- Accept Terms Modal -->
    <accept-terms></accept-terms>

    <!-- E-mail Verification Modal -->
    <verification-confirm-modal></verification-confirm-modal>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {Howl} from 'howler';
import Page from './Page.vue';
import Toolbar from './Toolbar.vue';
import Panel from './Panel.vue';
import NoticePopupQueue from './NoticePopupQueue.vue';
import MedalPopupQueue from './MedalPopupQueue.vue';
import XsollaModal from './XsollaModal.vue';
import EmailCheckModal from './EmailCheckModal.vue';
import PrivacyConsent from './PrivacyConsent.vue';
import UpdatesModal from './UpdatesModal.vue';
import VerificationConfirmModal from './VerificationConfirmModal.vue';
import AcceptTerms from './AcceptTerms.vue';

export default {
  name: 'app',
  components: {
    Page,
    Toolbar,
    Panel,
    NoticePopupQueue,
    MedalPopupQueue,
    XsollaModal,
    EmailCheckModal,
    PrivacyConsent,
    UpdatesModal,
    VerificationConfirmModal,
    AcceptTerms,
  },
  data() {
    return {
      sound: {},
      audioUrl: process.env.NODE_ENV === 'development' ? '/src/assets/audio' : '/audio',
    };
  },
  created() {
    this.$store.commit('setActiveGame', this.game);

    // Update the global timer for use in LiveTime and elsewhere.
    if (typeof window !== 'undefined') {
      setInterval(() => {
        this.$store.commit('updateTime');
      }, 1000);
    }

    // Update for React Native app support for postMessage.
    if (typeof window !== 'undefined' && typeof window.ReactNativeWebView !== 'undefined') {
      window.parent.postMessage = (data) => {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      };
    }

    // Add a click listener to open links in mobile.
    if (typeof document !== 'undefined') {
      document.addEventListener('click', (e) => {
        let target = e.target || e.srcElement;
        if (target.tagName !== 'A' && e.path && e.path[1] && e.path[1].tagName === 'A') {
          target = e.path[1];
        }

        if (this.$store.state.app.type === 'mobile' && target && target.tagName === 'A') {
          const href = target.getAttribute('href');
          if (href && href !== '#' && !href.startsWith('https://goldfire.me')) {
            window.parent.postMessage({
              action: 'openInBrowser',
              url: href,
            }, '*');

            e.preventDefault();
          }
        }
      });
    }
  },
  mounted() {
    this.sound = new Howl({
      src: [`${this.audioUrl}/chimes.2.webm`, `${this.audioUrl}/chimes.2.mp3`],
      sprite: {
        chatRec: [0, 964.58],
        chatSend: [2000, 1067.69],
        medal: [5000, 3041.68],
        notification: [10000, 2291.66],
      },
      volume: 0.6,
      html5: true,
    });
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
    playSound: state => state.toolbar.sound,
    game: state => state.route.params.game,
    showUpdates: state => state.updates.show,
    showGdpr: state => {
      return state.user.isGdpr
        && !state.user.gdpr
        && !state.user.guest
        && state.user.auth
        && !state.route.path.match(/terms|privacy/);
    },
  }),
  watch: {
    game() {
      this.$store.commit('setActiveGame', this.game);
    },
    playSound(val) {
      if (val) {
        this.sound.play(val);
        this.$store.commit('playSound', null);
      }
    },
  },
  meta: {
    title: 'GoldFire - Multiplayer Games',
    titleTemplate: '%s - GoldFire',
  },
};
</script>
