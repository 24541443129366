<template>
  <div class="login">
    <!-- Login/Signup Form -->
    <div class="left">
      <div class="content">
        <slot></slot>
      </div>
    </div>

    <!-- Social Login Buttons -->
    <div class="right">
      <!-- Or Divider Icon -->
      <div class="or-container">
        <div class="or-text">or</div>
        <div class="or-circle-container">
          <div class="or-circle"></div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="content">
        <div
          v-for="(button, i) in buttons"
          :key="i"
          :class="['button', 'social', 'flex', 'center', 'middle', button.id, {'wide': i < 1}]"
          @click="socialLogin(button.id)"
        >
          <span :class="['icon', 'login', 'right-margin-10', button.id]"></span>
          {{ button.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {readCookie, iOSDevice, isMobile} from '../utils';

export default {
  name: 'login-wrapper',
  computed: {
    buttons() {
      // Apple devices.
      if (iOSDevice(this.$store.state.user.ua)) {
        return [
          {id: 'apple', text: 'Sign in with Apple'},
          {id: 'facebook', text: 'Facebook'},
          {id: 'google', text: 'Google'},
          // {id: 'twitter', text: 'Twitter'},
          {id: 'windows', text: 'Windows'},
        ];
      }

      // Android devices.
      if (!iOSDevice(this.$store.state.user.ua) && isMobile(this.$store.state.user.ua)) {
        return [
          {id: 'google', text: 'Google'},
          {id: 'facebook', text: 'Facebook'},
          {id: 'windows', text: 'Windows'},
          // {id: 'twitter', text: 'Twitter'},
          {id: 'apple', text: 'Apple'},
        ];
      }

      return [
        {id: 'facebook', text: 'Facebook'},
        {id: 'google', text: 'Google'},
        {id: 'windows', text: 'Windows'},
        // {id: 'twitter', text: 'Twitter'},
        {id: 'apple', text: 'Apple'},
      ];
    },
  },
  methods: {
    // NOTE: This is duplicated in GuestComplete.vue.
    socialLogin(site) {
      let location = `/auth/${site}`;

      // If this is within the mobile app, notify the app to initiate the login.
      if (this.$store.state.app.type === 'mobile') {
        if (site !== 'apple') {
          this.$store.commit('showLogin', false);
        }

        // Send message to the app with the social login site.
        return window.parent.postMessage({
          action: 'socialLogin',
          site,
          sid: readCookie('sid'),
        }, '*');
      }

      // Keep track of where they were to send them back after login/signup.
      if (this.$el.offsetParent.className.match(/login-modal-container/)) {
        location += `?route=${this.$store.state.route.path}`;
      }

      window.location = location;

      return location;
    },
  },
};
</script>
