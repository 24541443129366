<template>
  <panel-content>
    <component :is="activeView"></component>
  </panel-content>
</template>

<script>
import Vuex from 'vuex';
import PanelContent from './PanelContent.vue';
import ForumsView from './ForumsView.vue';
import SocialFeedView from './SocialFeedView.vue';
import SocialFeedUpdateView from './SocialFeedUpdateView.vue';
import ForumThreadsListView from './ForumThreadsListView.vue';
import ForumThreadView from './ForumThreadView.vue';
import ForumSubscriptionsView from './ForumSubscriptionsView.vue';
import EmptyView from './EmptyView.vue';

export default {
  name: 'forums',
  components: {
    PanelContent,
    ForumsView,
    ForumThreadsListView,
    ForumThreadView,
    SocialFeedView,
    SocialFeedUpdateView,
    ForumSubscriptionsView,
    EmptyView,
  },
  computed: Vuex.mapState({
    activeView: state => state.forums.activeView,
  }),
  meta() {
    if (this.$parent.$el.className.match(/panel/)) {
      return {};
    }

    return {
      title: 'Forums',
    };
  },
};
</script>
