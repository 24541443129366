<template>
  <transition name="drawer-top">
    <span v-if="isVisible" class="search">
      <div class="box input no-border">
        <input type="text" v-model="query" placeholder="Search forums..." v-focus>
        <button class="small auto">Search</button>
      </div>
    </span>
  </transition>
</template>

<script>
import Vuex from 'vuex';
import {debounce} from '../utils';

export default {
  name: 'forum-search',
  data() {
    return {
      query: '',
    };
  },
  computed: Vuex.mapState({
    isVisible: state => state.forums.searchIsVisible,
    priorQuery: state => state.forums.searchQuery,
  }),
  watch: {
    query(newQuery) {
      this.$store.commit('setForumSearchQuery', {query: newQuery});
      this.$store.commit('setForumThreads', {});
      this.$store.commit('togglePanelLoading', true);
      if (newQuery !== '') {
        this.search();
      } else {
        this.$parent.$options.methods.load.call(this.$parent);
      }
    },
    isVisible(visible) {
      if (visible) {
        this.query = this.priorQuery;
      } else {
        this.$store.commit('setForumSearchQuery', {query: ''});
        this.$store.commit('setForumThreads', {});
        this.$parent.$options.methods.load.call(this.$parent);
      }
    },
  },
  methods: {
    search: debounce(function search() {
      this.$parent.$options.methods.load.call(this.$parent);
    }, 500),
  },
  mounted() {
    this.query = this.priorQuery;
  },
};
</script>
