<template>
  <transition name="fade">
    <div v-if="showVerification" class="login-modal-container flex middle center">
      <div class="login-modal light flex column middle center">
        <h3>E-mail verification sent!</h3>
        Check your spam folder if you don't see anything within a few minutes.
        <div class="button auto color top-margin-30" @click="close">Continue Playing</div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'verification-confirm-modal',
  computed: Vuex.mapState({
    showVerification: state => state.auth.showVerify,
  }),
  methods: {
    close() {
      this.$store.commit('setAuthData', {showVerify: false});
    },
  },
};
</script>
