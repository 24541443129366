<template>
  <div class="content"></div>
</template>

<script>
export default {
  name: 'empty-view',
  serverCacheKey() {
    return true;
  },
};
</script>
