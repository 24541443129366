<template>
  <div class="content">
    <forum-thread-box
      v-for="thread in threads"
      class="inf"
      :id="`id-${thread._id}`"
      :thread="thread"
      :category="thread.category"
      :key="thread._id"
      :unsubscribe="true"
    ></forum-thread-box>
    <div v-if="threads.length === 0 && !isLoading" class="box">
      You’re not subscribed to any threads yet. Browse the forums for threads you want to follow, then hit the Subscribe button.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumThreadBox from './ForumThreadBox.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'forum-subscriptions-view',
  components: {ForumThreadBox},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'SUBSCRIBED DISCUSSIONS',
      buttons: [],
      wide: true,
    });

    this.load(); // load first page of data
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        actionName: 'loadForumSubscriptions',
        mutationName: 'setForumThreads',
        items: state.forums.threads,
        keys: ['t'],
      };
    },
    threads: (state) => {
      return state.forums.threads.map((thread) => {
        thread.category = state.forums.categories.find(cat => cat.gid === thread.gid);
        return thread;
      });
    },
    isLoading: state => state.panel.loading,
  }),
  methods: {
    unsubscribe(thread) {
      this.$store.dispatch('unsubscribeFromSubscriptions', {tid: thread._id});
    },
  },
  beforeDestroy() {
    this.$store.commit('setForumThreads', {});
  },
};
</script>
