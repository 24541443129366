<template>
  <div>
    <transition name="fade-top">
      <div class="notice-bar flex middle center" @click="onClick" v-if="showNotification">
        {{ notificationText }}
      </div>
    </transition>
  </div>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'forum-threads-list-header',
  computed: Vuex.mapState({
    showNotification: state => state.forums.notifications !== 0,
    notificationCount: state => state.forums.notifications,
    notificationText() {
      const thread = this.notificationCount === 1 ? 'thread' : 'threads';
      return `Click to view ${this.notificationCount} new or updated ${thread}.`;
    },
  }),
  methods: {
    onClick() {
      this.$store.commit('incForumNotifications', 0);

      // If we have the first page in memory, just replace the threads;
      // otherwise, we need to just reload the whole thread list.
      if (this.$store.state.scroll.start) {
        this.$store.commit('insertWaitingForumThreads', {});
      } else {
        this.$store.commit('setForumThreads', {});
      }

      // Scroll to the top of the panel.
      this.$store.commit('scrollToTop');
    },
  },
  beforeDestroy() {
    this.$store.commit('incForumNotifications', 0);
  },
};
</script>
