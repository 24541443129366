<template>
  <div class="content">
    <!-- Product -->
    <shop-product-box :product="product"></shop-product-box>

    <!-- Load/Errors -->
    <div class="spinner auto-center" v-if="loading"></div>
    <a :href="url" target="_blank" class="button color top-margin-15" @click="loading = true" v-else>Checkout on PayPal</a>
    <div class="red input-error text-center" v-if="error.unknown">An unknown error occured. Please try again.</div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ShopProductBox from './ShopProductBox.vue';

export default {
  name: 'shop-paypal-payment-view',
  components: {ShopProductBox},
  data() {
    return {
      loading: true,
      url: '',
      error: {
        unknown: false,
      },
    };
  },
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons: [],
    });

    this.setupPaypal();
  },
  computed: Vuex.mapState({
    product: state => state.shop.cart[0],
    status: state => state.shop.paypal,
  }),
  watch: {
    status(val) {
      if (val === true) {
        this.$store.commit('changeView', 'shop-success-view');
      } else if (val === 'pending') {
        this.$store.commit('changeView', 'shop-pending-view');
      }

      this.$store.commit('setPaypalStatus', false);
    },
  },
  methods: {
    setupPaypal() {
      this.$store.dispatch('setupPaypal', {
        product: this.product.id,
        gid: this.product.gid,
        gift: this.$store.state.shop.gift,
      }).then((data) => {
        this.loading = false;

        if (!data.id || data.errors) {
          this.error.unknown = true;
          return;
        }

        // Set the URL to go to PayPal.
        if (!this.product.recurring) {
          this.url = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=paypal%40goldfire.me&item_name=${this.product.long_title || this.product.title}&amount=${encodeURIComponent(this.product.price.replace('$', ''))}&no_shipping=1&return=${encodeURIComponent('https://goldfire.me/purchase/done')}&notify_url=${encodeURIComponent('https://goldfire.me/gold/paypal')}&cancel_return=${encodeURIComponent('https://goldfire.me')}&no_note=1&currency_code=USD&page_style=GoldFire&item_number=${data.id}`;
        } else {
          this.url = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick-subscriptions&business=paypal%40goldfire.me&item_name=${this.product.long_title || this.product.title}&a3=${encodeURIComponent(this.product.price.replace('$', ''))}&t3=M&p3=${this.product.recurring}&src=1&no_shipping=1&return=${encodeURIComponent('https://goldfire.me/purchase/done')}&notify_url=${encodeURIComponent('https://goldfire.me/gold/paypal')}&cancel_return=${encodeURIComponent('https://goldfire.me')}&no_note=1&currency_code=USD&page_style=GoldFire&item_number=${data.id}`;
          // this.url = `https://sandbox.paypal.com/cgi-bin/webscr?cmd=_xclick-subscriptions&business=paypal-facilitator%40goldfire.me&item_name=${this.product.long_title || this.product.title}&a3=${encodeURIComponent(this.product.price)}&t3=M&p3=${this.product.recurring}&src=1&no_shipping=1&return=${encodeURIComponent('https://beta.goldfire.me/purchase/done')}&notify_url=${encodeURIComponent('https://beta.goldfire.me/gold/paypal')}&cancel_return=${encodeURIComponent('https://beta.goldfire.me')}&no_note=1&currency_code=USD&page_style=GoldFire&item_number=${data.id}`;
        }

        // Subscribe to the paypal completion channel.
        this.$store.dispatch('sub', `completePaypal:${this.$store.state.user.uid}`);
      });
    },
  },
  beforeDestroy() {
    this.$store.dispatch('unsub', `completePaypal:${this.$store.state.user.uid}`);
  },
};
</script>
