<template>
  <div class="content">
    <forum-poll :data="poll" v-if="poll"></forum-poll>
    <forum-thread-post
      v-for="(post, index) in posts"
      class="inf"
      :id="`id-${post._id}`"
      :post="checkUnread(post, index)"
      :isThreadFirst="firstPost === post._id"
      :key="index"
    >
    </forum-thread-post>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumThreadPost from './ForumThreadPost.vue';
import ForumPoll from './ForumPoll.vue';
import TextEditor from './TextEditor.vue';
import {infiniteScrolling} from '../mixins';
import {throttle} from '../utils';

export default {
  name: 'forum-thread-view',
  components: {ForumThreadPost, TextEditor, ForumPoll},
  mixins: [infiniteScrolling],
  data() {
    return {
      postIndex: 0,
      firstPost: null,
    };
  },
  mounted() {
    // Instruct server to increment thread's views and create/update user's view date
    // for this thread. Then, load posts at most recent unread post.
    if (this.$store.state.forums.activeThread.posts.length === 0) {
      this.loadThread();
    }

    if (this.$store.state.user.auth) {
      this.$store.dispatch('sub', `newThreadReply:${this.tid}`);
      this.$store.dispatch('sub', `forumPostEdit:${this.tid}`);
      this.$store.dispatch('sub', `forumThreadViews:${this.tid}`);
    }
  },
  methods: {
    loadThread() {
      const pid = this.$store.state.forums.postId;
      this.$store.commit('setScrollData', {start: true, end: false});
      this.$store.commit('setForumPosts', {});

      return this.$store.dispatch('viewThread', {
        tid: this.tid,
        limit: !this.$store.state.user.auth ? 25 : this.limit,
        gid: this.gid,
        pid,
      }).then((data) => {
        this.$store.commit('setScrollData', {
          start: data.end === 'start',
          end: data.end === 'end',
        });
        this.$store.commit('setForumThreadPostId', null);
        this.postIndex = data.postIndex || 0;
        this.firstPost = data.firstPost;

        if (data.posts) {
          this.$store.commit('setForumPosts', {items: data.posts, concat: true});

          if (data.posts.length) {
            const firstPostIsUnread = data.posts[0].unread;
            const lastPostIsRead = !data.posts[data.posts.length - 1].unread;

            if (firstPostIsUnread) {
              this.$store.commit('setPanelAltStatus', `1 / ${this.postCount}`);
            } else if (pid) {
              // Jump to the passed post ID.
              this.$nextTick(() => {
                const jumpEl = this.$el.querySelector(`#id-${pid}`);
                this.$store.commit('setScrollAreaPosition', {
                  top: jumpEl.offsetTop - 10,
                });
              });
            } else if (lastPostIsRead) {
              // If all posts have been previously read, scroll to the bottom.
              this.$store.commit('scrollToBottom');
            } else {
              // Otherwise, scroll to oldest unread post.
              this.$nextTick(() => {
                const firstUnreadEl = this.$el.querySelector('.box.post.color');
                this.$store.commit('setScrollAreaPosition', {
                  top: firstUnreadEl.offsetTop - 10,
                });
              });
            }
          }
        }

        if (typeof window !== 'undefined') {
          this.$parent.$el.addEventListener('scroll', this.onScroll);
        }
      });
    },
    jumpToTop() {
      this.$store.commit('reloadForumThread', 'top');
    },
    checkUnread(post, index) {
      const prev = this.posts[index - 1];

      if (prev && prev.unread) {
        this.$store.commit('markPostUnread', {index});
      }

      return post;
    },
    loadRes({items, direction}) {
      if (items && direction === 'prev') {
        this.postIndex -= items.length;
      }
    },
    purgeRes(purge, limit) {
      if (purge === 'first' && limit) {
        this.postIndex += limit;
      }
    },
    updatePositionCounter: throttle(function updatePositionCounter() {
      const currentPost = this.postIndex + this.posts
        .map(post => this.$el.querySelector(`#id-${post._id}`))
        .reduce((pos, post) => {
          if (post && post.offsetTop < this.$parent.$el.scrollTop + this.$parent.$el.offsetHeight) {
            return pos + 1;
          }
          return pos;
        }, 0);

      this.$store.commit('setPanelAltStatus', `${currentPost} / ${this.postCount}`);
      this.$store.commit('setPanelAltIcon', {
        altMethod: {method: this.jumpToTop, data: {}},
      });
    }, 100),
    onScroll() {
      this.updatePositionCounter();

      // Remove new post notifications if we've scrolled to the end.
      if (this.scrollEnd && this.end) {
        this.$store.commit('incForumNotifications', 0);
      }
    },
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        actionParams: {tid: this.tid},
        actionName: 'loadForumPosts',
        mutationName: 'setForumPosts',
        items: state.forums.activeThread.posts,
        keys: ['_id'],
      };
    },
    posts: state => state.forums.activeThread.posts,
    tid: state => state.forums.activeThread.id,
    gid: state => state.forums.currentCategory,
    poll: state => state.forums.activeThread.poll,
    postCount: state => state.forums.activeThread.postCount,
    reload: state => state.forums.reload,
  }),
  watch: {
    posts(val = [], oldVal) {
      if (!val.length) {
        return;
      }

      // Scroll to the bottom if this is from you.
      const {uid} = this.$store.state.user;
      const lastPostIsMine = val[val.length - 1].author === uid;
      const lastPostIsOnlyNewPost = val.length === oldVal.length + 1;
      const shouldScrollToBottom = lastPostIsMine && lastPostIsOnlyNewPost;

      if (shouldScrollToBottom) {
        this.$store.commit('scrollToBottom');
      }

      this.$nextTick(() => {
        this.updatePositionCounter();
      });
    },
    reload(val) {
      if (val) {
        this.loadThread().then(() => {
          this.$store.commit('reloadForumThread', false);
          this.$store.commit(val === 'top' ? 'scrollToTop' : 'scrollToBottom', {});
        });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('setForumPosts', {});
    this.$store.commit('setThreadPollData', null);

    if (this.$store.state.user.auth) {
      this.$store.dispatch('unsub', `newThreadReply:${this.tid}`);
      this.$store.dispatch('unsub', `forumPostEdit:${this.tid}`);
      this.$store.dispatch('unsub', `forumThreadViews:${this.tid}`);
      this.$store.dispatch('updateThreadView', {tid: this.tid});
    }

    this.$store.commit('resetActiveThread');
    this.$parent.$el.removeEventListener('scroll', this.onScroll);
  },
};
</script>
