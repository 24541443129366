<template>
  <div>
    <game-medals v-for="game in games" :game="game" :key="game.game"></game-medals>
    <div v-if="games.length === 0 && !isLoading" class="box center">
      This user hasn't played any games yet.
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import GameMedals from './GameMedals.vue';

export default {
  name: 'all-medals',
  components: {GameMedals},
  props: ['games'],
  computed: Vuex.mapState({
    isLoading: state => state.panel.loading,
  }),
};
</script>
