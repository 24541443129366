<template>
  <div class="gender-buttons">
    <span :class="['button', 'flex', 'center', 'middle', 'auto', 'right-margin-10', {untoggle: value !== 'Male'}]" @click="updateValue('Male')">
      <span class="icon male right-margin-5"></span>
      <span>Male</span>
    </span>
    <span :class="['button', 'flex', 'center', 'middle', 'auto', {untoggle: value !== 'Female'}]" @click="updateValue('Female')">
      <span class="icon female right-margin-5"></span>
      <span>Female</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'gender-select',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
  serverCacheKey(props) {
    return props.value;
  },
};
</script>
