<template>
  <div class="box poll flex column">
    <span class="question bottom-margin-5">{{ data.q }}</span>
    <template v-if="!data.voted && $store.state.user.auth">
      <button class="small top-margin-5" v-for="(answer, index) in data.a" @click="vote(index)">{{ answer }}</button>
    </template>
    <template v-else>
      <span class="box no-padding flex justify top-margin-5 relative" v-for="(answer, index) in data.a">
        <span class="left-margin-10">{{ answer }}</span>
        <span class="small subtext right-margin-10" v-html="countString(index)"></span>
        <div class="percent" :style="`width: ${percent(index)}%;`"></div>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'forum-poll',
  props: ['data'],
  mounted() {
    if (this.$store.state.user.auth) {
      this.$store.dispatch('sub', `threadPollVote:${this.$store.state.forums.activeThread.id}`);
    }
  },
  methods: {
    countString(index) {
      const votes = this.data.v[index];
      const percent = this.percent(index);

      return `<b>${votes}</b> (${percent}%)`;
    },
    percent(index) {
      return Math.round((this.data.v[index] / this.total) * 100);
    },
    vote(index) {
      this.$store.dispatch('voteThreadPoll', {
        id: this.$store.state.forums.activeThread.id,
        answer: index,
      });
    },
  },
  computed: {
    total() {
      return this.data.v.reduce((a, b) => a + b, 0);
    },
  },
  beforeDestroy() {
    if (this.$store.state.user.auth) {
      this.$store.dispatch('unsub', `threadPollVote:${this.$store.state.forums.activeThread.id}`);
    }
  },
};
</script>
