<template>
  <div class="signup">
    <div class="content">
      <h3>Enter the e-mail on your account.</h3>
      <div class="bottom-margin-30">
        <input
          type="text"
          class="underline"
          placeholder="E-mail Address"
          v-model="email"
          @keyup.enter="submit"
          v-focus
        >
      </div>

      <!-- Reset Button -->
      <button-loader
        value="Reset Password"
        :styles="{'color': true, 'top-margin-15': true}"
        :loading="loading"
        :click="submit"
      ></button-loader>
      <div class="red input-error" v-if="error.unknown">An unknown error occured, please try again.</div>
    </div>
  </div>
</template>

<script>
import ButtonLoader from '../ButtonLoader.vue';

export default {
  name: 'reset',
  components: {ButtonLoader},
  data() {
    return {
      loading: false,
      email: '',
      error: {
        unknown: false,
      },
    };
  },
  methods: {
    submit() {
      this.$store.dispatch('resetPassword', this.email);
      this.$router.push('/login/reset/2');
    },
  },
};
</script>
