<template>
  <div class="content">
    <div class="box input" v-if="canRemove">
      <input type="text" v-model="groupName" placeholder="Group Name">
    </div>
    <friend-box
      v-for="player in players"
      :player="player"
      :toggle="toggle"
      :id="`id-${player.uid}`"
      :key="player.uid"
      :remove="canRemove"
      @remove="remove"
    ></friend-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import {debounce} from '../utils';
import FriendBox from './FriendBox.vue';

export default {
  name: 'chat-settings-view',
  components: {FriendBox},
  data() {
    return {
      toggle: null,
      groupName: null,
      groupNameChanged: false,
    };
  },
  mounted() {
    const isGroup = (this.$store.state.chat.settings.players.length > 2);
    this.$store.state.chat.friendListType = 'friend';
    this.$store.commit('setPanelData', {
      title: 'CHAT SETTINGS',
      buttons: [{
        label: isGroup ? 'Add to Group' : 'Start Group Chat',
        view: {mutation: 'setFriendListType', data: {type: 'group', exclude: this.players.map(p => p.uid)}},
      }],
    });

    // Subscribe to player status.
    this.$store.dispatch('bulkSub', this.players.map(p => `playerStatus:${p.uid}`));

    // Set the current group name value.
    this.groupName = this.$store.state.chat.activeMessage.name;
  },
  watch: {
    groupName() {
      // Set groupNameChanged to true after initial value has been set.
      if (!this.groupNameChanged) {
        this.groupNameChanged = true;
        return;
      }

      // Debounce the function call to avoid rapid firing.
      this.debouncedSetGroupName();
    },
  },
  computed: Vuex.mapState({
    players: state => state.chat.settings.players.filter(player => player.group),
    canRemove: state => {
      const players = state.chat.settings.players.filter(player => player.group);
      return (players[0].uid === state.user.uid || players[1].uid === state.user.uid) && players.length > 2;
    },
  }),
  methods: {
    remove(uid) {
      this.$store.dispatch('remGroupChat', uid);
    },
    setGroupName() {
      this.$store.dispatch('setChatGroupName', {
        name: this.groupName,
        id: this.$store.state.chat.activeMessage.id,
      });
    },
    debouncedSetGroupName: debounce(function setGroup() {
      this.setGroupName();
    }, 1000),
  },
  beforeDestroy() {
    this.$store.dispatch('bulkUnsub', this.players.map(p => `playerStatus:${p.uid}`));
  },
  destroyed() {
    this.$store.commit('setChatSettingsPlayers', []);
  },
};
</script>
