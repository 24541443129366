<template>
  <!-- Spinner for Social Signup -->
  <div
    v-if="type && id && $store.state.route.query.bday && !error.badEmail && !error.emailTaken"
    class="signup"
  >
    <div class="spinner"></div>
  </div>

  <!-- Verify Message on Complete -->
  <div v-else-if="completed" class="signup">
    <div class="content column middle">
      <h3>Welcome! Check your e-mail to verify your account.</h3>
      <div class="button color top-margin-30" @click="close">Continue Playing</div>
    </div>
  </div>

  <!-- Gender/Birthday for Apple -->
  <div v-else-if="type && id" class="signup">
    <div class="content">
      <h3>Almost done, just a few more details to start playing!</h3>

      <!-- Login Form -->
      <div class="top-margin-10 bottom-margin-30">
        <!-- Birthday -->
        <cleave
          v-model="bday"
          :class="['underline', 'top-margin-15', {error: error.age}]"
          placeholder="Birthday (mm/dd/yyyy)"
          :options="{date: true, datePattern: ['m', 'd', 'Y']}"
          @enter="complete"
        />
        <div v-if="error.age" class="red input-error">You must be 13+ to join.</div>

        <!-- Gender -->
        <div class="gender-select top-margin-30">
          <gender-select v-model="gender" class="center full" />
        </div>
      </div>

      <!-- Signup Button -->
      <button-loader
        value="Complete Signup"
        :styles="{'color': true, 'bottom-margin-30': true}"
        :loading="loading"
        :click="complete"
      />
      <div v-if="error.unknown" class="red input-error">
        An unknown error occured, please try again.
      </div>
    </div>
  </div>

  <!-- Facebook Signup -->
  <div v-else-if="fbCmp && !fbConnectOff" class="signup flex column">
    <h3>Save Your Progress</h3>

    <!-- Facebook Login Button -->
    <div class="flex column center bottom-margin-30">
      <div class="button social flex center middle facebook" @click="socialLogin('facebook')">
        <span class="icon login facebook right-margin-10"></span>
        Signup with Facebook
      </div>

      <div class="button social flex center middle email" @click="fbConnectOff = true">
        <span class="icon login email right-margin-10"></span>
        Signup with Email
      </div>
    </div>

    <!-- Forgot Pass/Login Link -->
    <a href="#" class="link top-margin-20" @click.prevent="close">Not right now.</a>
  </div>

  <!-- Second Page during Guest Signup -->
  <login-wrapper v-else>
    <h3>Save Your Progress</h3>

    <!-- Username Input -->
    <div v-if="page === 1" class="top-margin-10 bottom-margin-30 full">
      <input
        v-model="email"
        type="text"
        :class="{underline: true, error: error.badEmail || error.emailTaken}"
        placeholder="E-mail Address"
        @keyup.enter="complete"
        @change="onChange"
        v-focus
      >
      <div v-if="error.emailTaken" class="red input-error">
        This e-mail is already in use.
      </div>
      <div v-else-if="error.badEmail" class="red input-error">
        Please enter a valid e-mail.
      </div>
      <div
        v-else-if="mailcheck"
        class="warning input-error"
        @click="fixEmail"
        v-html="mailcheck"
      ></div>
      <input
        v-model="pass"
        type="password"
        :class="['underline', 'top-margin-15', {error: error.passLen}]"
        placeholder="Password"
        @keyup.enter="complete"
      >
      <div v-if="error.passLen" class="red input-error">
        Password should be 6+ characters.
      </div>
    </div>

    <!-- Gender/Birthday -->
    <div v-else class="top-margin-20 bottom-margin-30 full">
      <!-- Birthday -->
      <cleave
        v-model="bday"
        :class="['underline', {error: error.age}]"
        placeholder="Birthday (mm/dd/yyyy)"
        :options="{date: true, datePattern: ['m', 'd', 'Y']}"
        @enter="complete"
        v-focus
      />
      <div v-if="error.age" class="red input-error">
        You must be 13+ to join.
      </div>

      <!-- Gender -->
      <div class="gender-select top-margin-30">
        <gender-select v-model="gender" class="center full" />
      </div>
    </div>

    <!-- Play Now Button -->
    <button-loader
      :value="fullForm && page === 1 ? 'Continue' : 'Create Account'"
      :styles="{'color': true, 'bottom-margin-30': page === 1}"
      :loading="loading"
      :click="complete"
    />
    <div v-if="error.unknown" class="red input-error">
      An unknown error occured, please try again.
    </div>

    <!-- Forgot Pass/Login Link -->
    <a v-if="page === 1" href="#" class="link" @click.prevent="close">Not right now.</a>
  </login-wrapper>
</template>

<script>
import is from 'is_js';
import mailcheck from 'mailcheck';
import LoginWrapper from './LoginWrapper.vue';
import ButtonLoader from './ButtonLoader.vue';
import Cleave from './Cleave.vue';
import GenderSelect from './GenderSelect.vue';
import {readCookie} from '../utils';

export default {
  name: 'guest-complete',
  components: {LoginWrapper, ButtonLoader, Cleave, GenderSelect},
  data() {
    return {
      loading: false,
      completed: false,
      id: this.$store.state.route.query.id,
      type: this.$store.state.route.query.type,
      email: this.$store.state.route.query.email || '',
      pass: '',
      gender: this.$store.state.route.query.type ? this.$store.state.route.query.gender : 'Male',
      bday: this.$store.state.route.query.bday,
      mailcheck: null,
      page: 1,
      fullForm: false,
      fbCmp: `${readCookie('game-cmp')}`.includes('fb')
        || `${readCookie('game-cmp')}`.includes('facebook'),
      fbConnectOff: false,
      error: {
        badEmail: false,
        emailTaken: false,
        badAge: false,
        passLen: false,
        unknown: false,
      },
    };
  },
  created() {
    // Handle social login.
    if (typeof window !== 'undefined' && this.type && this.type !== '' && this.bday && this.id && this.id !== '') {
      this.complete();
    }
  },
  mounted() {
    if (!this.$store.state.activeGame.guests) {
      this.fullForm = true;
    }
  },
  methods: {
    // NOTE: This is duplicated from LoginWrapper.vue.
    socialLogin(site) {
      let location = `/auth/${site}`;

      // If this is within the mobile app, notify the app to initiate the login.
      if (this.$store.state.app.type === 'mobile') {
        if (site !== 'apple') {
          this.$store.commit('showLogin', false);
        }

        // Send message to the app with the social login site.
        return window.parent.postMessage({
          action: 'socialLogin',
          site,
          sid: readCookie('sid'),
        }, '*');
      }

      // Keep track of where they were to send them back after login/signup.
      location += `?route=${this.$store.state.route.path}`;

      window.location = location;

      return location;
    },
    complete() {
      if (this.page === 2) {
        return this.complete2();
      }

      const err = this.error;

      this.loading = true;
      err.badEmail = false;
      err.emailTaken = false;
      err.passLen = false;
      err.unknown = false;

      if (!is.email(this.email)) {
        this.badEmail = true;
      }
      if (!this.pass || this.pass === '' || this.pass.length < 6) {
        this.passLen = true;
      }

      // Check with the server if this e-mail is taken.
      return this.$store.dispatch('guestComplete', {
        email: this.email.toLowerCase(),
        pass: this.pass,
        id: this.id,
        type: this.type,
        gender: this.gender,
        bday: this.bday,
      }).then((data) => {
        this.loading = false;
        Object.assign(this.error, data.errors);

        if (!err.badEmail && !err.emailTaken && !err.passLen) {
          this.$store.commit('setSettingsData', {verify: data.verify});
          this.$store.commit('setUserData', {guest: 0});

          if (this.type) {
            const loginRoute = readCookie('loginRoute');
            const loginGame = readCookie('loginGame');
            if (loginRoute === 'mobile') {
              window.location = `${loginGame}://?sid=${data.cookie}`;
            } else {
              this.$router.push(loginRoute ? `${loginRoute}?rel=social` : '/');
            }

            // Clear the route cookie.
            if (loginRoute) {
              document.cookie = 'loginRoute=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'loginGame=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
          } else if (!this.fullForm) {
            this.completed = true;
          } else {
            this.page = 2;
          }
        }
      });
    },
    complete2() {
      this.error.age = false;
      this.error.unknown = false;

      if (this.guest && !is.dateString(this.bday)) {
        this.error.age = true;
        return;
      }

      this.loading = true;
      this.$store.dispatch('guestComplete2', {
        bday: this.bday,
        gender: this.gender,
      }).then((data) => {
        this.loading = false;
        Object.assign(this.error, data.errors);

        if (!this.error.uknown && !this.error.age) {
          this.close();
          this.$store.dispatch('connectGame', this.$store.state.activeGame.gid);
        }
      });
    },
    close() {
      this.$store.commit('showLogin', false);

      // Focus on the game.
      if (document.body.querySelector('.game-iframe')) {
        document.body.querySelector('.game-iframe').focus();
      }
    },
    onChange() {
      this.mailcheck = null;
      mailcheck.run({
        email: this.email,
        suggested: (suggestion) => {
          this.mailcheck = `Did you mean <b>${suggestion.full}</b>?`;
          this.emailFixed = suggestion.full;
        },
      });
    },
    fixEmail() {
      this.email = this.emailFixed;
      this.mailcheck = null;
      this.$el.querySelector('input[type="password"]').focus();
    },
  },
};
</script>
