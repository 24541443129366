<template>
  <div id="media" class="info column center middle">
    <forum-tags-view></forum-tags-view>
  </div>
</template>

<script>
import ForumTagsView from './ForumTagsView.vue';

export default {
  name: 'tags-keyboard',
  components: {ForumTagsView},
  mounted() {
    this.$store.commit('toggleShowTags', {show: false, filter: false, select: true});
  },
};
</script>
