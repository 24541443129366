<template>
  <div class="content full-height flex column center middle">
    <div class="icon success bottom-margin-30"></div>
    <span class="strong normal bottom-margin-5">Success!</span>
    <span class="subtext medium light text-center">Your purchase is complete. Thank you for your support!</span>
  </div>
</template>

<script>
export default {
  name: 'shop-success-view',
  mounted() {
    // If they bought a GoldFire product from settings, take them back there.
    let buttons = [{label: 'Back to Store', view: 'shop-view'}];
    if (this.$store.state.shop.cart[0].gid === 0) {
      buttons = [{
        label: 'Back to Settings',
        view: {
          mutation: 'setTabView',
          data: {tab: 'launcher', view: 'settings-view'},
        },
      }];
    }

    this.$store.commit('setHistory', []);
    this.$store.commit('setPanelData', {
      title: 'STORE',
      buttons,
    });
  },
};
</script>
