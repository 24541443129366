<template>
  <div class="content">
    <!-- Saved Cards -->
    <info-box title="Saved Cards" type="''" v-if="!isMobile">
      <credit-card-box
        v-for="(card, index) in creditCards"
        :card="card"
        :can-delete="true"
        @toggle="toggleDeleteCC(index)"
        @delete="deleteCC(index)"
        :key="index"
      ></credit-card-box>

      <span class="subtext" v-if="creditCards.length === 0">You have no saved cards.</span>
    </info-box>

    <!-- Subscriptions -->
    <info-box title="Active Subscriptions" type="''">
      <div v-for="subscription, index in subscriptions" class="box credit-card">
        <template v-if="!subscription.deleting">
          <div :class="['icon', 'game-icon', subscription.game ]"></div>
          <span class="text flex column end">
            <span class="title">{{ subscription.title }}</span>
            <span class="subtext">{{ expiration(subscription) }}</span>
          </span>
          <button class="button square error no-padding" @click="toggleDeleteSub(index)" v-if="!subscription.cancel">
            <div class="icon trash"></div>
          </button>
        </template>
        <template v-else>
          <span class="text">
            <span>Are you sure?</span>
          </span>
          <button class="button square no-padding" style="margin-right: 1px;" @click="deleteSubscription(index)">Yes</button>
          <button class="button square no-padding" @click="toggleDeleteSub(index)">No</button>
        </template>
      </div>

      <span class="subtext" v-if="subscriptions.length === 0">You have no active subscriptions.</span>
    </info-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import InfoBox from './InfoBox.vue';
import CreditCardBox from './CreditCardBox.vue';

export default {
  name: 'shop-manage-payments-view',
  components: {InfoBox, CreditCardBox},
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'MANAGE',
      buttons: [],
    });

    this.$store.dispatch('getShopSubscriptions');
    if (this.$store.state.shop.manage.cards.length === 0) {
      this.$store.dispatch('getShopCards');
    }
  },
  computed: Vuex.mapState({
    isMobile: state => state.app.type === 'mobile',
    creditCards: state => state.shop.manage.cards,
    subscriptions: state => state.shop.manage.subs,
  }),
  methods: {
    toggleDeleteCC(index) {
      this.$store.commit('toggleDeleteCC', index);
    },
    toggleDeleteSub(index) {
      this.$store.commit('toggleDeleteSub', index);
    },
    deleteCC(index) {
      this.$store.dispatch('deleteCC', index);
    },
    deleteSubscription(index) {
      this.$store.dispatch('deleteSubscription', index);
    },
    expiration(sub) {
      const {expiration} = sub;
      return sub.cancel ? `${expiration} (canceled)` : expiration;
    },
  },
};
</script>
