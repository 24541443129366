<template>
  <info-box
    title="Product"
    type="justify flex"
    :counter="extraInfo"
    counter-class="text-counter small light"
  >
    <div class="text-counter extra-large bold">
      <div class="icon product right-margin-10" :style="shopIcon"></div>
      {{ product.title }}
    </div>
    <span class="text-counter right large light color">
      {{ price }}
    </span>
  </info-box>
</template>

<script>
import Vuex from 'vuex';
import InfoBox from './InfoBox.vue';

export default {
  name: 'shop-product-box',
  components: {InfoBox},
  props: ['product'],
  computed: Vuex.mapState({
    price(state) {
      const {appShop} = state.gameData[this.product.gid];
      let price = `$${this.product.price.replace('$', '')}`;

      // Check if we have a custom price from the app store.
      if (appShop) {
        const product = appShop.find(p => p.product === this.product.id);
        if (product) {
          price = product.price;
        }
      }

      return price;
    },
    gift: state => state.shop.gift,
    game(state) {
      return state.gameData.find(g => g.gid === this.product.gid).game;
    },
    shopIcon() {
      let baseUrl = '/images/games/shop/';
      if (process.env.NODE_ENV !== 'production') {
        baseUrl = '/src/assets/images/games/shop/';
      }

      return `background-image: url(${baseUrl}${this.game}-${this.product.icon})`;
    },
    extraInfo() {
      if (this.gift) {
        return `<span>Gift to <b>${this.gift}</b></span>`;
      }
      if (this.product.recurring) {
        if (this.product.recurring === 1) {
          return '<span><b>Every Month</b></span>';
        }

        return `<span><b>Every ${this.product.recurring} Months</b></span>`;
      }

      return '';
    },
  }),
};
</script>
