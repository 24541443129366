<template>
  <div class="inner">
    <!-- Panel Header -->
    <panel-header></panel-header>

    <!-- Optional: Custom header component  -->
    <div>
      <component :is="headerView"></component>
    </div>

    <!-- Scroll Area & Content -->
    <scroll-area>
      <span id="panelLoader" class="spinner" v-if="loading"></span>
      <slot></slot>
    </scroll-area>

    <!-- Optional: Custom Footer Component -->
    <component :is="footerView"></component>

    <!-- Panel Footer -->
    <panel-footer></panel-footer>
  </div>
</template>

<script>
import Vuex from 'vuex';
import PanelHeader from './PanelHeader.vue';
import PanelFooter from './PanelFooter.vue';
import ScrollArea from './ScrollArea.vue';
import ForumThreadsListHeader from './ForumThreadsListHeader.vue';
import ForumThreadsListFooter from './ForumThreadsListFooter.vue';
import ForumThreadHeader from './ForumThreadHeader.vue';
import ForumThreadFooter from './ForumThreadFooter.vue';
import ChatMessageFooter from './ChatMessageFooter.vue';
import SocialFeedFooter from './SocialFeedFooter.vue';

export default {
  name: 'panel-content',
  components: {
    PanelHeader,
    PanelFooter,
    ScrollArea,
    ForumThreadsListHeader,
    ForumThreadsListFooter,
    ForumThreadHeader,
    ForumThreadFooter,
    ChatMessageFooter,
    SocialFeedFooter,
  },
  mounted() {
    this.$emit('mounted');
  },
  computed: Vuex.mapState({
    loading: state => state.panel.loading,
    footerView: state => state.panel.footerView,
    headerView: state => state.panel.headerView,
  }),
};
</script>
