<template>
  <transition name="fade">
    <div class="screenshot-modal-wrapper xsolla flex middle center" v-if="token">
      <div class="screenshot-modal xsolla flex middle center">
        <div>
          <iframe
            :src="`https://secure.xsolla.com/paystation2/?access_token=${token}`"
            frameborder="0"
            allowfullscreen="true"
          ></iframe>
          <div class="close-button flex center middle" @click="close">
            <span class="icon close"></span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'xsolla-modal',
  computed: Vuex.mapState({
    token: state => state.shop.xsolla,
  }),
  watch: {
    token(val, oldVal) {
      if (val && !oldVal) {
        // Setup the listeners based on actions in the iframe.
        if (typeof window !== 'undefined') {
          this.clearListener();
          window.addEventListener('message', this.updateStatus, false);
        }
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('setXsollaToken', null);
      this.$store.dispatch('goBackView');
    },
    updateStatus(event) {
      let data = {};
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        // ...
      }

      if (data && data.data && data.command === 'status' && data.data.paymentInfo) {
        if (data.data.paymentInfo.status === 'done') {
          this.$store.commit('setXsollaToken', null);
          this.$store.commit('changeView', 'shop-success-view');
          this.clearListener();
        }
      }
    },
    clearListener() {
      if (typeof window !== 'undefined') {
        window.removeEventListener('message', this.updateStatus, false);
      }
    },
  },
};
</script>
