<template>
  <div class="content">
    <!-- Verification Button -->
    <div v-if="!verify && email">
      <button class="color" @click="resendVerify">Resend Verification</button>
    </div>

    <!-- E-mail/Password -->
    <info-box title="E-mail/Password" type="''" v-if="settings.email !== ''">
      <div class="box input">
        <input type="text" v-model="email" placeholder="Email Address">
      </div>
      <div class="red input-error" v-if="error.emailTaken">This e-mail is already in use.</div>
      <div class="red input-error" v-else-if="error.badEmail">Please enter a valid e-mail.</div>
      <div class="box input">
        <input type="password" placeholder="New Password" v-model="pass">
      </div>
      <div class="red input-error" v-if="error.passLen">Password should be 6+ characters.</div>
    </info-box>

    <!-- Multi-Factor Auth -->
    <info-box title="Security" type="''" v-if="verify && settings.email !== ''">
      <div>
        On each login, a code will be sent to your e-mail to improve account security.
      </div>
      <div>
        <input type="checkbox" id="multi-factor-cb" v-model="multiFactor">
        <label for="multi-factor-cb"><span></span>Enable Two-Factor Login</label>
      </div>
    </info-box>

    <!-- Demographics -->
    <info-box title="Demographics" type="''" v-if="settings.gender !== ''">
      <div class="box input">
        <input class="country" type="text" placeholder="Country" v-model="country" @focus="countrySelect" @blur="clearCountries">
      </div>
      <div class="red input-error" v-if="error.badCountry">Please select a valid country.</div>
      <dropdown-list :items="countries" @select="setCountry" v-if="countries.length > 0"></dropdown-list>
      <gender-select v-model="gender" class="full"></gender-select>
    </info-box>

    <!-- Communication -->
    <info-box title="Communication" type="''">
      <div>
        <input type="checkbox" id="emails-cb" v-model="emails">
        <label for="emails-cb"><span></span>Receive E-mails</label>
      </div>
      <div>
        <input type="checkbox" id="notices-cb" v-model="notices">
        <label for="notices-cb"><span></span>Popup Notifications</label>
      </div>
      <div>
        <input type="checkbox" id="openchat-cb" v-model="openchat">
        <label for="openchat-cb"><span></span>Receive Chat Messages from Anyone</label>
      </div>
    </info-box>

    <!-- Miscellaneous -->
    <info-box title="General" type="''">
      <div>
        <input type="checkbox" id="mute-cb" v-model="mute">
        <label for="mute-cb"><span></span>Mute Sounds</label>
      </div>
      <div>
        <input type="checkbox" id="left-cb" v-model="left">
        <label for="left-cb"><span></span>Left Align Toolbar</label>
      </div>
    </info-box>

    <!-- Avatar -->
    <div class="info-box" v-if="settings.gender !== ''">
      <span class="box">Avatar</span>
      <div class="buttons">
        <!-- Buy Avatar Uploads Button -->
        <button-loader
          :value="`Unlock Uploads (${$store.state.gameData[0].appShop[0] ? $store.state.gameData[0].appShop[0].price : '$4.99'})`"
          :styles="{}"
          :loading="avatarBtnLoading"
          :click="unlockAvatar"
          v-if="!flags.avatar"
        ></button-loader>

        <!-- Upload Avatar Area -->
        <div class="info" v-else>
          <dropzone bucket="cdn.goldfire.me" type="avatar"></dropzone>
        </div>
      </div>
    </div>

    <!-- Username -->
    <div class="info-box" v-if="settings.gender !== ''">
      <span class="box">Username</span>
      <div class="buttons">
        <!-- Buy New Username Button -->
        <button-loader
          :value="`Change Username (${$store.state.gameData[0].appShop[1] ? $store.state.gameData[0].appShop[1].price : '$4.99'})`"
          :styles="{}"
          :loading="userBtnLoading"
          :click="unlockUser"
          v-if="!flags.chuser"
        ></button-loader>

        <!-- New Username Input -->
        <div class="info" v-else>
          <div class="box input">
            <input type="text" v-model="user" placeholder="New Username">
          </div>
          <div class="red input-error" v-if="error.badUser">Usernames must be 3-15 alphanumeric characters.</div>
          <div class="red input-error" v-else-if="error.userTaken">Username is already taken.</div>
        </div>
      </div>
    </div>

    <!-- Delete Account -->
    <div class="info-box" v-if="settings.email !== ''">
      <span class="box">Delete Account</span>
      <div class="buttons" v-if="!deleteStep">
        <div class="button flex center middle error" @click="deleteStep = 1">Delete Account</div>
      </div>
      <div class="info" v-else-if="deleteStep === 1">
        Delete your account and all game data?
        <div class="flex row justify top-margin-10">
          <span class="button half-gap flex center middle" @click="deleteStep = 0">No</span>
          <span class="button half-gap flex center middle error" @click="deleteStep = 2">Yes</span>
        </div>
      </div>
      <div class="info" v-else>
        <div class="box input">
          <input type="text" v-model="deleteConfirm" placeholder="Type username to confirm..." :class="{error: deleteConfirmError}" @keyup.enter="deleteAccount" v-focus>
        </div>
        <div class="button flex center middle error" @click="deleteAccount">Confirm Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex';
import GenderSelect from './GenderSelect.vue';
import DropdownList from './DropdownList.vue';
import ButtonLoader from './ButtonLoader.vue';
import InfoBox from './InfoBox.vue';
import Dropzone from './Dropzone.vue';
import {debounce} from '../utils';

export default {
  name: 'settings-view',
  components: {GenderSelect, InfoBox, DropdownList, ButtonLoader, Dropzone},
  mounted() {
    this.$store.commit('setPanelData', {
      title: 'SETTINGS',
      buttons: [{label: 'Save Settings', loading: false, view: {action: 'saveSettings'}}],
    });

    this.$store.dispatch('loadSettings').then(() => {
      this.email = this.settings.email;
      this.user = this.settings.user;
      this.country = this.settings.loc;
      this.gender = this.settings.gender;
      this.emails = this.settings.emails;
      this.notices = this.settings.notices;
      this.multiFactor = this.settings.multiFactor;
      this.openchat = this.settings.openchat;
      this.mute = this.settings.mute;
      this.left = this.settings.left;
    });
  },
  data() {
    return {
      email: '',
      pass: '',
      multiFactor: false,
      country: '',
      gender: '',
      user: '',
      countries: [],
      emails: true,
      notices: true,
      openchat: true,
      avatarBtnLoading: false,
      userBtnLoading: false,
      mute: false,
      left: false,
      deleteStep: 0,
      deleteConfirm: '',
      deleteConfirmError: false,
    };
  },
  watch: {
    email() {
      this.updateSettings();
    },
    user() {
      this.updateSettings();
    },
    pass() {
      this.updateSettings();
    },
    multiFactor() {
      this.updateSettings();
    },
    country() {
      // Do a fuzzy search on countries.
      if (this.$el.querySelector('.country:focus') && this.country && this.country.length >= 2) {
        this.search();
      }

      // Reset the list if less than 2 characters.
      if (!this.country || this.country.length < 2) {
        this.countries = [];
      }

      this.updateSettings();
    },
    gender() {
      this.updateSettings();
    },
    emails() {
      this.updateSettings();
    },
    notices() {
      this.updateSettings();
    },
    openchat() {
      this.updateSettings();
    },
    mute() {
      this.updateSettings();
    },
    left() {
      this.updateSettings();
    },
  },
  methods: {
    updateSettings() {
      this.$store.commit('setSettingsData', {
        email: this.email,
        user: this.user,
        pass: this.pass,
        multiFactor: this.multiFactor,
        gender: this.gender,
        loc: this.country,
        emails: this.emails,
        notices: this.notices,
        openchat: this.openchat,
        mute: this.mute,
        left: this.left,
      });
    },
    countrySelect() {
      this.$el.querySelector('.country').select();
    },
    setCountry(val) {
      this.country = val;
      this.$el.querySelector('.country').blur();
    },
    clearCountries() {
      this.countries = [];
    },
    search: debounce(function() {
      this.$store.dispatch('searchCountries', this.country).then((list) => {
        this.countries = list;
      });
    }, 250),
    resendVerify() {
      this.$store.dispatch('resendVerification');
      this.$store.commit('setAuthData', {showVerify: true});
    },
    unlockAvatar() {
      this.avatarBtnLoading = true;
      this.$store.dispatch('purchaseProduct', {gid: 0, product: '1'});
      this.updateHistory();
    },
    unlockUser() {
      this.userBtnLoading = true;
      this.$store.dispatch('purchaseProduct', {gid: 0, product: '2'});
      this.updateHistory();
    },
    updateHistory() {
      this.$store.commit('addHistory', {
        mutation: 'setTabView',
        data: {
          tab: 'launcher',
          view: 'settings-view',
        },
      });
    },
    deleteAccount() {
      if (this.$store.state.user.user !== this.deleteConfirm) {
        this.deleteConfirmError = true;
        return;
      }

      this.$store.dispatch('deleteAccount');
      this.$store.commit('toggleTab', 'launcher');
      this.$router.push('/bye');
    },
  },
  computed: Vuex.mapState({
    settings: state => state.settings,
    error: state => state.settings.error,
    verify: state => state.settings.verify,
    flags: state => state.settings.flags,
  }),
};
</script>
