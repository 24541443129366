<template>
  <component :is="auth ? 'index-dashboard' : 'index-offline'"></component>
</template>

<script>
import Vuex from 'vuex';
import IndexOffline from './IndexOffline.vue';
import IndexDashboard from '../IndexDashboard.vue';
// import IndexOnline from './IndexOnline.vue';

export default {
  name: 'index',
  components: {IndexOffline, IndexDashboard},
  meta: {
    titleTemplate: null,
  },
  mounted() {
    if (this.$store.state.route.query.notices) {
      this.$store.commit('toggleTab', 'launcher');
      this.$router.replace('/');
    }
  },
  computed: Vuex.mapState({
    auth: state => state.user.auth,
  }),
};
</script>
