<template>
  <span
    :class="['text-counter', 'medium', 'regular', 'left-margin-15']"
    @click="onClick"
  >
    <span :class="['icon', 'dark', state === 'off' ? 'trash' : 'check']"></span>
  </span>
</template>

<script>
export default {
  name: 'delete-icon',
  props: ['remove'],
  data() {
    return {
      state: 'off',
    };
  },
  methods: {
    onClick() {
      if (this.state === 'on') {
        this.remove();
        this.state = 'off';
      } else {
        this.state = 'on';
        setTimeout(() => {
          this.state = 'off';
        }, 5000);
      }
    },
  },
};
</script>
