<template>
  <div
    :class="['box', 'notice', 'forum', 'click', 'color', 'middle', {inactive: thread.read}]"
    @click="viewThread">
    <!-- Avatars -->
    <span class="multi-avatars">
      <img :src="avatarUrl(thread.avatars[0])" class="avatar">
      <img v-if="thread.avatars[1]" :src="avatarUrl(thread.avatars[1])" class="avatar">
      <span v-else=""></span>
    </span>

    <!-- Title/Tags -->
    <div class="text">
      <span class="user">
        <span class="username" v-if="$store.state.user.auth">{{ thread.title }}</span>
        <router-link :to="link" class="username" v-else>{{ thread.title }}</router-link>
      </span>
      <div>
        <span v-for="tag in tags" :class="['tag', category.tags[tag], 'right-margin-5']">{{ tag }}</span>
      </div>
    </div>

    <!-- Info -->
    <live-time class="text-counter medium light end right-margin-30" :timestamp="thread.t" format="M/D/YY"></live-time>
    <span class="text-counter medium light">
      <div class="icon comments right-margin-5"></div>
      {{ replies }}
    </span>

    <!-- Sticky/Locked -->
    <span v-if="thread.sticky" class="pinned sticky">
      <div class="icon pin"></div>
    </span>
    <span v-if="thread.locked" class="pinned locked">
      <div class="icon key"></div>
    </span>
    
    <!-- Unsubscribe Button -->
    <span class="icon close light click right" @click="$parent.unsubscribe(thread)" v-if="unsubscribe"></span>
  </div>
</template>

<script>
import Vuex from 'vuex';
import LiveTime from './LiveTime.vue';
import {avatarUrl} from '../utils';

export default {
  name: 'forum-thread-box',
  components: {LiveTime},
  props: ['category', 'thread', 'unsubscribe'],
  computed: Vuex.mapState({
    replies() {
      // Don't include original post in reply count.
      return this.thread.postCount - 1;
    },
    link() {
      const title = this.thread.title.toLowerCase().replace(/'/, '').replace(/[^a-z0-9]/gi, '-');
      return `/forums/thread/${this.thread._id}/${title}`;
    },
    tags(state) {
      return this.thread ? this.thread.tags.map(tag => state.forums.tags.names[tag]) : [];
    },
  }),
  methods: {
    avatarUrl,
    viewThread(event) {
      if (event.target.className.match('icon close')) {
        return;
      }

      if (this.$store.state.user.auth) {
        this.$store.dispatch('viewForumThread', this.thread);
      } else {
        this.$router.push(this.link);
      }
    },
  },
};
</script>
