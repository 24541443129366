<template>
  <div class="info-box">
    <span class="box">
      {{ title }}
      <div :class="[counterClass || 'counter']" v-if="counter" v-html="counter"></div>
      <live-time class="time" :timestamp="time" v-if="time"></live-time>
    </span>
    <div :class="['info', type ? type : 'icon-list']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LiveTime from './LiveTime.vue';

export default {
  name: 'info-box',
  components: {LiveTime},
  props: ['title', 'type', 'counter', 'counterClass', 'time'],
};
</script>
