<template>
  <span :class="['icon', 'right-margin-5', status]"></span>
</template>

<script>
import Vuex from 'vuex';

export default {
  name: 'status',
  props: ['uid'],
  computed: Vuex.mapState({
    status(state) {
      let status = state.status.players[this.uid];

      // If this is you, don't worry about the cache.
      if (this.uid === state.user.uid) {
        status = {status: 'on'};
      }

      let className = 'offline';
      if (status && status.away && status.status === 'on') {
        className = 'away';
      } else if (status && status.status === 'on') {
        className = 'online';
      }

      return className;
    },
  }),
};
</script>
