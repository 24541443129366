<template>
  <div class="page full flex center middle">
    <h1>Purchase Complete!</h1>
    <h2>You may now close this window.</h2>
  </div>
</template>

<script>
export default {
  name: 'paypal-done',
  serverCacheKey() {
    return true;
  },
};
</script>
