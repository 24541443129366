<template>
  <div class="content">
    <forum-search></forum-search>
    <forum-thread-box
      v-for="thread in threads"
      class="inf"
      :id="`id-${thread._id}`"
      :key="thread._id"
      :thread="thread"
      :category="category"
    ></forum-thread-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import ForumSearch from './ForumSearch.vue';
import ForumThreadBox from './ForumThreadBox.vue';
import {infiniteScrolling} from '../mixins';

export default {
  name: 'forum-threads-list-view',
  components: {ForumSearch, ForumThreadBox},
  mixins: [infiniteScrolling],
  mounted() {
    this.$store.commit('incForumNotifications', 0);
    this.$store.commit('insertWaitingForumThreads', {});

    const buttons = [{label: 'Filter Tags', view: {mutation: 'toggleShowTags', data: {filter: true}}}];
    if (!this.$store.state.user.forumban) {
      buttons.unshift({label: 'New Thread', view: {mutation: 'toggleEditor', data: {type: 'new'}}});
    }

    this.$store.commit('setPanelData', {
      title: this.category.name.toUpperCase(),
      buttons,
      altIcon: 'search',
      altMethod: {
        mutation: 'toggleForumSearch',
      },
      wide: true,
      headerView: 'forum-threads-list-header',
      footerView: 'forum-threads-list-footer',
    });

    if (this.$store.state.forums.threads.length === 0) {
      this.load(); // load first page of data
    }
    if (this.$store.state.user.auth) {
      this.$store.dispatch('sub', `newForumThread:${this.$store.state.forums.currentCategory}`);
      this.$store.dispatch('sub', `incrementUnreadCount:${this.$store.state.forums.currentCategory}`);
    }
  },
  computed: Vuex.mapState({
    infiniteScrollingCfg(state) {
      return {
        actionParams: {gid: this.category.gid},
        actionName: 'loadForumThreads',
        mutationName: 'setForumThreads',
        items: state.forums.threads,
        keys: ['t'],
      };
    },
    category: (state) => {
      return state.forums.categories
        .find(category => category.gid === state.forums.currentCategory);
    },
    threads: (state) => {
      if (state.forums.filteredTags.length === 0) {
        return state.forums.threads;
      }

      return state.forums.threads;
    },
    tags: state => state.forums.filteredTags.map(tag => state.forums.tags.names.indexOf(tag)),
  }),
  watch: {
    tags() {
      this.$store.commit('setForumThreads', {});
      this.load();
    },
  },
  beforeDestroy() {
    this.$store.commit('setForumThreads', {});
    this.$store.dispatch('unsub', `newForumThread:${this.$store.state.forums.currentCategory}`);
    this.$store.dispatch('unsub', `incrementUnreadCount:${this.$store.state.forums.currentCategory}`);
  },
};
</script>
